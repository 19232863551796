import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  IconButton,
  CssBaseline,
  Divider,
  useMediaQuery,
  useTheme,
  AppBar,
  Menu,
  MenuItem,
  Typography,
  Collapse,
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

// Font Awesome imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faChevronDown,
  faChevronUp,
  faUser,
  faHome,
  faRightFromBracket,
  faTruck,
  faDownload,
  faChartLine,
  faUserShield,
  faCashRegister,
  faList,
  faUpRightFromSquare,
  faBoxes,
  faStore,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";

import { styled } from "@mui/material/styles";

// Component imports
import AddExclusions from "./pages/AddExclusions";
import ViewExclusions from "./pages/ViewExclusions";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ManagePickups from "./pages/ManagePickups";
import Downloads from "./pages/Downloads";
import Dashboard from "./pages/Dashboard";
import AfhaaltijdenMain from "./pages/AfhaaltijdenMain";
import Bestellingen from "./pages/Bestellingen";
import OrderDetail from "./pages/OrderDetail";
import Producten from "./pages/Producten";
import ProductDetail from "./pages/ProductDetail";
import DiscountCodes from "./pages/DiscountCodes";
import PrivateRoute from "./components/PrivateRoute";

// CONSTANTES
const drawerWidth = 240;
const collapsedDrawerWidth = 60;
const mainGradients = {
  drawer: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  active: "rgba(255, 255, 255, 0.15)",
  hover: "rgba(255, 255, 255, 0.1)",
};

// Styled components blijven hetzelfde...
const Main = styled("main", {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "collapsed" && prop !== "isMobile",
})(({ theme, open, collapsed, isMobile }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isMobile
    ? 0
    : open
    ? `${drawerWidth}px`
    : `${collapsedDrawerWidth}px`,
  width: isMobile
    ? "100%"
    : `calc(100% - ${open ? drawerWidth : collapsedDrawerWidth}px)`,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
  },
  backgroundColor: "#f5f5f5",
  minHeight: "100vh",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  minHeight: 64,
  paddingLeft: theme.spacing(1),
  color: "white",
  "& .MuiIconButton-root": {
    color: "white",
  },
}));

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "drawerOpen",
})(({ theme, active, drawerOpen }) => ({
  margin: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  backgroundColor: active ? mainGradients.active : "transparent",
  backdropFilter: active ? "blur(10px)" : "none",
  color: "white",
  position: "relative",
  overflow: "hidden",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(255, 255, 255, 0.1)",
    transform: "translateX(-100%)",
    transition: "transform 0.3s ease-out",
  },
  "&:hover": {
    backgroundColor: active ? mainGradients.active : mainGradients.hover,
    transform: "translateX(5px) scale(1.02)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    "&:before": {
      transform: "translateX(0)",
    },
  },
  "& .MuiListItemIcon-root": {
    color: "white",
    minWidth: 30,
    marginRight: drawerOpen ? theme.spacing(0.5) : 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    padding: 0,
    transition: "transform 0.2s ease",
  },
  "&:hover .MuiListItemIcon-root": {
    transform: "scale(1.1)",
  },
  "& .MuiListItemText-primary": {
    color: "white",
    fontWeight: active ? 600 : 400,
    fontSize: "0.95rem",
    transition: "transform 0.2s ease",
  },
  "&:hover .MuiListItemText-primary": {
    transform: "translateX(3px)",
  },
  height: 48,
  paddingLeft: theme.spacing(1),
  justifyContent: "flex-start",
}));

const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [afhaaltijdenOpen, setAfhaaltijdenOpen] = useState(false);
  const [downloadsOpen, setDownloadsOpen] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalLinkUrl, setExternalLinkUrl] = useState("");
  const [externalLinkName, setExternalLinkName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoggedIn = !!localStorage.getItem("token");

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    setUsername("");
    navigate("/login");
  };

  const handleProfileMenuOpen = (event) =>
    setProfileMenuAnchor(event.currentTarget);
  const handleProfileMenuClose = () => setProfileMenuAnchor(null);

  const handleMenuItemClick = () => {
    if (isMobile) {
      setDrawerOpen(false);
      setAfhaaltijdenOpen(false);
      setDownloadsOpen(false);
    }
  };

  const showAppBarAndDrawer = location.pathname !== "/login";

  const isActive = (paths, hash = null) => {
    return paths.some((path) => {
      if (hash) {
        return location.pathname === path && location.hash === `#${hash}`;
      }
      return (
        location.pathname === path ||
        (location.pathname.startsWith(`${path}/`) && !hash)
      );
    });
  };

  const handleExternalLinkClick = (href, name) => {
    setExternalLinkUrl(href);
    setExternalLinkName(name);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setExternalLinkUrl("");
    setExternalLinkName("");
  };

  const handleDialogConfirm = () => {
    window.open(externalLinkUrl, "_blank", "noopener,noreferrer");
    setDialogOpen(false);
    setExternalLinkUrl("");
    setExternalLinkName("");
  };

  const drawerContent = (
    <>
      <DrawerHeader>
        <IconButton
          onClick={handleDrawerToggle}
          size="large"
          edge="start"
          aria-label="toggle drawer"
          sx={{
            color: "#333333",
            transition: "transform 0.2s ease-in-out",
            mr: { xs: 0, sm: theme.spacing(0.5) },
            ml: { xs: -2, sm: -0.1 },
          }}
        >
          {drawerOpen ? (
            <FontAwesomeIcon icon={faXmark} style={{ fontSize: "20px" }} />
          ) : (
            <FontAwesomeIcon icon={faBars} style={{ fontSize: "20px" }} />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {isLoggedIn && (
          <>
            <StyledListItemButton
              component={Link}
              to="/home"
              active={isActive(["/home", "/"])}
              onClick={handleMenuItemClick}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faHome} style={{ fontSize: "20px" }} />
              </ListItemIcon>
              {drawerOpen && <ListItemText primary="Home" />}
            </StyledListItemButton>

            <StyledListItemButton
              component={Link}
              to="/dashboard"
              active={isActive(["/dashboard"])}
              onClick={handleMenuItemClick}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faChartLine}
                  style={{ fontSize: "20px" }}
                />
              </ListItemIcon>
              {drawerOpen && <ListItemText primary="Dashboard" />}
            </StyledListItemButton>

            <StyledListItemButton
              component={Link}
              to="/bestellingen"
              active={isActive(["/bestellingen"])}
              onClick={handleMenuItemClick}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faList} style={{ fontSize: "20px" }} />
              </ListItemIcon>
              {drawerOpen && <ListItemText primary="Bestellingen" />}
            </StyledListItemButton>

            <StyledListItemButton
              component={Link}
              to="/producten"
              active={isActive(["/producten"])}
              onClick={handleMenuItemClick}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faBoxes} style={{ fontSize: "20px" }} />
              </ListItemIcon>
              {drawerOpen && <ListItemText primary="Producten" />}
            </StyledListItemButton>

            <StyledListItemButton
              component={Link}
              to="/discount-codes"
              active={isActive(["/discount-codes"])}
              onClick={handleMenuItemClick}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faTicket} style={{ fontSize: "20px" }} />
              </ListItemIcon>
              {drawerOpen && <ListItemText primary="Kortingscodes" />}
            </StyledListItemButton>

            <StyledListItemButton
              component={drawerOpen ? "button" : Link}
              to={drawerOpen ? undefined : "/afhaaltijden-main"}
              onClick={() => {
                if (drawerOpen) {
                  setAfhaaltijdenOpen(!afhaaltijdenOpen);
                  if (isMobile && !afhaaltijdenOpen) {
                    setDownloadsOpen(false);
                  }
                } else {
                  handleMenuItemClick();
                }
              }}
              active={isActive([
                "/manage-pickups",
                "/add",
                "/view",
                "/afhaaltijden-main",
              ])}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faTruck} style={{ fontSize: "20px" }} />
              </ListItemIcon>
              {drawerOpen && <ListItemText primary="Afhaaltijden" />}
              {drawerOpen && (
                <Box
                  sx={{ width: 24, display: "flex", justifyContent: "center" }}
                >
                  {afhaaltijdenOpen ? (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={{ fontSize: "20px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{ fontSize: "20px" }}
                    />
                  )}
                </Box>
              )}
            </StyledListItemButton>

            {drawerOpen && (
              <Collapse in={afhaaltijdenOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <StyledListItemButton
                    component={Link}
                    to="/manage-pickups"
                    active={isActive(["/manage-pickups"])}
                    onClick={handleMenuItemClick}
                    drawerOpen={drawerOpen}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary="Beheer tijdsloten" />
                  </StyledListItemButton>
                  <StyledListItemButton
                    component={Link}
                    to="/add"
                    active={isActive(["/add"])}
                    onClick={handleMenuItemClick}
                    drawerOpen={drawerOpen}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary="Uitsluiten producten" />
                  </StyledListItemButton>
                  <StyledListItemButton
                    component={Link}
                    to="/view"
                    active={isActive(["/view"])}
                    onClick={handleMenuItemClick}
                    drawerOpen={drawerOpen}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary="Bekijk uitsluitingen" />
                  </StyledListItemButton>
                </List>
              </Collapse>
            )}

            <StyledListItemButton
              component={drawerOpen ? "button" : Link}
              to={drawerOpen ? undefined : "/downloads"}
              onClick={() => {
                if (drawerOpen) {
                  setDownloadsOpen(!downloadsOpen);
                  if (isMobile && !downloadsOpen) {
                    setAfhaaltijdenOpen(false);
                  }
                } else {
                  handleMenuItemClick();
                }
              }}
              active={isActive(["/downloads"])}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ fontSize: "20px" }}
                />
              </ListItemIcon>
              {drawerOpen && <ListItemText primary="Downloads" />}
              {drawerOpen && (
                <Box
                  sx={{ width: 24, display: "flex", justifyContent: "center" }}
                >
                  {downloadsOpen ? (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={{ fontSize: "20px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{ fontSize: "20px" }}
                    />
                  )}
                </Box>
              )}
            </StyledListItemButton>

            {drawerOpen && (
              <Collapse in={downloadsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {[
                    {
                      to: "/downloads#productlist-day",
                      text: "Productlijst Dag",
                    },
                    {
                      to: "/downloads#productlist-year",
                      text: "Productlijst Jaar",
                    },
                    { to: "/downloads#checklist-day", text: "Afvinklijst Dag" },
                    {
                      to: "/downloads#checklist-year",
                      text: "Afvinklijst Jaar",
                    },
                    { to: "/downloads#order-downloads", text: "Bestelbonnen" },
                    {
                      to: "/downloads#additional-downloads",
                      text: "Extra downloads",
                    },
                  ].map((item, index) => (
                    <StyledListItemButton
                      key={index}
                      component={Link}
                      to={item.to}
                      active={
                        location.pathname === "/downloads" &&
                        location.hash === `#${item.to.split("#")[1]}`
                      }
                      onClick={handleMenuItemClick}
                      drawerOpen={drawerOpen}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary={item.text} />
                    </StyledListItemButton>
                  ))}
                </List>
              </Collapse>
            )}

            <StyledListItemButton
              component="button"
              onClick={() =>
                handleExternalLinkClick(
                  "https://www.vuurwerkbruchem.com",
                  "Webshop"
                )
              }
              active={false}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faStore} style={{ fontSize: "20px" }} />
              </ListItemIcon>
              {drawerOpen && (
                <>
                  <ListItemText primary="Webshop" />
                  <Tooltip title="Opent in een nieuw tabblad">
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      style={{ marginLeft: "8px", fontSize: "20px" }}
                    />
                  </Tooltip>
                </>
              )}
            </StyledListItemButton>

            <StyledListItemButton
              component="button"
              onClick={() =>
                handleExternalLinkClick(
                  "https://api.vuurwerkbruchem.com/admin",
                  "Admin Paneel"
                )
              }
              active={false}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faUserShield}
                  style={{ fontSize: "20px" }}
                />
              </ListItemIcon>
              {drawerOpen && (
                <>
                  <ListItemText primary="Admin Paneel" />
                  <Tooltip title="Opent in een nieuw tabblad">
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      style={{ marginLeft: "8px", fontSize: "20px" }}
                    />
                  </Tooltip>
                </>
              )}
            </StyledListItemButton>

            <StyledListItemButton
              component="button"
              onClick={() =>
                handleExternalLinkClick(
                  "https://kiosk.fireworkflow.com/",
                  "Kassasysteem"
                )
              }
              active={false}
              drawerOpen={drawerOpen}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faCashRegister}
                  style={{ fontSize: "20px" }}
                />
              </ListItemIcon>
              {drawerOpen && (
                <>
                  <ListItemText primary="Kassasysteem" />
                  <Tooltip title="Opent in een nieuw tabblad">
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      style={{ marginLeft: "8px", fontSize: "20px" }}
                    />
                  </Tooltip>
                </>
              )}
            </StyledListItemButton>

            <StyledListItemButton
              onClick={handleLogout}
              drawerOpen={drawerOpen}
              active={false}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  style={{ fontSize: "20px" }}
                />
              </ListItemIcon>
              {drawerOpen && <ListItemText primary="Uitloggen" />}
            </StyledListItemButton>
          </>
        )}
      </List>
    </>
  );

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      {showAppBarAndDrawer && (
        <>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: "#ffffff",
              color: "#333333",
              zIndex: theme.zIndex.drawer + 1,
              transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              ...(drawerOpen &&
                !isMobile && {
                  marginLeft: `${drawerWidth}px`,
                  width: `calc(100% - ${drawerWidth}px)`,
                }),
              ...(!drawerOpen &&
                !isMobile && {
                  marginLeft: `${collapsedDrawerWidth}px`,
                  width: `calc(100% - ${collapsedDrawerWidth}px)`,
                }),
              boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
              borderBottom: "none",
            }}
          >
            <Toolbar sx={{ padding: { xs: "0 16px" } }}>
              {isMobile && (
                <IconButton
                  color="inherit"
                  aria-label={drawerOpen ? "close drawer" : "open drawer"}
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    width: 40,
                    mr: 1,
                    transition: "none",
                  }}
                >
                  {drawerOpen ? (
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ fontSize: "20px", color: "#333333" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBars}
                      style={{ fontSize: "20px" }}
                    />
                  )}
                </IconButton>
              )}
              <Typography
                variant="h6"
                noWrap
                sx={{
                  flexGrow: 1,
                  marginLeft: isMobile ? 0 : 1,
                  transition: "none",
                }}
              >
                Vuurwerk Bruchem
              </Typography>
              {isLoggedIn && (
                <IconButton onClick={handleProfileMenuOpen} color="inherit">
                  <FontAwesomeIcon icon={faUser} />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {username || "Gebruiker"}
                  </Typography>
                </IconButton>
              )}
              <Menu
                anchorEl={profileMenuAnchor}
                open={Boolean(profileMenuAnchor)}
                onClose={handleProfileMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/home");
                    handleProfileMenuClose();
                  }}
                >
                  Home
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleLogout();
                    handleProfileMenuClose();
                  }}
                >
                  Uitloggen
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>

          <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                width: drawerOpen ? drawerWidth : collapsedDrawerWidth,
                boxSizing: "border-box",
                backgroundImage: `${mainGradients.drawer}, radial-gradient(circle at top right, rgba(255,255,255,0.1) 0%, transparent 60%)`,
                backgroundColor: "#ff4b6e",
                color: "white",
                transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                overflowX: "hidden",
                display: "flex",
                alignItems: "flex-start",
                boxShadow: "4px 0 25px rgba(0,0,0,0.1)",
                borderRight: "1px solid rgba(255,255,255,0.1)",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)",
                  pointerEvents: "none",
                },
                "& .MuiDivider-root": {
                  borderColor: "rgba(255,255,255,0.1)",
                  margin: theme.spacing(1, 0),
                },
                "& .MuiListItemIcon-root": {
                  color: "white",
                },
                "& .MuiCollapse-wrapper": {
                  "& .MuiListItemButton-root": {
                    paddingLeft: theme.spacing(4),
                    "& .MuiListItemText-primary": {
                      fontSize: "0.875rem",
                    },
                  },
                },
              },
            }}
          >
            {drawerContent}
          </Drawer>
        </>
      )}

      <Main open={drawerOpen} collapsed={!drawerOpen} isMobile={isMobile}>
        {showAppBarAndDrawer && <Toolbar />}
        <Routes>
          <Route path="/login" element={<Login setUsername={setUsername} />} />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/add"
            element={
              <PrivateRoute>
                <AddExclusions />
              </PrivateRoute>
            }
          />
          <Route
            path="/view"
            element={
              <PrivateRoute>
                <ViewExclusions />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-pickups"
            element={
              <PrivateRoute>
                <ManagePickups />
              </PrivateRoute>
            }
          />
          <Route
            path="/downloads"
            element={
              <PrivateRoute>
                <Downloads />
              </PrivateRoute>
            }
          />
          <Route
            path="/afhaaltijden-main"
            element={
              <PrivateRoute>
                <AfhaaltijdenMain />
              </PrivateRoute>
            }
          />
          <Route
            path="/bestellingen"
            element={
              <PrivateRoute>
                <Bestellingen />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders/:orderId"
            element={
              <PrivateRoute>
                <OrderDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/producten"
            element={
              <PrivateRoute>
                <Producten />
              </PrivateRoute>
            }
          />
          <Route
            path="/producten/:id"
            element={
              <PrivateRoute>
                <ProductDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/discount-codes"
            element={
              <PrivateRoute>
                <DiscountCodes />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
        </Routes>
      </Main>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
          },
        }}
      >
        <DialogTitle
          sx={{
            background: mainGradients.drawer,
            color: "white",
            borderBottom: "1px solid rgba(255,255,255,0.1)",
          }}
        >
          Je verlaat de applicatie
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText>
            Weet je zeker dat je de applicatie wilt verlaten? Je wordt
            doorgestuurd naar het <strong>{externalLinkName}</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button
            onClick={handleDialogClose}
            sx={{
              color: "#666",
              "&:hover": { backgroundColor: "rgba(102, 102, 102, 0.05)" },
            }}
          >
            Annuleren
          </Button>
          <Button
            onClick={handleDialogConfirm}
            sx={{
              background: mainGradients.drawer,
              color: "white",
              "&:hover": { opacity: 0.9 },
            }}
            autoFocus
          >
            Doorgaan
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default App;
