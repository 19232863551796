import React from "react";
const API_BASE = process.env.REACT_APP_API_BASE_URL;

export const getAnalyticsData = async (days = 7) => {
  try {
    const response = await fetch(
      `${API_BASE}/dashboard/analytics?days=${days}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Analytics API error");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching analytics:", error);
    return [];
  }
};

export const getTopPages = async (days = 7) => {
  try {
    const response = await fetch(
      `${API_BASE}/dashboard/analytics/pages?days=${days}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Analytics API error");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching top pages:", error);
    return [];
  }
};

export const getTrafficSources = async (days = 7) => {
  try {
    const response = await fetch(
      `${API_BASE}/dashboard/analytics/traffic?days=${days}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Traffic Sources API error");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching traffic sources:", error);
    return [];
  }
};

export const getGeographicData = async (days = 7) => {
  try {
    const response = await fetch(
      `${API_BASE}/dashboard/analytics/geographic?days=${days}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Geographic API error");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching geographic data:", error);
    return [];
  }
};
