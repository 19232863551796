// src/pages/Login.js

import React, { useState, useEffect } from "react"; // Voeg useEffect toe
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faUser,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";

const Login = ({ setUsername: setGlobalUsername }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Voeg dit effect toe om te controleren of gebruiker al ingelogd is
  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUsername = localStorage.getItem("username"); // Vermijd naamconflict
    if (token && storedUsername) {
      setGlobalUsername(storedUsername);
      navigate("/home");
    }
  }, [navigate, setGlobalUsername]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: "", text: "" });

    try {
      await login({ username, password }); // Verwijder 'response' variabele
      setMessage({ type: "success", text: "Inloggen succesvol!" });
      setGlobalUsername(username);

      // Sla gegevens op als "Onthoud mij" is aangevinkt
      if (rememberMe) {
        localStorage.setItem("username", username);
      }

      // Ga naar de oorspronkelijke pagina als die er is, anders naar home
      const intendedPath = location.state?.from?.pathname || "/home";
      window.location.href = intendedPath; // Gebruik window.location.href voor volledige page refresh
    } catch (error) {
      setMessage({
        type: "error",
        text: error.message || "Fout bij inloggen.",
      });
      toast.error(error.message || "Fout bij inloggen.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Rest van de code blijft hetzelfde...
  const handleForgotPassword = () => {
    toast.info(
      "Neem contact op met FireWorkFlow voor hulp bij het resetten van uw wachtwoord.",
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-left">
          <h2>Inloggen</h2>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>GEBRUIKERSNAAM</label>
              <div className="input-container">
                <FontAwesomeIcon icon={faUser} className="input-icon" />
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Gebruikersnaam"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label>WACHTWOORD</label>
              <div className="input-container">
                <FontAwesomeIcon icon={faLock} className="input-icon" />
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Wachtwoord"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-toggle"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>

            {message.text && message.type === "error" && (
              <div className={`message ${message.type}`}>{message.text}</div>
            )}

            <div className="form-footer">
              <label className="remember-me">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <span>Onthoud mij</span>
              </label>
              <button
                type="button"
                onClick={handleForgotPassword}
                className="forgot-password"
              >
                Wachtwoord vergeten?
              </button>
            </div>

            <button type="submit" disabled={loading} className="submit-button">
              {loading ? "Bezig met inloggen..." : "Inloggen"}
            </button>
          </form>
        </div>

        <div className="login-right">
          <div className="welcome-content">
            <h2>Welkom!</h2>
            <p>Nog geen account?</p>
            <button className="signup-button">Registreren</button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Login;
