// src/pages/AfhaaltijdenMain.js
import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faBan, faListAlt } from "@fortawesome/free-solid-svg-icons";
import FaqReader from "../components/FaqReader";

const AfhaaltijdenMain = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const options = [
    {
      path: "/manage-pickups",
      label: "Beheer tijdsloten",
      description:
        "Configureer en beheer de beschikbare tijdsloten voor afhalen.",
      icon: faClock,
      gradient: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
    },
    {
      path: "/add",
      label: "Uitsluiten producten",
      description:
        "Stel producten in die niet beschikbaar zijn op specifieke tijden.",
      icon: faBan,
      gradient: "linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)",
    },
    {
      path: "/view",
      label: "Bekijk uitsluitingen",
      description: "Overzicht van alle huidige product uitsluitingen.",
      icon: faListAlt,
      gradient: "linear-gradient(135deg, #10b981 0%, #059669 100%)",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Box
        sx={{
          mb: 8,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-20px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100px",
            height: "4px",
            background: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
            borderRadius: "2px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Dit centreert de box horizontaal
            gap: 1,
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: "bold",
              background: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Afhaaltijden Beheer
          </Typography>
          <Box sx={{ mt: 1 }}>
            {" "}
            {/* Kleine verticale aanpassing voor betere uitlijning */}
            <FaqReader pageName="TijdSloten" />
          </Box>
        </Box>
        <Typography
          variant="h6"
          align="center"
          sx={{
            color: "text.secondary",
            maxWidth: "600px",
            margin: "0 auto",
            opacity: 0.8,
          }}
        >
          Kies een van de onderstaande opties om verder te gaan:
        </Typography>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            md: "repeat(3, 1fr)",
          },
          gap: 3,
          mt: 4,
        }}
      >
        {options.map((option, index) => (
          <Card
            key={index}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "hidden",
              borderRadius: 4,
              transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
              border: "1px solid rgba(255,255,255,0.1)",
              background: "#fff",
              "&:hover": {
                transform: "translateY(-8px)",
                boxShadow: "0 12px 24px rgba(0,0,0,0.1)",
                "& .icon-wrapper": {
                  transform: "scale(1.1) translateY(-2px)",
                },
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: "6px",
                background: option.gradient,
              }}
            />
            <CardContent sx={{ flexGrow: 1, textAlign: "center", pt: 4 }}>
              <Box
                className="icon-wrapper"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 3,
                  transition: "transform 0.3s ease",
                }}
              >
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: option.gradient,
                    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  }}
                >
                  <FontAwesomeIcon
                    icon={option.icon}
                    style={{
                      fontSize: "1.5rem",
                      color: "#fff",
                    }}
                  />
                </Box>
              </Box>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: 600 }}
              >
                {option.label}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                {option.description}
              </Typography>
              <Button
                component={Link}
                to={option.path}
                variant="contained"
                sx={{
                  background: option.gradient,
                  color: "white",
                  py: 1.5,
                  px: 4,
                  borderRadius: 2,
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: 500,
                  "&:hover": {
                    background: option.gradient,
                    opacity: 0.9,
                    transform: "scale(1.02)",
                  },
                }}
              >
                {option.label}
              </Button>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default AfhaaltijdenMain;
