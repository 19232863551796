// src/components/PrivateRoute.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";

const LoadingOverlay = ({ children }) => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    }}
  >
    {children}
  </Box>
);

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading, error } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/login");
    }
  }, [isLoading, isAuthenticated, navigate]);

  if (isLoading) {
    return (
      <LoadingOverlay>
        <CircularProgress sx={{ mb: 2 }} />
        <Typography>Even geduld...</Typography>
      </LoadingOverlay>
    );
  }

  if (error) {
    return (
      <LoadingOverlay>
        <Typography variant="h6" sx={{ mb: 2, color: "error.main" }}>
          {error}
        </Typography>
        <Button
          variant="contained"
          onClick={() => (window.location.href = "/login")}
          sx={{
            background: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
            color: "white",
            "&:hover": {
              background: "linear-gradient(135deg, #ff3355 0%, #ff4b6e 100%)",
            },
          }}
        >
          Opnieuw inloggen
        </Button>
      </LoadingOverlay>
    );
  }

  return isAuthenticated ? children : null;
};

export default PrivateRoute;
