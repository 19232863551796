import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  getAnalyticsData,
  getTopPages,
  getTrafficSources,
  getGeographicData,
} from "../services/analyticsService";
import GeographicSection from "./GeographicSection";

// Definieer een mapping van Nederlandse provincienamen (Engels en Nederlands)
const DUTCH_PROVINCES_MAP = {
  Drenthe: "Drenthe",
  Flevoland: "Flevoland",
  Friesland: "Friesland",
  Fryslân: "Friesland",
  Gelderland: "Gelderland",
  Groningen: "Groningen",
  Limburg: "Limburg",
  "Noord-Brabant": "Noord-Brabant",
  "North Brabant": "Noord-Brabant",
  "Noord-Holland": "Noord-Holland",
  "North Holland": "Noord-Holland",
  Overijssel: "Overijssel",
  Utrecht: "Utrecht",
  Zeeland: "Zeeland",
  "Zuid-Holland": "Zuid-Holland",
  "South Holland": "Zuid-Holland",
};

const COLORS = ["#FF4F00", "#00C49F", "#FFBB28", "#8884d8", "#FF8042"];

const AnalyticsSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [period, setPeriod] = useState("7");
  const [loading, setLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [topPages, setTopPages] = useState([]);
  const [trafficData, setTrafficData] = useState([]);
  const [geoData, setGeoData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const days = parseInt(period);
        const [
          analyticsResponse,
          topPagesResponse,
          trafficResponse,
          geoResponse,
        ] = await Promise.all([
          getAnalyticsData(days),
          getTopPages(days),
          getTrafficSources(days),
          getGeographicData(days),
        ]);

        setAnalyticsData(analyticsResponse);
        setTopPages(topPagesResponse);
        setTrafficData(trafficResponse);

        // Filter de geoResponse om alleen Nederlandse provincies te bevatten
        const filteredGeoData = geoResponse
          .filter((item) => {
            const provinceName = item.provincie || item.region || item.province;
            return (
              provinceName && DUTCH_PROVINCES_MAP.hasOwnProperty(provinceName)
            );
          })
          .map((item) => ({
            ...item,
            provincie:
              DUTCH_PROVINCES_MAP[
                item.provincie || item.region || item.province
              ],
          }));

        setGeoData(filteredGeoData);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [period]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  const totalPageviews = analyticsData.reduce(
    (sum, day) => sum + (day.pageviews || 0),
    0
  );
  const totalUsers = analyticsData.reduce(
    (sum, day) => sum + (day.users || 0),
    0
  );
  const avgBounceRate = analyticsData.length
    ? analyticsData.reduce((sum, day) => sum + (day.bounceRate || 0), 0) /
      analyticsData.length
    : 0;

  const trafficSummary = trafficData.reduce((acc, item) => {
    const sourceName = item.bron === "(direct)" ? "Direct verkeer" : item.bron;
    const threshold =
      trafficData.reduce((sum, i) => sum + i.paginaWeergaven, 0) * 0.01;
    const name = item.paginaWeergaven < threshold ? "Overig" : sourceName;

    if (!acc[name]) {
      acc[name] = {
        name,
        value: 0,
        users: 0,
        conversies: 0,
      };
    }

    acc[name].value += item.paginaWeergaven;
    acc[name].users += item.bezoekers;
    acc[name].conversies += item.conversies || 0;
    return acc;
  }, {});

  const pieData = Object.values(trafficSummary).sort(
    (a, b) => b.value - a.value
  );

  const CustomTrafficTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 1.5,
            border: 1,
            borderColor: "divider",
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Typography variant="subtitle2">{data.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            Pageviews: {data.value.toLocaleString()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Bezoekers: {data.users.toLocaleString()}
          </Typography>
          {data.conversies > 0 && (
            <Typography variant="body2" color="text.secondary">
              Conversies: {data.conversies.toLocaleString()}
            </Typography>
          )}
        </Box>
      );
    }
    return null;
  };

  const CustomBarTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 1.5,
            border: 1,
            borderColor: "divider",
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Typography variant="subtitle2">{label}</Typography>
          {payload.map((entry, index) => (
            <Typography
              key={index}
              variant="body2"
              color="text.secondary"
              sx={{ color: entry.color }}
            >
              {entry.name}: {entry.value.toLocaleString()}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <>
      {/* Header met title en period selector */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 2 : 0,
        }}
      >
        <Typography variant="h4">Website Analytics</Typography>
        <FormControl
          sx={{
            minWidth: 120,
            width: isMobile ? "100%" : "auto",
          }}
        >
          <Select
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            size="small"
            fullWidth={isMobile}
          >
            <MenuItem value="1">Laatste 24 uur</MenuItem>
            <MenuItem value="7">Laatste week</MenuItem>
            <MenuItem value="30">Laatste maand</MenuItem>
            <MenuItem value="90">Laatste 3 maanden</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Overview Cards */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: "#E3F2FD" }}>
            <CardContent>
              <Stack spacing={1}>
                <Typography color="textSecondary">TOTAAL PAGEVIEWS</Typography>
                <Typography variant="h4">
                  {totalPageviews.toLocaleString()}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: "#E8F5E9" }}>
            <CardContent>
              <Stack spacing={1}>
                <Typography color="textSecondary">UNIEKE BEZOEKERS</Typography>
                <Typography variant="h4">
                  {totalUsers.toLocaleString()}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: "#FFF3E0" }}>
            <CardContent>
              <Stack spacing={1}>
                <Typography color="textSecondary">GEM. BOUNCE RATE</Typography>
                <Typography variant="h4">
                  {avgBounceRate.toFixed(1)}%
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Visitors Chart */}
      {analyticsData.length > 0 && (
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              BEZOEKERSOVERZICHT
            </Typography>
            <Box sx={{ height: isMobile ? 250 : 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={analyticsData}
                  margin={
                    isMobile
                      ? { top: 20, right: 20, bottom: 20, left: 20 }
                      : { top: 20, right: 50, left: 50, bottom: 80 }
                  }
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    angle={isMobile ? -45 : 0}
                    textAnchor={isMobile ? "end" : "middle"}
                    height={isMobile ? 60 : 30}
                    tick={{ fontSize: isMobile ? 10 : 12 }}
                  />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip content={<CustomBarTooltip />} />
                  <Legend />
                  <Bar
                    yAxisId="left"
                    dataKey="pageviews"
                    name="Pageviews"
                    fill="#8884d8"
                  />
                  <Bar
                    yAxisId="right"
                    dataKey="users"
                    name="Bezoekers"
                    fill="#82ca9d"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      )}

      {/* Traffic Sources Chart */}
      {trafficData.length > 0 && (
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              VERKEERSBRONNEN
            </Typography>
            <Box sx={{ height: isMobile ? 300 : 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={pieData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={isMobile ? 100 : 150}
                    label={({
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      percent,
                      name,
                    }) => {
                      const RADIAN = Math.PI / 180;
                      const radius = outerRadius * (isMobile ? 1.1 : 1.2);
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);

                      return !isMobile ? (
                        <text
                          x={x}
                          y={y}
                          fill="textPrimary"
                          textAnchor={x > cx ? "start" : "end"}
                          dominantBaseline="central"
                          fontSize={12}
                        >
                          {`${name} (${(percent * 100).toFixed(0)}%)`}
                        </text>
                      ) : null;
                    }}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTrafficTooltip />} />
                </PieChart>
              </ResponsiveContainer>
            </Box>
            {/* Mobiele legenda voor pie chart */}
            {isMobile && (
              <Box sx={{ mt: 2 }}>
                {pieData.map((entry, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: 12,
                        height: 12,
                        backgroundColor: COLORS[index % COLORS.length],
                        mr: 1,
                        borderRadius: "2px",
                      }}
                    />
                    <Typography variant="body2">
                      {entry.name} (
                      {((entry.value / totalPageviews) * 100).toFixed(1)}%)
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </CardContent>
        </Card>
      )}

      {/* Most Viewed Pages - Simplified for mobile */}
      {topPages.length > 0 && (
        <Card sx={{ mb: 4, overflow: "auto" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              MEEST BEKEKEN PAGINA'S
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pagina</TableCell>
                  {!isMobile && <TableCell align="right">Views</TableCell>}
                  <TableCell align="right">Unieke views</TableCell>
                  {!isMobile && (
                    <TableCell align="right">Gem. tijd (sec)</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {topPages.map((page, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        maxWidth: isMobile ? 200 : "none",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {page.title}
                    </TableCell>
                    {!isMobile && (
                      <TableCell align="right">
                        {page.pageviews.toLocaleString()}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      {page.users.toLocaleString()}
                    </TableCell>
                    {!isMobile && (
                      <TableCell align="right">
                        {page.avgTime ? page.avgTime.toFixed(1) : "N/A"}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}

      {/* Geographic Data - Hidden on mobile */}
      {!isMobile && geoData.length > 0 && (
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              GEOGRAFISCHE VERDELING
            </Typography>
            <GeographicSection data={geoData} />
          </CardContent>
        </Card>
      )}

      {/* Mobile-only simplified geographic data */}
      {isMobile && geoData.length > 0 && (
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              GEOGRAFISCHE VERDELING
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Provincie</TableCell>
                  <TableCell align="right">Bezoekers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(
                  geoData.reduce((acc, item) => {
                    // Samenvoegen van bezoekers per provincie
                    if (!acc[item.provincie]) {
                      acc[item.provincie] = 0;
                    }
                    acc[item.provincie] += item.bezoekers;
                    return acc;
                  }, {})
                )
                  // Sorteer op aantal bezoekers (hoogste eerst)
                  .sort(([, a], [, b]) => b - a)
                  .map(([provincie, bezoekers], index) => (
                    <TableRow key={index}>
                      <TableCell>{provincie}</TableCell>
                      <TableCell align="right">
                        {bezoekers.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AnalyticsSection;
