// src/pages/Dashboard.js

import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  useTheme,
  TableContainer,
  useMediaQuery,
  Tabs,
  Tab,
  Popover,
  IconButton,
} from "@mui/material";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faMoneyBillWave,
  faCreditCard,
  faMoneyBill,
  faUser,
  faCalendarDay,
  faChartBar, // Icoon voor "Verkocht"
  faBoxOpen, // Icoon voor "Voorraad"
  faInfoCircle, // Icoon voor informatie
} from "@fortawesome/free-solid-svg-icons";

import api from "../services/api";
import AnalyticsSection from "../components/AnalyticsSection";
import FaqReader from "../components/FaqReader";

// TabPanel component voor de verschillende tabbladen
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}
const getStatusStyle = (status) => {
  const styles = {
    completed: { backgroundColor: "#e8f5e9", color: "#2e7d32" },
    paid: { backgroundColor: "#e8f5e9", color: "#2e7d32" },
    contant: { backgroundColor: "#fff3e0", color: "#ed6c02" },
    pending: { backgroundColor: "#e3f2fd", color: "#0288d1" },
    cancelled: { backgroundColor: "#fbe9e7", color: "#d32f2f" },
    refunded: { backgroundColor: "#fbe9e7", color: "#d32f2f" },
  };
  return (
    styles[status?.toLowerCase()] || {
      backgroundColor: "#f5f5f5",
      color: "#616161",
    }
  );
};

const getStatusLabel = (status) => {
  const labels = {
    completed: "Betaald",
    paid: "Betaald",
    contant: "Contant",
    pending: "In afwachting",
    cancelled: "Geannuleerd",
    refunded: "Terugbetaald",
  };
  return labels[status?.toLowerCase()] || status;
};
// Styling constanten
const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

// Utility functions
const formatFriendlyDate = (date) => {
  if (!date) return "Geen datum bekend";

  let orderDate = new Date(date);

  // Als de datum ongeldig is, probeer eerst DD-MM-YYYY formaat
  if (isNaN(orderDate.getTime())) {
    const parts = date.split("-");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      orderDate = new Date(`${year}-${month}-${day}`);
    }
  }

  // Als de datum nog steeds ongeldig is, return originele string
  if (isNaN(orderDate.getTime())) {
    return date;
  }

  // Reset tijden naar middernacht voor accurate dagvergelijking
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const dayBeforeYesterday = new Date(today);
  dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

  // Vergelijk datums zonder tijden
  const compareDate = new Date(orderDate);
  compareDate.setHours(0, 0, 0, 0);

  if (compareDate.getTime() === today.getTime()) return "Vandaag";
  if (compareDate.getTime() === yesterday.getTime()) return "Gisteren";
  if (compareDate.getTime() === dayBeforeYesterday.getTime())
    return "Eergisteren";

  // Voor andere datums, return DD-MM-YYYY formaat
  return orderDate
    .toLocaleDateString("nl-NL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, "-");
};

const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// Normalize city names
const normalizeCity = (city) => {
  if (!city) return "";
  let normalized = city.toLowerCase().trim();
  const replacements = {
    "'s-hertogenbosch": "den bosch",
    "den-bosch": "den bosch",
    "'s hertogenbosch": "den bosch",
    "'s-gravenhage": "den haag",
    "den-haag": "den haag",
    "'s gravenhage": "den haag",
    geldrop: "mierlo",
    "den bosch": "Maaspoort",
  };
  return replacements[normalized] || normalized;
};

// InfoPopover component
const InfoPopover = ({ title, content }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "info-popover" : undefined;

  return (
    <>
      <IconButton onClick={handleClick} size="small" aria-label={title}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            fontSize: "0.8rem",
            color: "#555",
          }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, maxWidth: 200 }}>
          <Typography variant="body2">{content}</Typography>
        </Box>
      </Popover>
    </>
  );
};

// DashboardMap component
const DashboardMap = ({
  locations,
  geocodedLocations,
  setGeocodedLocations,
  geocodeCity,
}) => {
  const [municipalitiesData, setMunicipalitiesData] = useState(null);
  const [citiesData, setCitiesData] = useState(null);
  const [municipalityMapping, setMunicipalityMapping] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);
  const [hoveredMunicipality, setHoveredMunicipality] = useState(null);
  const [ordersInMunicipality, setOrdersInMunicipality] = useState(null);

  // Fetch map data
  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const [municipalitiesResponse, citiesResponse, mappingResponse] =
          await Promise.all([
            fetch("https://admin.vuurwerkbruchem.com/gemeenten.json"),
            fetch("https://admin.vuurwerkbruchem.com/buurten.json"),
            fetch("https://admin.vuurwerkbruchem.com/gemeente_met_plaats.json"),
          ]);

        const municipalitiesData = await municipalitiesResponse.json();
        const citiesData = await citiesResponse.json();
        const municipalityMapping = await mappingResponse.json();

        setMunicipalitiesData(municipalitiesData);
        setCitiesData(citiesData);
        setMunicipalityMapping(municipalityMapping);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching map data:", error);
        setLoading(false);
      }
    };

    fetchMapData();
  }, []);

  // Geocode locations
  useEffect(() => {
    const geocodeLocations = () => {
      if (!locations || !municipalitiesData || !citiesData) return;

      const locationArray = Object.entries(locations)
        .map(([city, data]) => {
          const coordinates = geocodeCity(city);
          const isDefaultLocation =
            coordinates[0] === 5.2913 && coordinates[1] === 52.1326;

          if (isDefaultLocation) {
            console.warn(
              `⚠️ Niet-gematchte locatie gevonden: ${city} (${
                data.count
              } orders, €${data.total.toFixed(2)})`
            );
            return null;
          }

          return {
            city,
            coordinates,
            count: data.count,
            total: data.total,
          };
        })
        .filter((location) => location !== null);

      setGeocodedLocations(locationArray);
    };

    geocodeLocations();
  }, [
    locations,
    municipalitiesData,
    citiesData,
    geocodeCity,
    setGeocodedLocations,
  ]);

  // Handle municipality selection
  const getOrdersForMunicipality = (municipality) => {
    if (
      !municipality?.properties?.statnaam &&
      !municipality?.properties?.GM_NAAM
    ) {
      return [];
    }

    const municipalityName =
      municipality.properties.GM_NAAM || municipality.properties.statnaam;

    if (!municipalityMapping) {
      return [];
    }

    const mappingEntry = municipalityMapping.find((entry) => {
      const variants = entry.gemeente_varianten.map((v) =>
        v
          .toLowerCase()
          .trim()
          .replace(/['\s-]/g, "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
      const normalizedMunicipalityName = municipalityName
        .toLowerCase()
        .trim()
        .replace(/['\s-]/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return variants.includes(normalizedMunicipalityName);
    });

    if (!mappingEntry) {
      return [];
    }

    const orders = Object.entries(locations).filter(([city, data]) => {
      const normalizedCity = city
        .toLowerCase()
        .trim()
        .replace(/['\s-]/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      const isInMunicipality = mappingEntry.plaats.some((plaats) => {
        const normalizedPlaats = plaats
          .toLowerCase()
          .trim()
          .replace(/['\s-]/g, "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return normalizedPlaats === normalizedCity;
      });

      return isInMunicipality;
    });

    return orders;
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 2,
      }}
    >
      <Box
        sx={{
          height: { xs: 400, md: 800 },
          width: { xs: "100%", md: "75%" },
          bgcolor: "grey.100",
          borderRadius: 1,
          mb: { xs: 2, md: 0 },
        }}
      >
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            center: [5.2913, 52.1326],
            scale: 8000,
          }}
          style={{ width: "100%", height: "100%" }}
        >
          <ZoomableGroup center={[5.2913, 52.1326]} zoom={1}>
            {municipalitiesData && (
              <Geographies geography={municipalitiesData}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.properties.GM_CODE || geo.properties.statcode}
                      geography={geo}
                      fill={
                        selectedMunicipality?.properties?.GM_CODE ===
                        geo.properties.GM_CODE
                          ? "#e5e5e5"
                          : "#FFFFFF"
                      }
                      stroke="#D4D4D4"
                      strokeWidth={0.5}
                      style={{
                        default: { outline: "none" },
                        hover: {
                          fill: "#F5F5F5",
                          outline: "none",
                          cursor: "pointer",
                        },
                        pressed: { outline: "none" },
                      }}
                      onMouseEnter={() => setHoveredMunicipality(geo)}
                      onMouseLeave={() => setHoveredMunicipality(null)}
                      onClick={() => {
                        const municipalityOrders =
                          getOrdersForMunicipality(geo);
                        setSelectedMunicipality(geo);
                        setOrdersInMunicipality(municipalityOrders);
                      }}
                    >
                      {hoveredMunicipality?.properties?.GM_CODE ===
                        geo.properties.GM_CODE && (
                        <title>
                          {capitalizeFirstLetter(
                            geo.properties.GM_NAAM || geo.properties.statnaam
                          )}
                        </title>
                      )}
                    </Geography>
                  ))
                }
              </Geographies>
            )}
            {geocodedLocations.map((location, i) => (
              <Marker key={i} coordinates={location.coordinates}>
                <circle
                  r={Math.min(Math.max(Math.sqrt(location.count) * 8, 10), 40)}
                  fill="#FF4F00"
                  fillOpacity={0.4}
                  stroke="#FFFFFF"
                  strokeWidth={1}
                  strokeOpacity={0.6}
                  pointerEvents="none"
                />
                <title>
                  {`${capitalizeFirstLetter(location.city)}: ${
                    location.count
                  } orders (€${location.total.toFixed(2)})`}
                </title>
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>
      </Box>

      <Box
        sx={{
          width: { xs: "100%", md: "25%" },
          p: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {selectedMunicipality && (
          <Box>
            <Typography variant="h6" gutterBottom>
              {capitalizeFirstLetter(
                selectedMunicipality.properties.GM_NAAM ||
                  selectedMunicipality.properties.statnaam
              )}
            </Typography>

            {ordersInMunicipality && ordersInMunicipality.length > 0 ? (
              <Box>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Orders in deze gemeente:
                </Typography>

                {/* Toegevoegde Box met responsieve height en scroll */}
                <Box sx={{ height: { xs: 300, md: 600 }, overflowY: "auto" }}>
                  {ordersInMunicipality.map(([city, data], index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                        pb: 1,
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {capitalizeFirstLetter(city)}
                      </Typography>
                      <Typography variant="body2">
                        Aantal orders: {data.count}
                      </Typography>
                      <Typography variant="body2">
                        Totaal bedrag: €{data.total.toFixed(2)}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{ mt: 2, pt: 1, borderTop: 1, borderColor: "divider" }}
                >
                  <Typography variant="subtitle2">
                    Totaal voor deze gemeente:
                  </Typography>
                  <Typography variant="body2">
                    Aantal orders:{" "}
                    {ordersInMunicipality.reduce(
                      (sum, [_, data]) => sum + data.count,
                      0
                    )}
                  </Typography>
                  <Typography variant="body2">
                    Totaal bedrag: €
                    {ordersInMunicipality
                      .reduce((sum, [_, data]) => sum + data.total, 0)
                      .toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Typography color="text.secondary">
                Geen orders gevonden in deze gemeente
              </Typography>
            )}
          </Box>
        )}

        {!selectedMunicipality && (
          <Typography color="text.secondary" sx={{ fontStyle: "italic" }}>
            Klik op een gemeente voor meer informatie
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAllTopSelling, setShowAllTopSelling] = useState(false);
  const [showAllLowStock, setShowAllLowStock] = useState(false);
  const [geocodedLocations, setGeocodedLocations] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    orderCount: 0,
    totalAmount: 0,
    onlinePaid: 0,
    cashAmount: 0,
    lastOrder: "",
    lastOrderDate: "",
    ordersToday: 0,
    topSellingItems: [],
    lowStockItems: [],
    orderLocations: {},
    cityAmounts: [],
    ordersByMonth: [],
    municipalitiesData: null, // Toegevoegd voor DashboardMap
    citiesData: null, // Toegevoegd voor DashboardMap
    municipalityMapping: null, // Toegevoegd voor DashboardMap
    recentOrders: [],
  });
  const navigate = useNavigate();
  const yearOptions = Array.from(
    { length: new Date().getFullYear() - 2019 + 1 },
    (_, i) => 2019 + i
  );

  // Bestaande sortedItems functie
  const sortedItems = (items, showAll, type) => {
    const sortKey = type === "lowStock" ? "stockQuantity" : "totalSold";
    const sortDirection = type === "lowStock" ? "asc" : "desc";

    const sorted = [...items].sort((a, b) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];
      if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

    return isMobile
      ? type === "lowStock"
        ? sorted.slice(0, 5)
        : sorted // For lowStock, limit to 5
      : showAll
      ? sorted
      : sorted.slice(0, 10);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Definieer geocodeCity met useCallback om useEffect afhankelijkheden te fixen
  const geocodeCity = useCallback(
    (city) => {
      const normalizeCityLocal = (city) => {
        if (!city) return "";
        let normalized = city.toLowerCase().trim();
        const replacements = {
          "'s-hertogenbosch": "den bosch",
          "den-bosch": "den bosch",
          "'s hertogenbosch": "den bosch",
          "'s-gravenhage": "den haag",
          "den-haag": "den haag",
          "'s gravenhage": "den haag",
          geldrop: "mierlo",
          "den bosch": "Maaspoort",
        };
        return replacements[normalized] || normalized;
      };

      const getCoordinatesLocal = (coords) => {
        if (Array.isArray(coords)) {
          if (Array.isArray(coords[0][0])) {
            const points = coords[0];
            const center = points.reduce(
              (acc, point) => [acc[0] + point[0], acc[1] + point[1]],
              [0, 0]
            );
            return [center[0] / points.length, center[1] / points.length];
          }
          return coords[0];
        }
        return coords;
      };

      const normalizedCity = normalizeCityLocal(city).toLowerCase();
      let foundFeature = null;

      if (dashboardData.citiesData) {
        foundFeature = dashboardData.citiesData.features.find(
          (feature) =>
            feature.properties?.statnaam?.toLowerCase() === normalizedCity
        );

        if (!foundFeature) {
          foundFeature = dashboardData.municipalitiesData.features.find(
            (feature) =>
              feature.properties?.statnaam?.toLowerCase() === normalizedCity
          );
        }

        if (!foundFeature && dashboardData.citiesData) {
          foundFeature = dashboardData.citiesData.features.find(
            (feature) =>
              feature.properties?.statnaam?.toLowerCase() === normalizedCity &&
              feature.properties?.gemeente?.toLowerCase() === normalizedCity
          );
        }

        if (!foundFeature) {
          foundFeature = dashboardData.citiesData.features.find(
            (feature) =>
              feature.properties?.statnaam
                ?.toLowerCase()
                .startsWith(normalizedCity) ||
              normalizedCity.startsWith(
                feature.properties?.statnaam?.toLowerCase()
              )
          );
        }

        if (foundFeature) {
          const coords = foundFeature.geometry.coordinates;
          return getCoordinatesLocal(coords);
        }
      }

      if (!foundFeature && dashboardData.municipalitiesData) {
        foundFeature = dashboardData.municipalitiesData.features.find(
          (feature) =>
            feature.properties?.statnaam?.toLowerCase() === normalizedCity
        );

        if (foundFeature) {
          const coords = foundFeature.geometry.coordinates;
          return getCoordinatesLocal(coords);
        }
      }

      console.warn(`Niet-gematchte locatie: ${normalizedCity}`);
      return [5.2913, 52.1326];
    },
    [dashboardData.citiesData, dashboardData.municipalitiesData]
  );

  // Data ophalen
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const statsResponse = await api.getDashboardStats(selectedYear);
        const locationsResponse = await api.getDashboardLocations(selectedYear);

        const normalizedLocations = {};
        Object.entries(locationsResponse.data || {}).forEach(([city, data]) => {
          const normalizedCity = normalizeCity(city);
          if (!normalizedLocations[normalizedCity]) {
            normalizedLocations[normalizedCity] = { count: 0, total: 0 };
          }
          normalizedLocations[normalizedCity].count += data.count;
          normalizedLocations[normalizedCity].total += data.total;
        });

        if (statsResponse.success) {
          console.log("Recent orders:", statsResponse.data.recentOrders); // Debug log

          setDashboardData((prevData) => ({
            ...prevData,
            ...statsResponse.data,
            orderLocations: normalizedLocations,
            cityAmounts: Object.entries(normalizedLocations)
              .map(([city, data]) => ({
                city: capitalizeFirstLetter(city),
                amount: data.total,
                count: data.count,
              }))
              .sort((a, b) => b.amount - a.amount),
          }));
        } else {
          setError("Fout bij ophalen dashboard data");
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
        setError("Fout bij ophalen dashboard data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedYear]);

  // Data ophalen voor de kaart (municipalities, cities, mapping)
  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const [municipalitiesResponse, citiesResponse, mappingResponse] =
          await Promise.all([
            fetch("https://admin.vuurwerkbruchem.com/gemeenten.json"),
            fetch("https://admin.vuurwerkbruchem.com/buurten.json"),
            fetch("https://admin.vuurwerkbruchem.com/gemeente_met_plaats.json"),
          ]);

        const municipalitiesData = await municipalitiesResponse.json();
        const citiesData = await citiesResponse.json();
        const municipalityMapping = await mappingResponse.json();

        setDashboardData((prevData) => ({
          ...prevData,
          municipalitiesData,
          citiesData,
          municipalityMapping,
        }));
        // setLoading(false); // Verwijder dit hier om overlappen met andere loading
      } catch (error) {
        console.error("Error fetching map data:", error);
        // setLoading(false); // Verwijder dit hier om overlappen met andere loading
      }
    };

    fetchMapData();
  }, []);

  // Verwerken van geocode locaties
  useEffect(() => {
    const geocodeLocations = () => {
      if (
        !dashboardData.orderLocations ||
        !dashboardData.municipalitiesData ||
        !dashboardData.citiesData
      )
        return;

      const locationArray = Object.entries(dashboardData.orderLocations)
        .map(([city, data]) => {
          const coordinates = geocodeCity(city);
          const isDefaultLocation =
            coordinates[0] === 5.2913 && coordinates[1] === 52.1326;

          if (isDefaultLocation) {
            console.warn(
              `⚠️ Niet-gematchte locatie gevonden: ${city} (${
                data.count
              } orders, €${data.total.toFixed(2)})`
            );
            return null;
          }

          return {
            city,
            coordinates,
            count: data.count,
            total: data.total,
          };
        })
        .filter((location) => location !== null);

      setGeocodedLocations(locationArray);
    };

    geocodeLocations();
  }, [
    dashboardData.orderLocations,
    dashboardData.municipalitiesData,
    dashboardData.citiesData,
    geocodeCity,
  ]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount ? Number(amount) : 0);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  // Render het overzicht tabblad
  const renderOverviewTab = () => (
    <Box>
      {/* Jaar selector */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Typography variant="h4">
          Overzicht{!isMobile && ` ${selectedYear}`}
        </Typography>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="year-select-label">Jaar</InputLabel>
          <Select
            labelId="year-select-label"
            value={selectedYear}
            label="Jaar"
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {yearOptions.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Statistiek kaarten */}
      <Box
        sx={{
          display: "grid",
          gap: 3,
          gridTemplateColumns: {
            xs: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          },
          mb: 4,
        }}
      >
        {/* Aantal bestellingen kaart */}
        <Card sx={{ height: 160, bgcolor: "#E0F7FA", boxShadow: 3 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faShoppingCart}
              style={{ fontSize: 40, color: "#00796B", marginBottom: "8px" }}
            />
            <Typography color="textSecondary" gutterBottom>
              AANTAL BESTELLINGEN
            </Typography>
            {dashboardData.orderCount > 0 ? (
              <>
                <Typography variant="h4" fontWeight="bold">
                  {dashboardData.orderCount}
                </Typography>
                <Typography color="textSecondary">
                  In het jaar {selectedYear}
                </Typography>
              </>
            ) : (
              <Typography color="text.secondary" sx={{ mt: 1 }}>
                Geen bestellingen in {selectedYear}
              </Typography>
            )}
          </CardContent>
        </Card>

        {/* Totaalbedrag kaart */}
        <Card sx={{ height: 160, bgcolor: "#FFF3E0", boxShadow: 3 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faMoneyBillWave}
              style={{ fontSize: 40, color: "#FFB74D", marginBottom: "8px" }}
            />
            <Typography color="textSecondary" gutterBottom>
              TOTAALBEDRAG
            </Typography>
            {dashboardData.totalAmount > 0 ? (
              <>
                <Typography variant="h4" fontWeight="bold">
                  {formatCurrency(dashboardData.totalAmount)}
                </Typography>
                <Typography color="textSecondary">
                  In het jaar {selectedYear}
                </Typography>
              </>
            ) : (
              <Typography color="text.secondary" sx={{ mt: 1 }}>
                Geen omzet in {selectedYear}
              </Typography>
            )}
          </CardContent>
        </Card>

        {/* Online betaald kaart */}
        <Card sx={{ height: 160, bgcolor: "#E8F5E9", boxShadow: 3 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faCreditCard}
              style={{ fontSize: 40, color: "#66BB6A", marginBottom: "8px" }}
            />
            <Typography color="textSecondary" gutterBottom>
              ONLINE BETAALD
            </Typography>
            {dashboardData.onlinePaid > 0 ? (
              <>
                <Typography variant="h4" fontWeight="bold">
                  {formatCurrency(dashboardData.onlinePaid)}
                </Typography>
                <Typography color="textSecondary">
                  In het jaar {selectedYear}
                </Typography>
              </>
            ) : (
              <Typography color="text.secondary" sx={{ mt: 1 }}>
                Geen online betalingen in {selectedYear}
              </Typography>
            )}
          </CardContent>
        </Card>

        {/* Contant te betalen kaart */}
        <Card sx={{ height: 160, bgcolor: "#E3F2FD", boxShadow: 3 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faMoneyBill}
              style={{ fontSize: 40, color: "#42A5F5", marginBottom: "8px" }}
            />
            <Typography color="textSecondary" gutterBottom>
              CONTANT TE BETALEN
            </Typography>
            {dashboardData.cashAmount > 0 ? (
              <>
                <Typography variant="h4" fontWeight="bold">
                  {formatCurrency(dashboardData.cashAmount)}
                </Typography>
                <Typography color="textSecondary">
                  In het jaar {selectedYear}
                </Typography>
              </>
            ) : (
              <Typography color="text.secondary" sx={{ mt: 1 }}>
                Geen contante betalingen in {selectedYear}
              </Typography>
            )}
          </CardContent>
        </Card>

        {/* Laatste bestelling kaart */}
        <Card sx={{ height: 160, bgcolor: "#F3E5F5", boxShadow: 3 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faUser}
              style={{ fontSize: 40, color: "#AB47BC", marginBottom: "8px" }}
            />
            <Typography color="textSecondary" gutterBottom>
              LAATSTE BESTELLING
            </Typography>
            {dashboardData.lastOrder ? (
              <>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  {capitalizeFirstLetter(dashboardData.lastOrder)}
                </Typography>
                <Typography color="textSecondary">
                  {dashboardData.lastOrderDate
                    ? formatFriendlyDate(dashboardData.lastOrderDate)
                    : "Geen datum bekend"}
                </Typography>
              </>
            ) : (
              <Typography color="text.secondary" sx={{ mt: 1 }}>
                Geen bestellingen in {selectedYear}
              </Typography>
            )}
          </CardContent>
        </Card>

        {/* Bestellingen vandaag kaart */}
        <Card sx={{ height: 160, bgcolor: "#FCE4EC", boxShadow: 3 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faCalendarDay}
              style={{ fontSize: 40, color: "#EC407A", marginBottom: "8px" }}
            />
            <Typography color="textSecondary" gutterBottom>
              BESTELLINGEN VANDAAG
            </Typography>
            {dashboardData.ordersToday > 0 ? (
              <>
                <Typography variant="h4" fontWeight="bold">
                  {dashboardData.ordersToday}
                </Typography>
                <Typography color="textSecondary">Vandaag</Typography>
              </>
            ) : (
              <Typography color="text.secondary" sx={{ mt: 1 }}>
                Geen bestellingen vandaag
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
      {/* Recente bestellingen tabel */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">RECENTE BESTELLINGEN</Typography>
            <Button
              variant="contained"
              component={Link}
              to={{
                pathname: "/bestellingen",
                search: `?year=${selectedYear}`,
                state: { year: selectedYear },
              }}
              sx={{
                background: mainGradients.primary,
                color: "white",
                "&:hover": {
                  background: mainGradients.primary,
                  opacity: 0.9,
                  transform: "translateY(-1px)",
                  boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                },
                textTransform: "none",
                borderRadius: "6px",
                boxShadow: "none",
                transition: "all 0.2s ease",
              }}
            >
              Alle bestellingen
            </Button>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 600,
                      background: mainGradients.tableHeader,
                    }}
                  >
                    Datum
                  </TableCell>
                  {!isMobile && (
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        background: mainGradients.tableHeader,
                      }}
                    >
                      Bestelnummer
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      fontWeight: 600,
                      background: mainGradients.tableHeader,
                    }}
                  >
                    Klant
                  </TableCell>
                  {!isMobile && (
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        background: mainGradients.tableHeader,
                      }}
                    >
                      Stad
                    </TableCell>
                  )}
                  <TableCell
                    align="right"
                    sx={{
                      fontWeight: 600,
                      background: mainGradients.tableHeader,
                    }}
                  >
                    Bedrag
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 600,
                      background: mainGradients.tableHeader,
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(dashboardData.recentOrders || []).map((order, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": { backgroundColor: mainGradients.hover },
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/orders/${order.id}`)}
                  >
                    <TableCell>
                      <Typography variant="body2">
                        {formatFriendlyDate(order.createdAt)}
                      </Typography>
                    </TableCell>
                    {!isMobile && (
                      <TableCell>
                        <Typography variant="body2">{order.id}</Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography variant="body2">
                        {capitalizeFirstLetter(order.userName)}
                      </Typography>
                    </TableCell>
                    {!isMobile && (
                      <TableCell>
                        <Typography variant="body2">
                          {capitalizeFirstLetter(order.city)}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <Typography variant="body2">
                        {formatCurrency(order.totalPrice || 0)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "inline-block",
                          px: 1.5,
                          py: 0.5,
                          borderRadius: "12px",
                          fontSize: "0.75rem",
                          fontWeight: "medium",
                          ...getStatusStyle(
                            order.paymentStatus || order.stripePaymentStatus
                          ),
                        }}
                      >
                        {getStatusLabel(
                          order.paymentStatus || order.stripePaymentStatus
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {/* Maandelijkse omzet grafiek */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            OMZET PER MAAND {selectedYear}
          </Typography>
          {dashboardData.ordersByMonth?.some(
            (m) => m.amount > 0 || m.orders > 0
          ) ? (
            isMobile ? (
              // Mobiele lijstweergave
              <Box>
                {dashboardData.ordersByMonth.map((monthData, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: 2,
                      borderBottom: 1,
                      borderColor: "divider",
                      "&:last-child": { borderBottom: 0 },
                      bgcolor:
                        index % 2 === 0
                          ? "background.default"
                          : "background.paper",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      {monthData.month}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Omzet:
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        fontWeight="bold"
                      >
                        {formatCurrency(monthData.amount)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Aantal bestellingen:
                      </Typography>
                      <Typography
                        variant="body2"
                        color="secondary"
                        fontWeight="bold"
                      >
                        {monthData.orders}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              // Desktop grafiek weergave - blijft ongewijzigd
              <Box sx={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={dashboardData.ordersByMonth}
                    margin={{ top: 20, right: 50, left: 50, bottom: 80 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis
                      yAxisId="left"
                      orientation="left"
                      tickFormatter={(value) => formatCurrency(value)}
                      label={{
                        value: "Omzet",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      label={{
                        value: "Aantal Bestellingen",
                        angle: 90,
                        position: "insideRight",
                      }}
                    />
                    <RechartsTooltip
                      formatter={(value, name, { dataKey }) => {
                        if (dataKey === "amount") {
                          return [formatCurrency(value), "Omzet"];
                        } else if (dataKey === "orders") {
                          return [value, "Aantal bestellingen"];
                        }
                        return [value, name];
                      }}
                      labelFormatter={(label) => `Maand: ${label}`}
                    />
                    <Legend />
                    <Bar
                      yAxisId="left"
                      dataKey="amount"
                      fill="#FF4F00"
                      name="Omzet"
                    />
                    <Bar
                      yAxisId="right"
                      dataKey="orders"
                      fill="#82ca9d"
                      name="Aantal bestellingen"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            )
          ) : (
            <Box
              sx={{
                height: isMobile ? "auto" : 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "grey.100",
                borderRadius: 1,
              }}
            >
              <Typography color="text.secondary">
                Geen gegevens beschikbaar voor {selectedYear}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Kaart van Nederland */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            BESTELLINGEN OP KAART VAN NEDERLAND
          </Typography>
          {Object.keys(dashboardData.orderLocations || {}).length > 0 ? (
            <DashboardMap
              locations={dashboardData.orderLocations}
              geocodedLocations={geocodedLocations}
              setGeocodedLocations={setGeocodedLocations}
              geocodeCity={geocodeCity}
            />
          ) : (
            <Box
              sx={{
                height: isMobile ? 400 : 800,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "grey.100",
                borderRadius: 1,
              }}
            >
              <Typography color="text.secondary">
                Geen locatiegegevens beschikbaar voor {selectedYear}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Omzet per stad grafiek */}
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            OMZET EN BESTELLINGEN PER WOONPLAATS
            {isMobile && " (Top 5)"}
          </Typography>
          {dashboardData.cityAmounts?.length > 0 ? (
            isMobile ? (
              // Mobiele lijstweergave
              <Box>
                {dashboardData.cityAmounts.slice(0, 5).map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: 2,
                      borderBottom: 1,
                      borderColor: "divider",
                      "&:last-child": { borderBottom: 0 },
                      bgcolor:
                        index % 2 === 0
                          ? "background.default"
                          : "background.paper",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      {index + 1}. {item.city}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Omzet:
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        fontWeight="bold"
                      >
                        {formatCurrency(item.amount)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Aantal bestellingen:
                      </Typography>
                      <Typography
                        variant="body2"
                        color="secondary"
                        fontWeight="bold"
                      >
                        {item.count}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box sx={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={dashboardData.cityAmounts.slice(0, 25)}
                    margin={{ top: 20, right: 50, left: 50, bottom: 80 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="city"
                      angle={45}
                      textAnchor="start"
                      height={60}
                      interval={0}
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis
                      yAxisId="left"
                      orientation="left"
                      tickFormatter={(value) => formatCurrency(value)}
                      label={{
                        value: "Omzet",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      label={{
                        value: "Aantal Bestellingen",
                        angle: 90,
                        position: "insideRight",
                      }}
                    />
                    <RechartsTooltip
                      formatter={(value, name, { dataKey }) => {
                        if (dataKey === "amount") {
                          return [formatCurrency(value), "Omzet"];
                        }
                        return [value, "Aantal bestellingen"];
                      }}
                      labelFormatter={(label) => `Plaats: ${label}`}
                    />
                    <Legend verticalAlign="top" height={36} />
                    <Bar
                      yAxisId="left"
                      dataKey="amount"
                      name="Omzet"
                      fill="#FF4F00"
                      radius={[4, 4, 0, 0]}
                    />
                    <Bar
                      yAxisId="right"
                      dataKey="count"
                      name="Aantal bestellingen"
                      fill="#82ca9d"
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            )
          ) : (
            <Box
              sx={{
                height: isMobile ? "auto" : 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "grey.100",
                borderRadius: 1,
              }}
            >
              <Typography color="text.secondary">
                Geen gegevens beschikbaar voor {selectedYear}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );

  // Render de Analytics tab
  const renderAnalyticsTab = () => (
    <Box>
      <AnalyticsSection />
    </Box>
  );

  // Render de Productinformatie tab
  const renderProductInfoTab = () => (
    <Box>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Typography variant="h4">
          Productinformatie{!isMobile && ` ${selectedYear}`}
        </Typography>
      </Box>

      {/* Best verkopende items tabel */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6">BEST VERKOCHT</Typography>
            {!isMobile && (
              <Button
                variant="contained"
                onClick={() => setShowAllTopSelling(!showAllTopSelling)}
                sx={{
                  ml: "auto",
                  color: "#fff",
                  bgcolor: "primary.main",
                  "&:hover": { bgcolor: "primary.dark" },
                }}
              >
                {showAllTopSelling ? "TOON MINDER" : "TOON ALLE"}
              </Button>
            )}
          </Box>
          <TableContainer
            sx={{
              overflowX: isMobile ? "hidden" : "auto",
            }}
          >
            <Table
              sx={{
                width: "100%",
                tableLayout: isMobile ? "auto" : "fixed",
              }}
            >
              <TableHead>
                <TableRow>
                  {/* Naam kolom - breedte aangepast voor mobiel */}
                  <TableCell
                    sx={{
                      width: isMobile ? "40%" : "55%", // 40% voor mobiel, 55% voor desktop
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "left",
                    }}
                  >
                    <TableSortLabel
                      active={sortConfig.key === "name"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("name")}
                    >
                      Naam
                    </TableSortLabel>
                  </TableCell>

                  {/* Artikelnr. kolom - verborgen op mobiel */}
                  {!isMobile && (
                    <TableCell
                      sx={{
                        width: "15%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig.key === "artNumber"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("artNumber")}
                      >
                        Artikelnr.
                      </TableSortLabel>
                    </TableCell>
                  )}

                  {/* Verkocht kolom */}
                  <TableCell
                    sx={{
                      width: isMobile ? "30%" : "auto", // 30% voor mobiel
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                    }}
                  >
                    {isMobile ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faChartBar}
                          size="sm"
                          style={{ color: "#555" }}
                        />
                        <InfoPopover
                          title="Aantal Verkocht"
                          content="Dit geeft het aantal verkochte items weer."
                        />
                      </Box>
                    ) : (
                      <TableSortLabel
                        active={sortConfig.key === "totalSold"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("totalSold")}
                      >
                        Verkocht
                      </TableSortLabel>
                    )}
                  </TableCell>

                  {/* Voorraad kolom */}
                  <TableCell
                    sx={{
                      width: isMobile ? "30%" : "auto", // 30% voor mobiel
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                    }}
                  >
                    {isMobile ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBoxOpen}
                          size="sm"
                          style={{ color: "#555" }}
                        />
                        <InfoPopover
                          title="Aantal Voorraad"
                          content="Dit geeft het aantal beschikbare voorraad weer."
                        />
                      </Box>
                    ) : (
                      <TableSortLabel
                        active={sortConfig.key === "stockQuantity"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("stockQuantity")}
                      >
                        Voorraad
                      </TableSortLabel>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedItems(
                  dashboardData.topSellingItems,
                  showAllTopSelling,
                  "topSelling"
                ).map((item, index) => (
                  <TableRow key={index}>
                    {/* Naam cel */}
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                        padding: isMobile ? "8px 4px" : "16px",
                      }}
                    >
                      <Typography
                        variant={isMobile ? "body2" : "body1"}
                        sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                        title={item.name} // Tooltip voor volledige naam
                      >
                        {item.name.length > 20
                          ? `${item.name.substring(0, 20)}...`
                          : item.name}
                      </Typography>
                    </TableCell>

                    {/* Artikelnr. cel - verborgen op mobiel */}
                    {!isMobile && (
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "left",
                          padding: isMobile ? "8px 4px" : "16px",
                        }}
                      >
                        <Typography
                          variant={isMobile ? "body2" : "body1"}
                          sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                          title={item.artNumber}
                        >
                          {item.artNumber.length > 15
                            ? `${item.artNumber.substring(0, 15)}...`
                            : item.artNumber}
                        </Typography>
                      </TableCell>
                    )}

                    {/* Verkocht cel */}
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        padding: isMobile ? "8px 4px" : "16px",
                      }}
                    >
                      <Typography
                        variant={isMobile ? "body2" : "body1"}
                        sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                      >
                        {item.totalSold}
                      </Typography>
                    </TableCell>

                    {/* Voorraad cel */}
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        padding: isMobile ? "8px 4px" : "16px",
                      }}
                    >
                      <Typography
                        variant={isMobile ? "body2" : "body1"}
                        sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                      >
                        {item.stockQuantity}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Bijna uitverkocht tabel */}
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6">BIJNA UITVERKOCHT</Typography>
            {!isMobile && (
              <Button
                variant="contained"
                onClick={() => setShowAllLowStock(!showAllLowStock)}
                sx={{
                  ml: "auto",
                  color: "#fff",
                  bgcolor: "secondary.main",
                  "&:hover": { bgcolor: "secondary.dark" },
                }}
              >
                {showAllLowStock ? "TOON MINDER" : "TOON ALLE"}
              </Button>
            )}
          </Box>
          <TableContainer
            sx={{
              overflowX: isMobile ? "hidden" : "auto",
            }}
          >
            <Table
              sx={{
                width: "100%",
                tableLayout: isMobile ? "auto" : "fixed",
              }}
            >
              <TableHead>
                <TableRow>
                  {/* Naam kolom - breedte aangepast voor mobiel */}
                  <TableCell
                    sx={{
                      width: isMobile ? "40%" : "55%", // 40% voor mobiel, 55% voor desktop
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "left",
                    }}
                  >
                    <TableSortLabel
                      active={sortConfig.key === "name"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("name")}
                    >
                      Naam
                    </TableSortLabel>
                  </TableCell>

                  {/* Artikelnr. kolom - verborgen op mobiel */}
                  {!isMobile && (
                    <TableCell
                      sx={{
                        width: "15%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig.key === "artNumber"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("artNumber")}
                      >
                        Artikelnr.
                      </TableSortLabel>
                    </TableCell>
                  )}

                  {/* Verkocht kolom */}
                  <TableCell
                    sx={{
                      width: isMobile ? "30%" : "auto", // 30% voor mobiel
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                    }}
                  >
                    {isMobile ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faChartBar}
                          size="sm"
                          style={{ color: "#555" }}
                        />
                        <InfoPopover
                          title="Aantal Verkocht"
                          content="Dit geeft het aantal verkochte items weer."
                        />
                      </Box>
                    ) : (
                      <TableSortLabel
                        active={sortConfig.key === "totalSold"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("totalSold")}
                      >
                        Verkocht
                      </TableSortLabel>
                    )}
                  </TableCell>

                  {/* Voorraad kolom */}
                  <TableCell
                    sx={{
                      width: isMobile ? "30%" : "auto", // 30% voor mobiel
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                    }}
                  >
                    {isMobile ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBoxOpen}
                          size="sm"
                          style={{ color: "#555" }}
                        />
                        <InfoPopover
                          title="Aantal Voorraad"
                          content="Dit geeft het aantal beschikbare voorraad weer."
                        />
                      </Box>
                    ) : (
                      <TableSortLabel
                        active={sortConfig.key === "stockQuantity"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("stockQuantity")}
                      >
                        Voorraad
                      </TableSortLabel>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedItems(
                  dashboardData.lowStockItems,
                  showAllLowStock,
                  "lowStock"
                ).map((item, index) => (
                  <TableRow key={index}>
                    {/* Naam cel */}
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                        padding: isMobile ? "8px 4px" : "16px",
                      }}
                    >
                      <Typography
                        variant={isMobile ? "body2" : "body1"}
                        sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                        title={item.name} // Tooltip voor volledige naam
                      >
                        {item.name.length > 20
                          ? `${item.name.substring(0, 20)}...`
                          : item.name}
                      </Typography>
                    </TableCell>

                    {/* Artikelnr. cel - verborgen op mobiel */}
                    {!isMobile && (
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "left",
                          padding: isMobile ? "8px 4px" : "16px",
                        }}
                      >
                        <Typography
                          variant={isMobile ? "body2" : "body1"}
                          sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                          title={item.artNumber}
                        >
                          {item.artNumber.length > 15
                            ? `${item.artNumber.substring(0, 15)}...`
                            : item.artNumber}
                        </Typography>
                      </TableCell>
                    )}

                    {/* Verkocht cel */}
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        padding: isMobile ? "8px 4px" : "16px",
                      }}
                    >
                      <Typography
                        variant={isMobile ? "body2" : "body1"}
                        sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                      >
                        {item.totalSold}
                      </Typography>
                    </TableCell>

                    {/* Voorraad cel */}
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        padding: isMobile ? "8px 4px" : "16px",
                      }}
                    >
                      <Typography
                        variant={isMobile ? "body2" : "body1"}
                        sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                      >
                        {item.stockQuantity}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header met gradient */}
      <Box
        sx={{
          mb: 4,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-12px",
            left: isMobile ? "50%" : "0",
            transform: isMobile ? "translateX(-50%)" : "none",
            width: "100px",
            height: "4px",
            background: mainGradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              background: mainGradients.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Dashboard {selectedYear}
          </Typography>
          <FaqReader pageName="Dashboard" />
        </Box>
      </Box>

      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          aria-label="dashboard tabs"
          variant={isMobile ? "fullWidth" : "standard"}
        >
          <Tab label="Overzicht" />
          <Tab label="Analytics" />
          <Tab label="Producten" />
        </Tabs>
      </Box>

      {/* Tab panels */}
      <TabPanel value={selectedTab} index={0}>
        {renderOverviewTab()}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {renderAnalyticsTab()}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        {renderProductInfoTab()}
      </TabPanel>
    </Container>
  );
};

export default Dashboard;
