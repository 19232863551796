import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Chip,
  Tooltip,
  CircularProgress,
  Alert,
  Container,
  useTheme,
  useMediaQuery,
  Collapse,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField, // Toegevoegd voor zoekbalk
  InputAdornment, // Toegevoegd voor zoekbalk
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "@mui/icons-material/Search"; // Toegevoegd voor zoekbalk
import ClearIcon from "@mui/icons-material/Clear"; // Toegevoegd voor zoekbalk
import { format } from "date-fns";
import { nl } from "date-fns/locale";
import DiscountCodeForm from "../components/DiscountCodeForm";
import { fetchDiscountCodes, deleteDiscountCode } from "../services/api";
import FaqReader from "../components/FaqReader";

// Styling constants
const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

const DiscountCodes = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expandedRow, setExpandedRow] = useState(null);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingCode, setEditingCode] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCodeId, setSelectedCodeId] = useState(null);

  // Nieuwe state voor bulk selectie
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);

  // Nieuwe state voor zoekfunctionaliteit
  const [searchTerm, setSearchTerm] = useState("");

  // Sorting state
  const [sortConfig, setSortConfig] = useState({
    key: "code",
    direction: "asc",
  });

  // Filter state
  const [filterConfig, setFilterConfig] = useState("all");

  useEffect(() => {
    loadDiscountCodes();
  }, []);

  const loadDiscountCodes = async () => {
    try {
      setLoading(true);
      const data = await fetchDiscountCodes();
      console.log("Fetched Data:", data); // Inspect the structure

      // Determine if data has a 'data' property
      const codes = data.data ? data.data : data;
      setDiscountCodes(codes);
      setError(null);
    } catch (err) {
      setError(
        "Er is een fout opgetreden bij het ophalen van de kortingscodes."
      );
      console.error("Error loading discount codes:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDiscountCode(selectedCodeId);
      await loadDiscountCodes();
      setDeleteDialogOpen(false);
      setSelectedCodeId(null);
      // Verwijder de code ook uit geselecteerde codes indien nodig
      setSelectedCodes((prevSelected) =>
        prevSelected.filter((id) => id !== selectedCodeId)
      );
    } catch (error) {
      setError("Fout bij het verwijderen van de kortingscode.");
    }
  };

  const handleBulkDelete = async () => {
    try {
      await Promise.all(selectedCodes.map((id) => deleteDiscountCode(id)));
      setSelectedCodes([]);
      await loadDiscountCodes();
      setBulkDeleteDialogOpen(false);
    } catch (error) {
      setError("Fout bij het verwijderen van geselecteerde kortingscodes.");
    }
  };

  const handleEdit = (code) => {
    setEditingCode(code);
    setModalOpen(true);
  };

  const handleFormSuccess = () => {
    setModalOpen(false);
    setEditingCode(null);
    loadDiscountCodes();
  };

  const handleRowClick = (id) => {
    if (isMobile) {
      setExpandedRow(expandedRow === id ? null : id);
    }
  };

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  // Handle filter change
  const handleFilterChange = (event) => {
    setFilterConfig(event.target.value);
  };

  // Handle checkbox changes
  const handleCheckboxChange = (id) => {
    setSelectedCodes((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((codeId) => codeId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle select all
  const handleSelectAll = () => {
    if (
      selectedCodes.length === filteredAndSortedDiscountCodes.length &&
      filteredAndSortedDiscountCodes.length > 0
    ) {
      setSelectedCodes([]);
    } else {
      setSelectedCodes(filteredAndSortedDiscountCodes.map((code) => code.id));
    }
  };

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Clear search
  const clearSearch = () => {
    setSearchTerm("");
  };

  // Filter and sort discount codes based on sortConfig, filterConfig en searchTerm
  const filteredAndSortedDiscountCodes = useMemo(() => {
    if (!discountCodes.length) return [];

    let filtered = [...discountCodes];

    // Filter op zoekterm
    if (searchTerm) {
      const lowerCaseSearch = searchTerm.toLowerCase();
      filtered = filtered.filter((code) =>
        code.attributes.code.toLowerCase().includes(lowerCaseSearch)
      );
    }

    // Apply filtering based on filterConfig
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Zet tijd op 00:00:00

    switch (filterConfig) {
      case "valid":
        filtered = filtered.filter(
          (code) => new Date(code.attributes.endDate) >= today
        );
        break;
      case "usable":
        filtered = filtered.filter(
          (code) =>
            code.attributes.maxUses === null ||
            (code.attributes.currentUses === null
              ? true
              : code.attributes.currentUses < code.attributes.maxUses)
        );
        break;
      case "valid_and_usable":
        filtered = filtered.filter(
          (code) =>
            new Date(code.attributes.endDate) >= today &&
            (code.attributes.maxUses === null ||
              (code.attributes.currentUses === null
                ? true
                : code.attributes.currentUses < code.attributes.maxUses))
        );
        break;
      default:
        // 'all' - no filtering
        break;
    }

    // Sorteer de gefilterde data
    const sorted = filtered.sort((a, b) => {
      let aValue = a.attributes[sortConfig.key];
      let bValue = b.attributes[sortConfig.key];

      // Handle special cases
      if (sortConfig.key === "endDate") {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }
      if (sortConfig.key === "currentUses") {
        // Behandel null values als 0 voor sortering
        aValue = aValue === null ? 0 : aValue;
        bValue = bValue === null ? 0 : bValue;

        // Numerieke sortering
        return sortConfig.direction === "asc"
          ? aValue - bValue
          : bValue - aValue;
      }

      if (sortConfig.key === "discountType") {
        // Sort alphabetically
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (sortConfig.key === "maxUses") {
        // Handle nulls: treat null as Infinity (unlimited)
        aValue = aValue === null ? Infinity : aValue;
        bValue = bValue === null ? Infinity : bValue;
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    return sorted;
  }, [discountCodes, sortConfig, filterConfig, searchTerm]);

  // Reset selectedCodes when filtered list changes to ensure selection is within search criteria
  useEffect(() => {
    setSelectedCodes((prevSelected) =>
      prevSelected.filter((id) =>
        filteredAndSortedDiscountCodes.some((code) => code.id === id)
      )
    );
  }, [filteredAndSortedDiscountCodes]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  const renderDiscountTypeChip = (type) => {
    let color;
    let label;

    switch (type) {
      case "discountPercentage":
        color = "primary";
        label = "Percentage";
        break;
      case "discountPrice":
        color = "secondary";
        label = "Bedrag";
        break;
      case "freeProduct":
        color = "success";
        label = "Gratis product";
        break;
      default:
        color = "default";
        label = type;
    }

    return <Chip size="small" color={color} label={label} />;
  };

  const renderValue = (code) => {
    switch (code.attributes.discountType) {
      case "discountPercentage":
        return `${code.attributes.value}%`;
      case "discountPrice":
        return `€${code.attributes.value.toFixed(2)}`;
      case "freeProduct":
        return `Product ID: ${code.attributes.value}`;
      default:
        return code.attributes.value;
    }
  };

  // Helper function to render sortable header
  const renderSortableHeader = (key, label, width) => (
    <TableCell
      sx={{
        width,
        minWidth:
          key === "endDate"
            ? "140px" // Voor datums
            : key === "minOrderAmount"
            ? "100px" // Voor bedragen
            : key === "code"
            ? "120px" // Voor codes
            : "auto", // Voor de rest
        fontWeight: 600,
        background: mainGradients.tableHeader,
      }}
    >
      <TableSortLabel
        active={sortConfig.key === key}
        direction={sortConfig.key === key ? sortConfig.direction : "asc"}
        onClick={() => handleSort(key)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box
        sx={{
          mb: 6,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-12px",
            left: isMobile ? "50%" : "0",
            transform: isMobile ? "translateX(-50%)" : "none",
            width: "100px",
            height: "4px",
            background: mainGradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        {/* Container voor titel en knop */}
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isMobile ? "stretch" : "center",
            gap: isMobile ? 2 : 0,
          }}
        >
          {/* Titel met FAQ icon */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              variant="h4"
              sx={{
                mb: 3,
                fontWeight: "bold",
                background: mainGradients.primary,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Kortingscodes
            </Typography>
            <FaqReader pageName="Kortingscodes" />
          </Box>

          {/* Nieuwe kortingscode knop */}
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              setEditingCode(null);
              setModalOpen(true);
            }}
            sx={{
              background: mainGradients.primary,
              borderRadius: 2,
              transition: "all 0.2s ease",
              textTransform: "none",
              "&:hover": {
                background: mainGradients.primary,
                opacity: 0.9,
                transform: "translateY(-1px)",
                boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
              },
              width: isMobile ? "100%" : "auto", // Volledige breedte op mobiel
            }}
          >
            Nieuwe Kortingscode
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Zoek-, Filter- en Bulk Verwijder Sectie */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 2 : 0,
        }}
      >
        {/* Zoekbalk */}
        <TextField
          placeholder="Zoek op code..."
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{
            flex: 1,
            maxWidth: isMobile ? "100%" : "300px",
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              transition: "all 0.2s ease",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 75, 110, 0.5)",
                },
              },
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ff4b6e",
                  borderWidth: "2px",
                },
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "text.secondary" }} />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  onClick={clearSearch}
                  size="small"
                  sx={{
                    transition: "all 0.2s ease",
                    "&:hover": {
                      color: "#ff4b6e",
                    },
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Filter Dropdown */}
        <FormControl
          sx={{
            minWidth: isMobile ? "100%" : 200,
            width: isMobile ? "100%" : "auto",
          }}
        >
          <InputLabel id="filter-select-label">Toon</InputLabel>
          <Select
            labelId="filter-select-label"
            value={filterConfig}
            label="Toon"
            onChange={handleFilterChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                transition: "all 0.2s ease",
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 75, 110, 0.5)",
                  },
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ff4b6e",
                    borderWidth: "2px",
                  },
                },
              },
            }}
          >
            <MenuItem value="all">Alle Codes</MenuItem>
            <MenuItem value="valid">Alleen Geldige Codes</MenuItem>
            <MenuItem value="usable">Alleen Beschikbare Codes</MenuItem>
            <MenuItem value="valid_and_usable">
              Geldige & Beschikbare Codes
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Verwijder Geselecteerde knop alleen voor desktop */}
      {!isMobile && selectedCodes.length > 0 && (
        <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setBulkDeleteDialogOpen(true)}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          >
            Verwijder Geselecteerde
          </Button>
        </Box>
      )}

      {isMobile && (
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mb: 2, fontStyle: "italic" }}
        >
          Klik op een kortingscode voor details
        </Typography>
      )}

      {/* Tabel met Kortingscodes */}
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
          border: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {/* Selectievinkje kolom alleen voor desktop */}
              {!isMobile && (
                <TableCell
                  padding="checkbox"
                  sx={{
                    width: "40px", // Vaste breedte voor checkbox kolom
                    background: mainGradients.tableHeader,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedCodes.length ===
                        filteredAndSortedDiscountCodes.length &&
                      filteredAndSortedDiscountCodes.length > 0
                    }
                    indeterminate={
                      selectedCodes.length > 0 &&
                      selectedCodes.length <
                        filteredAndSortedDiscountCodes.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
              )}
              {renderSortableHeader("code", "Code", isMobile ? "70%" : "12%")}
              {!isMobile && (
                <>
                  {renderSortableHeader("discountType", "Type", "10%")}
                  {renderSortableHeader("value", "Waarde", "10%")}
                  {renderSortableHeader("minOrderAmount", "Min. Bedrag", "12%")}
                  {renderSortableHeader("endDate", "Geldig tot", "15%")}
                  {renderSortableHeader("maxUses", "Max. Gebruik", "8%")}
                  {renderSortableHeader("currentUses", "Gebruikt", "8%")}
                  {renderSortableHeader(
                    "disableFreeFireworks",
                    "Gratis VW",
                    "8%"
                  )}
                </>
              )}
              <TableCell
                align="right"
                sx={{
                  fontWeight: 600,
                  background: mainGradients.tableHeader,
                  width: isMobile ? "30%" : "7%", // Aangepaste breedte voor acties kolom
                }}
              >
                Acties
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedDiscountCodes.map((code) => (
              <React.Fragment key={code.id}>
                <TableRow
                  hover
                  onClick={() => handleRowClick(code.id)}
                  sx={{
                    cursor: isMobile ? "pointer" : "default",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      backgroundColor: mainGradients.hover,
                    },
                  }}
                >
                  {/* Selectievinkje alleen voor desktop */}
                  {!isMobile && (
                    <TableCell
                      padding="checkbox"
                      sx={{
                        width: "40px", // Vaste breedte voor checkbox kolom
                      }}
                    >
                      <Checkbox
                        checked={selectedCodes.includes(code.id)}
                        onChange={() => handleCheckboxChange(code.id)}
                        onClick={(e) => e.stopPropagation()} // Voorkomt row click bij checkbox
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <strong>{code.attributes.code}</strong>
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell>
                        {renderDiscountTypeChip(code.attributes.discountType)}
                      </TableCell>
                      <TableCell>{renderValue(code)}</TableCell>
                      <TableCell>
                        €{code.attributes.minOrderAmount.toFixed(2)}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Typography
                            variant="body2"
                            color={
                              new Date(code.attributes.endDate) < new Date()
                                ? "error.main"
                                : "text.primary"
                            }
                          >
                            {format(
                              new Date(code.attributes.endDate),
                              "dd MMMM yyyy",
                              { locale: nl }
                            )}
                          </Typography>
                          {new Date(code.attributes.endDate) < new Date() && (
                            <Chip
                              size="small"
                              color="error"
                              label="Verlopen"
                              sx={{
                                height: "16px",
                                "& .MuiChip-label": {
                                  px: 1,
                                  fontSize: "0.625rem",
                                },
                              }}
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {code.attributes.maxUses !== null ? (
                          code.attributes.maxUses
                        ) : (
                          <Typography variant="body2">Onbeperkt</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            code.attributes.maxUses !== null &&
                            code.attributes.currentUses >=
                              code.attributes.maxUses
                              ? "Maximum aantal keer gebruikt bereikt"
                              : `${code.attributes.currentUses || 0}x gebruikt`
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Typography
                              variant="body2"
                              color={
                                code.attributes.maxUses !== null &&
                                code.attributes.currentUses >=
                                  code.attributes.maxUses
                                  ? "error.main"
                                  : "text.primary"
                              }
                            >
                              {code.attributes.currentUses || 0}
                              {code.attributes.maxUses !== null && (
                                <span>/{code.attributes.maxUses}</span>
                              )}
                            </Typography>
                            {code.attributes.maxUses !== null &&
                              code.attributes.currentUses >=
                                code.attributes.maxUses && (
                                <Chip
                                  size="small"
                                  color="error"
                                  label="Max"
                                  sx={{
                                    height: "16px",
                                    "& .MuiChip-label": {
                                      px: 1,
                                      fontSize: "0.625rem",
                                    },
                                  }}
                                />
                              )}
                          </Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          color={
                            code.attributes.disableFreeFireworks
                              ? "error"
                              : "success"
                          }
                          label={
                            code.attributes.disableFreeFireworks ? "Nee" : "Ja"
                          }
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell align="right">
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(code);
                        }}
                        sx={{
                          color: "primary.main",
                          "&:hover": { color: "primary.dark" },
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedCodeId(code.id);
                          setDeleteDialogOpen(true);
                        }}
                        sx={{
                          color: "error.main",
                          "&:hover": { color: "error.dark" },
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
                {isMobile && (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <Collapse
                        in={expandedRow === code.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box
                          sx={{ p: 2, background: "rgba(248, 250, 252, 0.5)" }}
                        >
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{ fontWeight: 600 }}
                          >
                            Details
                          </Typography>
                          <Box sx={{ pl: 2 }}>
                            {[
                              {
                                label: "Type",
                                value: renderDiscountTypeChip(
                                  code.attributes.discountType
                                ),
                              },
                              { label: "Waarde", value: renderValue(code) },
                              {
                                label: "Minimum bedrag",
                                value: `€${code.attributes.minOrderAmount.toFixed(
                                  2
                                )}`,
                              },
                              {
                                label: "Geldig tot",
                                value: (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      color={
                                        new Date(code.attributes.endDate) <
                                        new Date()
                                          ? "error.main"
                                          : "text.primary"
                                      }
                                    >
                                      {format(
                                        new Date(code.attributes.endDate),
                                        "dd MMMM yyyy",
                                        { locale: nl }
                                      )}
                                    </Typography>
                                    {new Date(code.attributes.endDate) <
                                      new Date() && (
                                      <Chip
                                        size="small"
                                        color="error"
                                        label="Verlopen"
                                      />
                                    )}
                                  </Box>
                                ),
                              },
                              {
                                label: "Maximaal Gebruik",
                                value:
                                  code.attributes.maxUses !== null
                                    ? code.attributes.maxUses
                                    : "Onbeperkt",
                              },
                              {
                                label: "Aantal keer gebruikt",
                                value: (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      color={
                                        code.attributes.maxUses !== null &&
                                        code.attributes.currentUses >=
                                          code.attributes.maxUses
                                          ? "error.main"
                                          : "text.primary"
                                      }
                                    >
                                      {code.attributes.currentUses || 0}
                                      {code.attributes.maxUses !== null && (
                                        <>
                                          <span style={{ margin: "0 4px" }}>
                                            /
                                          </span>
                                          <span>{code.attributes.maxUses}</span>
                                        </>
                                      )}
                                    </Typography>
                                    {code.attributes.maxUses !== null &&
                                      code.attributes.currentUses >=
                                        code.attributes.maxUses && (
                                        <Chip
                                          size="small"
                                          color="error"
                                          label="Maximum bereikt"
                                          sx={{ ml: 1 }}
                                        />
                                      )}
                                  </Box>
                                ),
                              },
                              {
                                label: "Gratis vuurwerk",
                                value: (
                                  <Chip
                                    size="small"
                                    color={
                                      code.attributes.disableFreeFireworks
                                        ? "error"
                                        : "success"
                                    }
                                    label={
                                      code.attributes.disableFreeFireworks
                                        ? "Nee"
                                        : "Ja"
                                    }
                                  />
                                ),
                              },
                            ].map(({ label, value }) => (
                              <Typography
                                key={label}
                                variant="body2"
                                sx={{ mb: 1 }}
                              >
                                <strong>{label}:</strong>{" "}
                                <span style={{ verticalAlign: "middle" }}>
                                  {value}
                                </span>
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {filteredAndSortedDiscountCodes.length === 0 && !loading && (
        <Box
          sx={{
            textAlign: "center",
            py: 8,
            px: 2,
            background: "rgba(248, 250, 252, 0.5)",
            borderRadius: 2,
            border: "1px solid rgba(0,0,0,0.05)",
            mt: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#64748b", fontWeight: 500, mb: 1 }}
          >
            Geen kortingscodes gevonden
          </Typography>
          <Typography variant="body2" sx={{ color: "#94a3b8" }}>
            Maak een nieuwe kortingscode aan om te beginnen
          </Typography>
        </Box>
      )}

      {/* Edit/Add Modal */}
      <Dialog
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setEditingCode(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingCode ? "Kortingscode Bewerken" : "Nieuwe Kortingscode"}
        </DialogTitle>
        <DialogContent>
          <DiscountCodeForm
            initialData={editingCode}
            onSuccess={handleFormSuccess}
            onCancel={() => {
              setModalOpen(false);
              setEditingCode(null);
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Kortingscode Verwijderen</DialogTitle>
        <DialogContent>
          <Typography>
            Weet je zeker dat je deze kortingscode wilt verwijderen?
          </Typography>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, gap: 1 }}>
          <Button onClick={() => setDeleteDialogOpen(false)}>Annuleren</Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Verwijderen
          </Button>
        </Box>
      </Dialog>

      {/* Bulk Delete Confirmation Dialog */}
      <Dialog
        open={bulkDeleteDialogOpen}
        onClose={() => setBulkDeleteDialogOpen(false)}
      >
        <DialogTitle>Kortingscodes Verwijderen</DialogTitle>
        <DialogContent>
          <Typography>
            Weet je zeker dat je de geselecteerde kortingscodes wilt
            verwijderen?
          </Typography>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, gap: 1 }}>
          <Button onClick={() => setBulkDeleteDialogOpen(false)}>
            Annuleren
          </Button>
          <Button onClick={handleBulkDelete} variant="contained" color="error">
            Verwijderen
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
};

export default DiscountCodes;
