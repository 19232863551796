// src/pages/Bestellingen.js

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
  IconButton,
  Collapse,
  TextField,
  InputAdornment,
  Tooltip,
  Autocomplete,
  Checkbox,
  ListItemText,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from "@mui/icons-material/Refresh";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import api, {
  downloadOrder,
  getAvailableOrderIds,
  generateAllPDFs,
  downloadSelectedOrdersAsPDF,
} from "../services/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FaqReader from "../components/FaqReader";

import { parse, format, isValid } from "date-fns";
import { nl } from "date-fns/locale";

const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

const PaymentMethodCell = ({ method }) => {
  let color;
  let displayText;

  switch (method) {
    case "online":
    case "completed":
      color = "success.main";
      displayText = "Online";
      break;
    case "contant":
      color = "warning.main";
      displayText = "Contant";
      break;
    case "cancelled":
      color = "error.main";
      displayText = "Geannuleerd";
      break;
    case "refunded":
      color = "error.main";
      displayText = "Terugbetaald";
      break;
    case "open":
      color = "info.main";
      displayText = "Open";
      break;
    default:
      color = "text.secondary";
      displayText = method || "Onbekend";
  }

  return (
    <Typography
      variant="body2"
      sx={{
        color: color,
        fontWeight: "medium",
      }}
    >
      {displayText}
    </Typography>
  );
};

const Bestellingen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expandedRow, setExpandedRow] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState(() => {
    const params = new URLSearchParams(location.search);
    const yearParam = params.get("year");
    return yearParam ? parseInt(yearParam) : new Date().getFullYear();
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [selectedDate, setSelectedDate] = useState("All");
  const [availableDates, setAvailableDates] = useState([]);
  const [filterConfig, setFilterConfig] = useState([]);

  // State voor geselecteerde orders en loading
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [loadingDownloads, setLoadingDownloads] = useState({
    generateAll: false,
    selectedPDF: false,
  });

  // State voor berichten
  const [message, setMessage] = useState({
    type: "",
    text: "",
  });

  // Toegevoegde state om te controleren welke orders een pakbon hebben
  const [availableOrderIds, setAvailableOrderIds] = useState([]);

  // State om bij te houden of alle pakbonnen zijn wygemaakt (gegenereerd)
  const [generated, setGenerated] = useState(false);

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setSelectedYear(newYear);
    navigate(`/bestellingen?year=${newYear}`);
  };

  const yearOptions = Array.from(
    { length: new Date().getFullYear() - 2019 + 1 },
    (_, i) => 2019 + i
  );

  const parsePickupDate = (pickupDate) => {
    if (!pickupDate || pickupDate === "Onbekend") return null;

    const dateFormats = [
      "EEEE d-MM-yyyy",
      "EEEE d-M-yyyy",
      "EEEE d MMMM yyyy",
      "d-MM-yyyy",
      "d-M-yyyy",
      "d MMMM yyyy",
    ];

    for (let formatString of dateFormats) {
      let parsedDate = parse(pickupDate, formatString, new Date(), {
        locale: nl,
      });
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }

    return null;
  };
  const formatOrderDate = (date) => {
    if (!date) return "Onbekend";

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const orderDate = new Date(date);
    orderDate.setHours(0, 0, 0, 0);

    const diffDays = Math.floor((today - orderDate) / (1000 * 60 * 60 * 24));

    switch (diffDays) {
      case 0:
        return "Vandaag";
      case 1:
        return "Gisteren";
      case 2:
        return "Eergisteren";
      default:
        return format(date, "dd-MM-yyyy");
    }
  };

  // Memoiseer loadOrders met useCallback
  const loadOrders = useCallback(async (year) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.fetchAllOrders(year);

      const processedOrders = response.ordersResponse.data.map((order) => {
        const dayNumber = order.attributes.pickupDate;
        const date = parse(`${dayNumber}-12-2024`, "d-MM-yyyy", new Date());
        const formattedDate = format(date, "dd-MM-yyyy");

        return {
          id: order.id,
          type: "orders",
          userName: order.attributes.userName,
          email: order.attributes.email,
          phoneNumber: order.attributes.phoneNumber,
          city: "Onbekend",
          totalPrice: order.attributes.products.reduce(
            (sum, product) => sum + product.price * product.count,
            0
          ),
          pickupDate: formattedDate,
          pickupDateObj: date,
          successUrl: order.attributes.successUrl,
          paymentMethod: "online",
          createdAt: year >= 2024 ? new Date(order.attributes.createdAt) : null,
        };
      });

      const processedWebshopOrders = response.webshopOrdersResponse.data.map(
        (order) => {
          const dateObj = parsePickupDate(order.attributes.pickupDate);
          const y = dateObj ? dateObj.getFullYear() : null;

          let formattedDate;
          let paymentMethod;

          if (y && y < 2024) {
            formattedDate = order.attributes.pickupDate || "Onbekend";
            paymentMethod = order.attributes.paymentStatus || "Onbekend";
          } else {
            formattedDate = dateObj
              ? format(dateObj, "dd-MM-yyyy")
              : "Onbekend";
            paymentMethod = order.attributes.paymentStatus || "Onbekend";
          }

          return {
            id: order.id,
            type: "webshop-orders",
            userName: order.attributes.userName,
            email: order.attributes.email,
            phoneNumber: order.attributes.phoneNumber,
            city: order.attributes.city || "Onbekend",
            totalPrice: order.attributes.totalPrice,
            pickupDate: formattedDate,
            pickupDateObj: dateObj,
            successUrl: order.attributes.successUrl,
            paymentMethod: paymentMethod,
            createdAt: y >= 2024 ? new Date(order.attributes.createdAt) : null,
          };
        }
      );

      const combinedOrders = [...processedOrders, ...processedWebshopOrders];

      const filteredOrders = combinedOrders.filter((order) => {
        if (!order.pickupDateObj) {
          const pickupYear = parseInt(order.pickupDate.split("-").pop());
          return pickupYear === year;
        }
        return order.pickupDateObj.getFullYear() === year;
      });

      setOrders(filteredOrders);

      const uniqueDateSet = new Set(
        filteredOrders
          .map((order) => order.pickupDateObj)
          .filter((date) => date)
          .map((date) => format(date, "yyyy-MM-dd"))
      );

      const uniqueDates = Array.from(uniqueDateSet)
        .map((dateStr) => {
          const date = parse(dateStr, "yyyy-MM-dd", new Date());
          return {
            label: format(date, "dd-MM-yyyy"),
            value: dateStr,
          };
        })
        .sort((a, b) => new Date(a.value) - new Date(b.value));

      setAvailableDates(uniqueDates);
      setSelectedDate("All");

      if (parseInt(year) >= 2024) {
        try {
          const ids = await getAvailableOrderIds();
          setAvailableOrderIds(ids);
        } catch (err) {
          console.error("Error fetching available order IDs:", err);
        }
      } else {
        setAvailableOrderIds([]);
      }

      // Na wisselen van jaar reset generated
      setGenerated(false);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setError(
        "Er is een fout opgetreden bij het ophalen van de bestellingen."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadOrders(selectedYear);
  }, [selectedYear, loadOrders]); // Voeg 'loadOrders' toe als afhankelijkheid

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handleRowClick = (id) => {
    if (isMobile) {
      setExpandedRow(expandedRow === id ? null : id);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleDateChange = (event, newValue) => {
    if (newValue && newValue.value) {
      setSelectedDate(newValue.value);
    } else {
      setSelectedDate("All");
    }
  };

  const handleFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterConfig(typeof value === "string" ? value.split(",") : value);
  };

  const handlePrint = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    });

    const totalPagesExp = "{total_pages_count_string}";

    const tableColumn = isMobile
      ? ["Nr.", "Naam", "Bedrag", "Ophaaldag"]
      : [
          "Nr.",
          "Naam",
          "Email",
          "Telefoon",
          "Plaats",
          "Bedrag",
          "Besteldatum",
          "Betaling",
          "Ophaaldag",
        ];

    const tableRows = filteredAndSortedOrders.map((order) => {
      if (isMobile) {
        return [
          order.id,
          order.userName,
          `€${order.totalPrice.toFixed(2)}`,
          order.pickupDate === "Onbekend" ? "Onbekend" : order.pickupDate,
        ];
      } else {
        return [
          order.id,
          order.userName,
          order.email,
          order.phoneNumber,
          order.city,
          `€${order.totalPrice.toFixed(2)}`,
          order.createdAt ? formatOrderDate(order.createdAt) : "Onbekend",
          order.paymentMethod === "online" ||
          order.paymentMethod === "completed"
            ? "Online"
            : order.paymentMethod === "contant"
            ? "Contant"
            : order.paymentMethod === "cancelled"
            ? "Geannuleerd"
            : order.paymentMethod === "refunded"
            ? "Terugbetaald"
            : order.paymentMethod === "open"
            ? "Open"
            : order.paymentMethod || "Onbekend",
          order.pickupDate === "Onbekend" ? "Onbekend" : order.pickupDate,
        ];
      }
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [255, 75, 110] },
      theme: "grid",
      margin: { top: 20, bottom: 20, left: 10, right: 10 },
      pageBreak: "auto",
      showHead: "everyPage",
      didDrawPage: (data) => {
        let str = `Pagina ${doc.internal.getNumberOfPages()}`;
        if (typeof doc.putTotalPages === "function") {
          str = str + ` van ${totalPagesExp}`;
        }
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text(
          str,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );

        doc.setFontSize(14);
        doc.setTextColor(40);
        doc.text("Bestellingen", data.settings.margin.left, 15);
      },
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(`bestellingen_${selectedYear}.pdf`);
  };

  const filteredAndSortedOrders = useMemo(() => {
    if (!orders.length) return [];

    let filtered = orders;

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase().trim();
      filtered = filtered.filter(
        (order) =>
          order.id.toString().includes(searchLower) ||
          order.userName.toLowerCase().includes(searchLower) ||
          order.city.toLowerCase().includes(searchLower) ||
          (order.createdAt
            ? format(order.createdAt, "EEEE d MMMM yyyy", { locale: nl })
                .toLowerCase()
                .includes(searchLower)
            : "onbekend".includes(searchLower))
      );
    }

    if (selectedDate && selectedDate !== "All") {
      filtered = filtered.filter((order) => {
        const orderDateStr = order.pickupDateObj
          ? format(order.pickupDateObj, "yyyy-MM-dd")
          : order.pickupDate;
        return orderDateStr === selectedDate;
      });
    }

    if (parseInt(selectedYear) >= 2024 && filterConfig.length > 0) {
      filtered = filtered.filter((order) => {
        const method = order.paymentMethod;
        return filterConfig.includes(method);
      });
    }

    return filtered.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === "pickupDate") {
        aValue = a.pickupDateObj || new Date(0);
        bValue = b.pickupDateObj || new Date(0);
      }

      if (sortConfig.key === "createdAt") {
        aValue = a.createdAt || new Date(0);
        bValue = b.createdAt || new Date(0);
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [
    orders,
    sortConfig,
    searchTerm,
    selectedDate,
    filterConfig,
    selectedYear,
  ]);

  const handleDownloadOrderPDF = async (orderId) => {
    setLoadingDownloads((prev) => ({ ...prev, [orderId]: true }));
    try {
      const response = await downloadOrder(orderId);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Order_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading order PDF:", error);
    } finally {
      setLoadingDownloads((prev) => ({ ...prev, [orderId]: false }));
    }
  };

  // Verwijder handleDownloadPakbonnen functie
  /*
  const handleDownloadPakbonnen = async () => {
    const orderIdsToDownload =
      selectedOrderIds.length > 0
        ? selectedOrderIds
        : orders.map((order) => order.id);

    setLoadingDownloads((prev) => ({ ...prev, pakbonnen: true }));
    try {
      const response = await downloadSelectedOrders(orderIdsToDownload);
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `pakbonnen_${
          selectedOrderIds.length > 0 ? "selected" : "all"
        }_${selectedYear}.zip`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      setMessage({
        type: "success",
        text:
          selectedOrderIds.length > 0
            ? "Geselecteerde orders succesvol gedownload!"
            : "Alle orders succesvol gedownload!",
      });
      if (selectedOrderIds.length > 0) setSelectedOrderIds([]);
    } catch (error) {
      console.error("Error downloading pakbonnen:", error);
      setMessage({
        type: "error",
        text: "Fout bij het downloaden van pakbonnen.",
      });
    } finally {
      setLoadingDownloads((prev) => ({ ...prev, pakbonnen: false }));
    }
  };
  */

  // Nieuwe functie om geselecteerde orders als één samengevoegd PDF te downloaden
  const handleDownloadSelectedOrdersPDF = async () => {
    if (selectedOrderIds.length === 0) return;
    setLoadingDownloads((prev) => ({ ...prev, selectedPDF: true }));
    setMessage({ type: "", text: "" });
    try {
      const response = await downloadSelectedOrdersAsPDF(selectedOrderIds);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const currentDate = new Date()
        .toLocaleDateString("nl-NL")
        .replace(/\//g, "-");
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Geselecteerde_Orders_${currentDate}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      setMessage({
        type: "success",
        text: "Geselecteerde orders succesvol samengevoegd en gedownload!",
      });
    } catch (error) {
      console.error("Error downloading selected orders PDF:", error);
      setMessage({
        type: "error",
        text: "Fout bij het downloaden van de samengevoegde PDF.",
      });
    } finally {
      setLoadingDownloads((prev) => ({ ...prev, selectedPDF: false }));
    }
  };

  const handleGenerateAllPDFs = async () => {
    setLoadingDownloads((prev) => ({ ...prev, generateAll: true }));
    setMessage({ type: "", text: "" });
    try {
      const response = await generateAllPDFs();
      setMessage({
        type: "success",
        text: `Alle pakbonnen zijn gegenereerd! ${response.generatedFiles.length} PDF's aangemaakt.`,
      });
      setGenerated(true);

      // Alleen opnieuw de availableOrderIds ophalen, niet de hele loadOrders.
      // Zo update je direct welke pakbonnen beschikbaar zijn, zonder loop.
      if (parseInt(selectedYear) >= 2024) {
        try {
          const ids = await getAvailableOrderIds();
          setAvailableOrderIds(ids);
        } catch (err) {
          console.error("Error fetching available order IDs:", err);
        }
      }
    } catch (error) {
      console.error("Error generating all PDFs:", error);
      setMessage({
        type: "error",
        text: "Fout bij het genereren van de pakbonnen.",
      });
    } finally {
      setLoadingDownloads((prev) => ({ ...prev, generateAll: false }));
    }
  };

  const isSelected = (id) => selectedOrderIds.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredAndSortedOrders.map((order) => order.id);
      setSelectedOrderIds(newSelecteds);
      return;
    }
    setSelectedOrderIds([]);
  };

  const handleSelectClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selectedOrderIds.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOrderIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOrderIds.slice(1));
    } else if (selectedIndex === selectedOrderIds.length - 1) {
      newSelected = newSelected.concat(selectedOrderIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedOrderIds.slice(0, selectedIndex),
        selectedOrderIds.slice(selectedIndex + 1)
      );
    }

    setSelectedOrderIds(newSelected);
  };

  const renderSortableHeader = (key, label, width) => (
    <TableCell
      sx={{
        width,
        fontWeight: 600,
        color: "#1e293b",
        background: mainGradients.tableHeader,
        transition: "all 0.2s ease",
        "& .MuiTableSortLabel-root": {
          transition: "all 0.2s ease",
          "&:hover": {
            color: "#ff4b6e",
          },
          "&.Mui-active": {
            color: "#ff4b6e",
          },
        },
        "& .MuiTableSortLabel-icon": {
          transition: "all 0.2s ease",
        },
      }}
    >
      <TableSortLabel
        active={sortConfig.key === key}
        direction={sortConfig.direction}
        onClick={() => handleSort(key)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 200px)",
        }}
      >
        <CircularProgress
          sx={{
            color: "#ff4b6e",
            "& .MuiCircularProgress-circle": {
              strokeLinecap: "round",
            },
          }}
        />
      </Box>
    );
  }

  const paymentStatusOptions = [
    { value: "contant", label: "Contant" },
    { value: "online", label: "Online" },
    { value: "cancelled", label: "Geannuleerd" },
    { value: "refunded", label: "Terugbetaald" },
    { value: "open", label: "Open" },
  ];

  const feedbackText =
    selectedOrderIds.length > 0
      ? `${selectedOrderIds.length} ${
          selectedOrderIds.length === 1 ? "bestelling" : "bestellingen"
        } geselecteerd`
      : `${filteredAndSortedOrders.length} ${
          filteredAndSortedOrders.length === 1 ? "bestelling" : "bestellingen"
        } gevonden`;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box
        sx={{
          mb: 6,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-12px",
            left: isMobile ? "50%" : "0",
            transform: isMobile ? "translateX(-50%)" : "none",
            width: "100px",
            height: "4px",
            background: mainGradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              background: mainGradients.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Bestellingen
          </Typography>
          <FaqReader pageName="Bestellingen" />
        </Box>
      </Box>
      {parseInt(selectedYear) < 2024 && (
        <Alert severity="info" sx={{ mb: 3 }}>
          {selectedYear === 2020 || selectedYear === 2021
            ? "In dit jaar was er geen verkoop vanwege corona."
            : "Voor bestellingen uit dit jaar zijn alleen basis gegevens beschikbaar. Gedetailleerde orderinformatie is niet beschikbaar voor historische bestellingen."}
        </Alert>
      )}

      {message.text && (
        <Alert severity={message.type} sx={{ mb: 3 }}>
          {message.text}
        </Alert>
      )}

      {parseInt(selectedYear) >= 2024 && !generated && (
        <Alert severity="info" sx={{ mb: 3 }}>
          Voordat je pakbonnen kunt downloaden, dien je ze eerst te genereren.
          Klik op "Genereer Alle Pakbonnen".
        </Alert>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isMobile ? "stretch" : "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
              flex: 1,
            }}
          >
            <FormControl sx={{ minWidth: 120, flex: isMobile ? "1" : "none" }}>
              <InputLabel id="year-select-label">Jaar</InputLabel>
              <Select
                labelId="year-select-label"
                value={selectedYear}
                label="Jaar"
                onChange={handleYearChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                    transition: "all 0.2s ease",
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255, 75, 110, 0.5)",
                      },
                    },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ff4b6e",
                        borderWidth: "2px",
                      },
                    },
                  },
                }}
              >
                {yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Autocomplete
              options={availableDates}
              getOptionLabel={(option) => option.label}
              value={
                availableDates.find((date) => date.value === selectedDate) ||
                null
              }
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Datum"
                  placeholder="Alle van dit jaar"
                  variant="outlined"
                  sx={{
                    minWidth: 200,
                    flex: isMobile ? "1" : "none",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      transition: "all 0.2s ease",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(255, 75, 110, 0.5)",
                        },
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ff4b6e",
                          borderWidth: "2px",
                        },
                      },
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />

            {parseInt(selectedYear) >= 2024 && (
              <FormControl
                sx={{
                  minWidth: 200,
                  flex: isMobile ? "1" : "none",
                }}
              >
                <InputLabel id="filter-select-label">Betaling(en)</InputLabel>
                <Select
                  labelId="filter-select-label"
                  multiple
                  value={filterConfig}
                  label="Betaling(en)"
                  onChange={handleFilterChange}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "Betaling(en)";
                    } else if (selected.length === 1) {
                      const option = paymentStatusOptions.find(
                        (opt) => opt.value === selected[0]
                      );
                      return option ? option.label : "1 geselecteerd";
                    } else {
                      return `${selected.length} geselecteerd`;
                    }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      transition: "all 0.2s ease",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(255, 75, 110, 0.5)",
                        },
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ff4b6e",
                          borderWidth: "2px",
                        },
                      },
                    },
                  }}
                >
                  {paymentStatusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={filterConfig.includes(option.value)} />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
              alignItems: isMobile ? "stretch" : "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrint}
              sx={{
                background: mainGradients.primary,
                borderRadius: 2,
                transition: "all 0.2s ease",
                textTransform: "none",
                "&:hover": {
                  background: mainGradients.primary,
                  opacity: 0.9,
                  transform: "translateY(-1px)",
                  boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                },
                flex: isMobile ? "1" : "none",
              }}
            >
              Print Tabel
            </Button>

            {parseInt(selectedYear) >= 2024 && (
              <>
                {!generated && (
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleGenerateAllPDFs}
                    disabled={loadingDownloads.generateAll}
                    startIcon={<RefreshIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: 2,
                      textTransform: "none",
                      "&:hover": {
                        background: mainGradients.primary,
                        opacity: 0.9,
                        transform: "translateY(-1px)",
                        boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                      },
                      flex: isMobile ? "1" : "none",
                    }}
                  >
                    {loadingDownloads.generateAll
                      ? "Genereren..."
                      : "Genereer Alle Pakbonnen"}
                  </Button>
                )}

                {generated && (
                  <>
                    {/* Verwijder de ZIP-downloadknop */}
                    {/* 
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDownloadPakbonnen}
                      disabled={loadingDownloads["pakbonnen"]}
                      sx={{
                        background: mainGradients.primary,
                        borderRadius: 2,
                        transition: "all 0.2s ease",
                        textTransform: "none",
                        "&:hover": {
                          background: mainGradients.primary,
                          opacity: 0.9,
                          transform: "translateY(-1px)",
                          boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                        },
                        flex: isMobile ? "1" : "none",
                      }}
                    >
                      {loadingDownloads["pakbonnen"]
                        ? "Downloaden..."
                        : selectedOrderIds.length > 0
                        ? "Download Geselecteerde Pakbonnen (ZIP)"
                        : "Download Alle Pakbonnen (ZIP)"}
                    </Button>
                    */}

                    <Button
                      variant="contained"
                      onClick={handleDownloadSelectedOrdersPDF}
                      disabled={
                        selectedOrderIds.length === 0 ||
                        loadingDownloads.selectedPDF
                      }
                      startIcon={<PictureAsPdfIcon />}
                      sx={{
                        background: mainGradients.primary,
                        borderRadius: 2,
                        transition: "all 0.2s ease",
                        textTransform: "none",
                        "&:hover": {
                          background: mainGradients.primary,
                          opacity: 0.9,
                          transform: "translateY(-1px)",
                          boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                        },
                        flex: isMobile ? "1" : "none",
                      }}
                    >
                      {loadingDownloads.selectedPDF
                        ? "Downloaden..."
                        : "Download Geselecteerde Pakbonnen (PDF)"}
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            placeholder="Zoek op bestelnummer, naam of plaats..."
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                transition: "all 0.2s ease",
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 75, 110, 0.5)",
                  },
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ff4b6e",
                    borderWidth: "2px",
                  },
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "text.secondary" }} />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={clearSearch}
                    size="small"
                    sx={{
                      transition: "all 0.2s ease",
                      "&:hover": {
                        color: "#ff4b6e",
                      },
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
            {feedbackText}
          </Typography>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {isMobile && (
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mb: 2, fontStyle: "italic" }}
        >
          Klik op een bestelling voor details
        </Typography>
      )}

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
          border: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{
                  background: mainGradients.tableHeader,
                  transition: "all 0.2s ease",
                }}
              >
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedOrderIds.length > 0 &&
                    selectedOrderIds.length < filteredAndSortedOrders.length
                  }
                  checked={
                    filteredAndSortedOrders.length > 0 &&
                    selectedOrderIds.length === filteredAndSortedOrders.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "Select all orders",
                  }}
                />
              </TableCell>
              {renderSortableHeader("id", "Nr.", isMobile ? "35%" : "8%")}
              {isMobile && renderSortableHeader("userName", "Naam", "35%")}
              {!isMobile && (
                <>
                  {renderSortableHeader("userName", "Naam", "15%")}
                  {renderSortableHeader("email", "Email", "20%")}
                  {renderSortableHeader("phoneNumber", "Telefoon", "12%")}
                  {renderSortableHeader("city", "Plaats", "10%")}
                </>
              )}
              {renderSortableHeader(
                "totalPrice",
                "Bedrag",
                isMobile ? "30%" : "10%"
              )}
              {!isMobile &&
                renderSortableHeader("createdAt", "Besteldatum", "12%")}
              {!isMobile && (
                <>
                  {renderSortableHeader("paymentMethod", "Betaling", "10%")}
                  {renderSortableHeader("pickupDate", "Ophaaldag", "15%")}
                  <TableCell
                    align="center"
                    sx={{
                      width: "10%",
                      fontWeight: 600,
                      color: "#1e293b",
                      background: mainGradients.tableHeader,
                    }}
                  >
                    Details
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: "10%",
                      fontWeight: 600,
                      color: "#1e293b",
                      background: mainGradients.tableHeader,
                    }}
                  >
                    Pakbon
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedOrders.map((order) => {
              const isItemSelected = isSelected(order.id);
              const labelId = `order-checkbox-${order.id}`;

              return (
                <React.Fragment key={`${order.type}-${order.id}`}>
                  <TableRow
                    hover
                    onClick={() => handleRowClick(order.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    sx={{
                      cursor: isMobile ? "pointer" : "default",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        backgroundColor: mainGradients.hover,
                      },
                      "& > .MuiTableCell-root": {
                        borderBottom: "1px solid rgba(0,0,0,0.05)",
                      },
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) => handleSelectClick(event, order.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: "0.8125rem",
                        padding: "8px 16px",
                      }}
                      id={labelId}
                    >
                      <Tooltip title={order.id} arrow>
                        <span>{order.id}</span>
                      </Tooltip>
                    </TableCell>
                    {isMobile && (
                      <TableCell
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontSize: "0.8125rem",
                          padding: "8px 16px",
                        }}
                      >
                        <Tooltip title={order.userName} arrow>
                          <span>{order.userName}</span>
                        </Tooltip>
                      </TableCell>
                    )}
                    {!isMobile && (
                      <>
                        <TableCell
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "0.8125rem",
                            padding: "8px 16px",
                          }}
                        >
                          <Tooltip title={order.userName} arrow>
                            <span>{order.userName}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "0.8125rem",
                            padding: "8px 16px",
                          }}
                        >
                          <Tooltip title={order.email} arrow>
                            <span>{order.email}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "0.8125rem",
                            padding: "8px 16px",
                          }}
                        >
                          <Tooltip title={order.phoneNumber} arrow>
                            <span>{order.phoneNumber}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "0.8125rem",
                            padding: "8px 16px",
                          }}
                        >
                          <Tooltip title={order.city} arrow>
                            <span>{order.city}</span>
                          </Tooltip>
                        </TableCell>
                      </>
                    )}
                    <TableCell
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: "0.8125rem",
                        padding: "8px 16px",
                      }}
                    >
                      <Tooltip title={`€${order.totalPrice.toFixed(2)}`} arrow>
                        <span>€{order.totalPrice.toFixed(2)}</span>
                      </Tooltip>
                    </TableCell>
                    {!isMobile && (
                      <TableCell
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontSize: "0.8125rem",
                          padding: "8px 16px",
                        }}
                      >
                        <Tooltip
                          title={
                            order.createdAt
                              ? formatOrderDate(order.createdAt)
                              : "Onbekend"
                          }
                          arrow
                        >
                          <span>
                            {order.createdAt
                              ? formatOrderDate(order.createdAt)
                              : "Onbekend"}
                          </span>
                        </Tooltip>
                      </TableCell>
                    )}
                    {!isMobile && (
                      <>
                        <TableCell align="center">
                          <PaymentMethodCell method={order.paymentMethod} />
                        </TableCell>
                        <TableCell
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "0.8125rem",
                            padding: "8px 16px",
                          }}
                        >
                          <Tooltip title={order.pickupDate} arrow>
                            <span>{order.pickupDate}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "10%",
                            fontWeight: 600,
                            color: "#1e293b",
                          }}
                        >
                          {parseInt(selectedYear) >= 2024 ? (
                            <Button
                              component={Link}
                              to={`/orders/${order.id}`}
                              variant="contained"
                              size="small"
                              onClick={(e) => e.stopPropagation()}
                              sx={{
                                background: mainGradients.primary,
                                borderRadius: "6px",
                                boxShadow: "none",
                                transition: "all 0.2s ease",
                                textTransform: "none",
                                "&:hover": {
                                  background: mainGradients.primary,
                                  opacity: 0.9,
                                  transform: "translateY(-1px)",
                                  boxShadow:
                                    "0 4px 8px rgba(255, 75, 110, 0.2)",
                                },
                              }}
                            >
                              Details
                            </Button>
                          ) : (
                            <Tooltip
                              title="Orderdetails niet beschikbaar voor historische bestellingen"
                              arrow
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disabled
                                  sx={{
                                    bgcolor: "grey.300",
                                    "&.Mui-disabled": {
                                      bgcolor: "grey.300",
                                    },
                                  }}
                                >
                                  Details
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "10%",
                            fontWeight: 600,
                            color: "#1e293b",
                          }}
                        >
                          {parseInt(selectedYear) >= 2024 &&
                          availableOrderIds.includes(order.id) ? (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownloadOrderPDF(order.id);
                              }}
                              disabled={loadingDownloads[order.id]}
                              sx={{
                                background: mainGradients.primary,
                                borderRadius: "6px",
                                boxShadow: "none",
                                transition: "all 0.2s ease",
                                textTransform: "none",
                                "&:hover": {
                                  background: mainGradients.primary,
                                  opacity: 0.9,
                                  transform: "translateY(-1px)",
                                  boxShadow:
                                    "0 4px 8px rgba(255, 75, 110, 0.2)",
                                },
                              }}
                            >
                              {loadingDownloads[order.id]
                                ? "Downloaden..."
                                : "Pakbon"}
                            </Button>
                          ) : parseInt(selectedYear) >= 2024 ? (
                            <Tooltip
                              title="Voor deze order is geen pakbon beschikbaar"
                              arrow
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disabled
                                  sx={{
                                    bgcolor: "grey.300",
                                    "&.Mui-disabled": {
                                      bgcolor: "grey.300",
                                    },
                                  }}
                                >
                                  Pakbon
                                </Button>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Pakbon niet beschikbaar voor historische bestellingen"
                              arrow
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disabled
                                  sx={{
                                    bgcolor: "grey.300",
                                    "&.Mui-disabled": {
                                      bgcolor: "grey.300",
                                    },
                                  }}
                                >
                                  Pakbon
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  {isMobile && (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={4}
                      >
                        <Collapse
                          in={expandedRow === order.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box
                            sx={{
                              py: 2,
                              px: 1,
                              background: "rgba(248, 250, 252, 0.5)",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              sx={{
                                color: "#1e293b",
                                fontWeight: 600,
                              }}
                            >
                              Details
                            </Typography>
                            <Box sx={{ pl: 2 }}>
                              {[
                                {
                                  label: "Bedrag",
                                  value: `€${order.totalPrice.toFixed(2)}`,
                                },
                                {
                                  label: "Betaling",
                                  value: (
                                    <PaymentMethodCell
                                      method={order.paymentMethod}
                                    />
                                  ),
                                },
                                {
                                  label: "Ophaaldag",
                                  value:
                                    order.pickupDate === "Onbekend"
                                      ? "Onbekend"
                                      : order.pickupDateObj
                                      ? format(
                                          order.pickupDateObj,
                                          "dd-MM-yyyy"
                                        )
                                      : order.pickupDate,
                                },
                                {
                                  label: "Besteldatum",
                                  value: order.createdAt
                                    ? formatOrderDate(order.createdAt)
                                    : "Onbekend",
                                },
                                {
                                  label: "Naam",
                                  value: order.userName,
                                },
                                {
                                  label: "Email",
                                  value: order.email,
                                },
                                {
                                  label: "Telefoon",
                                  value: order.phoneNumber,
                                },
                                {
                                  label: "Plaats",
                                  value: order.city,
                                },
                              ].map(({ label, value }) => (
                                <Typography
                                  key={label}
                                  variant="body2"
                                  sx={{
                                    mb: 1,
                                    color: "#475569",
                                  }}
                                >
                                  <strong style={{ color: "#1e293b" }}>
                                    {label}:
                                  </strong>{" "}
                                  {value}
                                </Typography>
                              ))}
                              <Box
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                {parseInt(selectedYear) >= 2024 ? (
                                  <>
                                    <Button
                                      component={Link}
                                      to={`/orders/${order.id}`}
                                      variant="contained"
                                      size="small"
                                      fullWidth
                                      onClick={(e) => e.stopPropagation()}
                                      sx={{
                                        background: mainGradients.primary,
                                        borderRadius: "6px",
                                        boxShadow: "none",
                                        transition: "all 0.2s ease",
                                        textTransform: "none",
                                        "&:hover": {
                                          background: mainGradients.primary,
                                          opacity: 0.9,
                                          transform: "translateY(-1px)",
                                          boxShadow:
                                            "0 4px 8px rgba(255, 75, 110, 0.2)",
                                        },
                                      }}
                                    >
                                      Details bekijken
                                    </Button>
                                    {availableOrderIds.includes(order.id) ? (
                                      <Button
                                        variant="contained"
                                        size="small"
                                        fullWidth
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDownloadOrderPDF(order.id);
                                        }}
                                        disabled={loadingDownloads[order.id]}
                                        sx={{
                                          background: mainGradients.primary,
                                          borderRadius: "6px",
                                          boxShadow: "none",
                                          transition: "all 0.2s ease",
                                          textTransform: "none",
                                          "&:hover": {
                                            background: mainGradients.primary,
                                            opacity: 0.9,
                                            transform: "translateY(-1px)",
                                            boxShadow:
                                              "0 4px 8px rgba(255, 75, 110, 0.2)",
                                          },
                                        }}
                                      >
                                        {loadingDownloads[order.id]
                                          ? "Downloaden..."
                                          : "Pakbon"}
                                      </Button>
                                    ) : (
                                      <Tooltip
                                        title="Voor deze order is geen pakbon beschikbaar"
                                        arrow
                                      >
                                        <span>
                                          <Button
                                            variant="contained"
                                            size="small"
                                            fullWidth
                                            disabled
                                            sx={{
                                              bgcolor: "grey.300",
                                              "&.Mui-disabled": {
                                                bgcolor: "grey.300",
                                              },
                                            }}
                                          >
                                            Pakbon
                                          </Button>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </>
                                ) : (
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    align="center"
                                    sx={{ fontStyle: "italic" }}
                                  >
                                    Orderdetails en pakbon niet beschikbaar voor
                                    historische bestellingen
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Verwijder de loading indicator voor pakbonnen */}
      {loadingDownloads["selectedPDF"] && (
        <Box
          sx={{
            width: "100%",
            mt: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            size={24}
            sx={{
              color: "#ff4b6e",
            }}
          />
        </Box>
      )}

      {filteredAndSortedOrders.length === 0 && (
        <Box
          sx={{
            textAlign: "center",
            py: 8,
            px: 2,
            background: "rgba(248, 250, 252, 0.5)",
            borderRadius: 2,
            border: "1px solid rgba(0,0,0,0.05)",
            mt: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#64748b",
              fontWeight: 500,
              mb: 1,
            }}
          >
            Geen bestellingen gevonden
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#94a3b8",
            }}
          >
            Pas je zoekcriteria aan om andere bestellingen te vinden
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Bestellingen;
