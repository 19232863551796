import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  Grid,
  Chip,
  Link,
  Tooltip,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faInfoCircle,
  faStar,
  faShoppingCart,
  faPlay,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import ReactMarkdown from "react-markdown";
import api from "../services/api";
import FaqReader from "../components/FaqReader";

// Styling constants
const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

// ProductDetail Component
const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await api.getProductById(id);
        if (response.data && response.data.attributes) {
          setProduct(response.data);
          if (response.data.attributes.image?.data?.attributes?.url) {
            setSelectedImage(
              response.data.attributes.image.data.attributes.url
            );
          }
        } else {
          setError("Productgegevens zijn niet beschikbaar.");
        }
      } catch (err) {
        console.error("Error fetching product details:", err);
        setError(
          err.message ||
            "Er is een fout opgetreden bij het ophalen van de productdetails."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress sx={{ color: "#ff4b6e" }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => navigate(-1)}
          sx={{
            color: "text.primary",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          }}
        >
          Terug naar overzicht
        </Button>
      </Container>
    );
  }

  if (!product) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="info">Product niet gevonden</Alert>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => navigate("/producten")}
          sx={{
            mt: 2,
            color: "text.primary",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          }}
        >
          Terug naar Producten
        </Button>
      </Container>
    );
  }

  const {
    name,
    artNumber,
    price,
    priceStore,
    priceDiscount,
    category,
    supplier,
    stockQuantity,
    totalSold,
    shortDescription,
    longDescription,
    amount,
    weightGram,
    ean,
    countFreeFireworks,
    shots,
    kaliber,
    videoUrl,
    outOfStock,
    image,
    highlight,
    bgcolor,
    maxPerOrder,
    minimalAge,
    extraCategory,
  } = product.attributes;

  // Get stock status
  const getStockStatus = () => {
    if (outOfStock || stockQuantity <= 0) {
      return {
        label: "UITVERKOCHT",
        backgroundColor: "#fbe9e7",
        color: "#d32f2f",
      };
    }
    return {
      label: `${stockQuantity} OP VOORRAAD`,
      backgroundColor: "#e8f5e9",
      color: "#2e7d32",
    };
  };

  // Basis URL voor afbeeldingen
  const IMAGE_BASE_URL = "https://api.vuurwerkbruchem.com";

  // Functie om de volledige afbeeldings-URL te krijgen
  const getImageUrl = (url) => {
    if (!url) return "";
    return url.startsWith("/")
      ? `${IMAGE_BASE_URL}${url}`
      : `${IMAGE_BASE_URL}/${url}`;
  };

  // Functie om minimalAge te formatteren
  const getMinimalAge = () => {
    if (!minimalAge) return "Minimaal 16 jaar oud";
    const ageMap = {
      minimaal12JaarOud: "Minimaal 12 jaar oud",
      minimaal16JaarOud: "Minimaal 16 jaar oud",
      minimaal18JaarOud: "Minimaal 18 jaar oud",
    };
    return ageMap[minimalAge] || minimalAge;
  };

  // Combineer category en extraCategory
  const combinedCategories = [category];
  if (extraCategory) {
    combinedCategories.push(extraCategory);
  }
  const categoriesLabel =
    combinedCategories.length > 1 ? "Categorieën" : "Categorie";

  // Calculate savings percentage
  const calculateSavings = () => {
    if (priceDiscount && price) {
      const savingsAmount = price - priceDiscount;
      const savingsPercentage = (savingsAmount / price) * 100;
      return {
        amount: savingsAmount.toFixed(2),
        percentage: savingsPercentage.toFixed(0),
      };
    }
    return null;
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Product Titel & Navigatie */}
      <Box
        sx={{
          mb: 6,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-12px",
            left: isMobile ? "50%" : "0",
            transform: isMobile ? "translateX(-50%)" : "none",
            width: "100px",
            height: "4px",
            background: mainGradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                mb: 3,
                fontWeight: "bold",
                background: mainGradients.primary,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {name}
            </Typography>
            <FaqReader pageName="Producten" />
          </Box>
          {highlight && (
            <Tooltip title="Uitgelicht Product">
              <Chip
                icon={<FontAwesomeIcon icon={faStar} />}
                label="Uitgelicht"
                sx={{
                  backgroundColor: "#fff3dc",
                  color: "#ffb400",
                  fontWeight: "bold",
                }}
              />
            </Tooltip>
          )}
        </Box>
        <Button
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => navigate("/producten")}
          sx={{
            background: mainGradients.primary,
            "&:hover": {
              background: mainGradients.primary,
              opacity: 0.9,
              transform: "translateY(-1px)",
              boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
            },
          }}
        >
          Terug naar Productoverzicht
        </Button>
      </Box>

      {/* Main Content Card */}
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: bgcolor || "white",
          overflow: "visible",
        }}
      >
        <CardContent sx={{ p: { xs: 2, md: 4 } }}>
          {/* Status Chip bovenaan */}
          <Box sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
            <Chip
              label={getStockStatus().label}
              sx={{
                backgroundColor: getStockStatus().backgroundColor,
                color: getStockStatus().color,
                fontWeight: "bold",
                fontSize: "1rem",
                py: 2,
                px: 3,
                borderRadius: "16px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            />
          </Box>

          <Grid container spacing={4}>
            {/* Afbeelding Sectie */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  borderRadius: 2,
                  overflow: "hidden",
                  boxShadow: 2,
                }}
              >
                {image?.data?.attributes?.url && (
                  <Box
                    component="img"
                    src={getImageUrl(selectedImage)}
                    alt={name}
                    sx={{
                      width: "100%",
                      height: "auto",
                      transition: "transform 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  />
                )}
                {videoUrl && (
                  <Button
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faPlay} />}
                    component={Link}
                    href={videoUrl}
                    target="_blank"
                    sx={{
                      position: "absolute",
                      bottom: 16,
                      right: 16,
                      backgroundColor: "rgba(0,0,0,0.7)",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.85)",
                      },
                    }}
                  >
                    Bekijk Video
                  </Button>
                )}
              </Box>
            </Grid>

            {/* Product Info Sectie */}
            <Grid item xs={12} md={6}>
              {/* Prijs Sectie */}
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  mb: 3,
                  backgroundColor: "rgba(0,0,0,0.02)",
                  borderRadius: 2,
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  {priceDiscount && priceDiscount < price ? (
                    <>
                      <Typography
                        variant="h6"
                        sx={{
                          textDecoration: "line-through",
                          color: "text.secondary",
                          mb: 1,
                        }}
                      >
                        €{price.toFixed(2)}
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#d32f2f",
                          fontWeight: "bold",
                          mb: 1,
                        }}
                      >
                        €{priceDiscount.toFixed(2)}
                      </Typography>
                      {calculateSavings() && (
                        <Chip
                          label={`Bespaar ${calculateSavings().percentage}% (€${
                            calculateSavings().amount
                          })`}
                          sx={{
                            backgroundColor: "#fde7e7",
                            color: "#d32f2f",
                            fontWeight: "bold",
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                      €{price.toFixed(2)}
                    </Typography>
                  )}
                  {priceStore && (
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, color: "text.secondary" }}
                    >
                      Winkelprijs: €{priceStore.toFixed(2)}
                    </Typography>
                  )}
                </Box>
              </Paper>

              {/* Product Details */}
              <Grid container spacing={2} sx={{ mb: 3 }}>
                {[
                  { label: "Artikelnr", value: artNumber },
                  { label: "Leverancier", value: supplier },
                  { label: "Shots", value: shots },
                  { label: "Kaliber", value: kaliber },
                  {
                    label: "Gewicht",
                    value: weightGram ? `${weightGram}g` : null,
                  },
                  { label: "Verkocht", value: totalSold || 0 },
                  {
                    label: "Meetelt voor gratis",
                    value: countFreeFireworks ? "Ja" : "Nee",
                  },
                  {
                    label: categoriesLabel,
                    value: combinedCategories.join(", "),
                  },
                  { label: "Leeftijd", value: getMinimalAge() },
                  {
                    label: "Aantal per doos",
                    value: amount ? `${amount} stuks` : null,
                  },
                  maxPerOrder
                    ? { label: "Max. per order", value: maxPerOrder }
                    : null,
                ]
                  .filter((item) => item && item.value !== null)
                  .map((item, index) => (
                    <Grid item xs={6} key={index}>
                      <Paper
                        sx={{
                          p: 1.5,
                          height: "100%",
                          backgroundColor: "rgba(0,0,0,0.02)",
                        }}
                      >
                        <Typography variant="caption" color="text.secondary">
                          {item.label}
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {item.value}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>

              {/* Beschrijving */}
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Beschrijving
                </Typography>
                <Paper
                  sx={{
                    p: 2,
                    backgroundColor: "rgba(0,0,0,0.02)",
                    borderRadius: 2,
                  }}
                >
                  <ReactMarkdown>
                    {longDescription ||
                      shortDescription ||
                      "Geen beschrijving beschikbaar."}
                  </ReactMarkdown>
                </Paper>
              </Box>

              {/* Actie Knoppen */}
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mt: 4,
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  component={Link}
                  href={`https://www.vuurwerkbruchem.com/item/${id}`}
                  target="_blank"
                  startIcon={<FontAwesomeIcon icon={faShoppingCart} />}
                  sx={{
                    background: mainGradients.primary,
                    fontWeight: "bold",
                    px: 4,
                    py: 1.5,
                    "&:hover": {
                      background: mainGradients.primary,
                      opacity: 0.9,
                      transform: "translateY(-1px)",
                      boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                    },
                  }}
                >
                  Koop in Webshop
                </Button>
                {videoUrl && (
                  <Button
                    variant="outlined"
                    size="large"
                    component={Link}
                    href={videoUrl}
                    target="_blank"
                    startIcon={<FontAwesomeIcon icon={faVideo} />}
                    sx={{
                      fontWeight: "bold",
                      px: 4,
                      py: 1.5,
                      borderWidth: 2,
                      "&:hover": {
                        borderWidth: 2,
                        transform: "translateY(-1px)",
                      },
                    }}
                  >
                    Bekijk Video
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ProductDetail;
