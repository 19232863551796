// src/utils/useScrollToHash.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToHash = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // Wacht even tot de pagina geladen is
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      // Als er geen hash is, scroll naar boven
      window.scrollTo(0, 0);
    }
  }, [location]);
};
