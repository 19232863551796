import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Collapse,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
  Chip,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import StarIcon from "@mui/icons-material/Star";
import { Link as RouterLink } from "react-router-dom";
import api from "../services/api";
import FaqReader from "../components/FaqReader";

// Styling constants
const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  salesHeader: "linear-gradient(180deg, #fff3e0 0%, #ffe0b2 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
  warning: "linear-gradient(135deg, #fbbf24 0%, #f59e0b 100%)",
  success: "linear-gradient(135deg, #34d399 0%, #10b981 100%)",
  error: "linear-gradient(135deg, #ef4444 0%, #dc2626 100%)",
};

// Stock thresholds
const STOCK_THRESHOLDS = {
  LOW: 5,
  MEDIUM: 15,
};

const Producten = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [products, setProducts] = useState([]);
  const [productNeeds, setProductNeeds] = useState(null);
  const [loading, setLoading] = useState(true);
  const [needsLoading, setNeedsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [needsError, setNeedsError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const [expandedRow, setExpandedRow] = useState(null);

  // Load product needs only on initial page load
  useEffect(() => {
    const loadProductNeeds = async () => {
      setNeedsLoading(true);
      setNeedsError("");
      try {
        const data = await api.getRequiredProductsByDay();
        setProductNeeds(data);
      } catch (err) {
        console.error("Error in loadProductNeeds:", err);
        setNeedsError(
          err.message || "Fout bij het ophalen van productbehoeften per dag."
        );
      } finally {
        setNeedsLoading(false);
      }
    };

    loadProductNeeds();
  }, []);

  // Load all products on component mount
  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true);
      try {
        const response = await api.fetchAllProducts();
        setProducts(response);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Er is een fout opgetreden bij het ophalen van de producten.");
      } finally {
        setLoading(false);
      }
    };
    loadProducts();
  }, []);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handleRowClick = (id) => {
    if (isMobile) {
      setExpandedRow(expandedRow === id ? null : id);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  // Select the last three days from productNeeds
  const selectedDates = useMemo(() => {
    if (!productNeeds) return [];
    const sortedDates = Object.keys(productNeeds)
      .sort((a, b) => new Date(a) - new Date(b))
      .slice(0, 3);

    return sortedDates.map((date, index) => ({
      label: `D${index + 1}`,
      date,
    }));
  }, [productNeeds]);

  const filteredAndSortedProducts = useMemo(() => {
    let filtered = products;

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = products.filter(
        (product) =>
          product.attributes.name.toLowerCase().includes(searchLower) ||
          product.attributes.artNumber
            .toString()
            .toLowerCase()
            .includes(searchLower)
      );
    }

    return filtered.sort((a, b) => {
      let aValue = a.attributes[sortConfig.key];
      let bValue = b.attributes[sortConfig.key];

      if (["stockQuantity", "totalSold", "price"].includes(sortConfig.key)) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [products, sortConfig, searchTerm]);

  const getStockStatus = (quantity) => {
    if (quantity <= STOCK_THRESHOLDS.LOW) return "low";
    if (quantity <= STOCK_THRESHOLDS.MEDIUM) return "medium";
    return "good";
  };

  const renderStockStatus = (quantity) => {
    const status = getStockStatus(quantity);
    let color;
    let label;

    switch (status) {
      case "low":
        color = "#ef4444"; // red
        label = "Laag";
        break;
      case "medium":
        color = "#f59e0b"; // yellow
        label = "Genoeg";
        break;
      case "good":
        color = "#10b981"; // green
        label = "Veel";
        break;
      default:
        color = "#6b7280"; // gray
        label = "Onbekend";
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: 28,
            height: 28,
            borderRadius: "50%",
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          {quantity}
        </Box>
        <Typography variant="caption" sx={{ mt: 0.5, fontWeight: 500 }}>
          {label}
        </Typography>
      </Box>
    );
  };

  const renderSortableHeader = (
    key,
    label,
    width = "auto",
    background = mainGradients.tableHeader,
    align = "left"
  ) => (
    <TableCell
      sx={{
        width,
        maxWidth: width,
        fontWeight: 600,
        color: "#1e293b",
        background,
        textAlign: align,
        transition: "all 0.2s ease",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "& .MuiTableSortLabel-root": {
          transition: "all 0.2s ease",
          "&:hover": {
            color: "#ff4b6e",
          },
          "&.Mui-active": {
            color: "#ff4b6e",
          },
        },
      }}
    >
      <TableSortLabel
        active={sortConfig.key === key}
        direction={sortConfig.direction}
        onClick={() => handleSort(key)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const renderProductNeedsCell = (product, date) => {
    const needsForDate = productNeeds[date];
    let displayCount = "-";

    if (needsForDate) {
      const currentArtNumber = product.attributes.artNumber.toString();
      let totalCount = 0;

      // Count direct amount
      if (needsForDate[currentArtNumber]) {
        totalCount += needsForDate[currentArtNumber].count;
      }

      // If this article is 560, also count derived products
      if (currentArtNumber === "560" && needsForDate["5601"]) {
        totalCount += needsForDate["5601"].count * 2;
      }

      displayCount = totalCount > 0 ? totalCount : "-";
    }

    return displayCount;
  };

  if (loading || needsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 200px)",
        }}
      >
        <CircularProgress
          sx={{
            color: "#ff4b6e",
            "& .MuiCircularProgress-circle": {
              strokeLinecap: "round",
            },
          }}
        />
      </Box>
    );
  }

  if (error || needsError) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        {needsError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {needsError}
          </Alert>
        )}
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box
        sx={{
          mb: 6,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-12px",
            left: isMobile ? "50%" : "0",
            transform: isMobile ? "translateX(-50%)" : "none",
            width: "100px",
            height: "4px",
            background: mainGradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              background: mainGradients.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Producten
          </Typography>
          <FaqReader pageName="Producten" />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 2 : 0,
        }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: isMobile ? 2 : 0 }}
        >
          {filteredAndSortedProducts.length} producten gevonden
        </Typography>
        <TextField
          placeholder="Zoek product of artikelnummer..."
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              transition: "all 0.2s ease",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 75, 110, 0.5)",
                },
              },
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ff4b6e",
                  borderWidth: "2px",
                },
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "text.secondary" }} />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  onClick={clearSearch}
                  size="small"
                  sx={{
                    transition: "all 0.2s ease",
                    "&:hover": {
                      color: "#ff4b6e",
                    },
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
          border: "1px solid rgba(0,0,0,0.05)",
        }}
      >
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              {renderSortableHeader(
                "artNumber",
                "Art.Nr.",
                isMobile ? "20%" : "5%"
              )}
              {renderSortableHeader("name", "Naam", isMobile ? "40%" : "18%")}
              {renderSortableHeader("price", "Prijs", isMobile ? "20%" : "10%")}
              {renderSortableHeader(
                "totalSold",
                "Verkocht",
                isMobile ? "20%" : "6%",
                mainGradients.salesHeader,
                "center"
              )}

              {!isMobile &&
                selectedDates.map(({ label }) => (
                  <TableCell
                    key={label}
                    sx={{
                      fontWeight: 600,
                      color: "#1e293b",
                      background: mainGradients.salesHeader,
                      width: "5%",
                      maxWidth: "5%",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {label}
                  </TableCell>
                ))}

              {!isMobile &&
                renderSortableHeader(
                  "stockQuantity",
                  "Voorraad",
                  "6%",
                  undefined,
                  "center"
                )}
              {!isMobile &&
                renderSortableHeader("category", "Categorie", "10%")}
              {!isMobile && (
                <TableCell
                  sx={{
                    fontWeight: 600,
                    background: mainGradients.tableHeader,
                    width: "6%",
                    maxWidth: "6%",
                  }}
                >
                  Acties
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredAndSortedProducts.map((product) => (
              <React.Fragment key={product.id}>
                <TableRow
                  hover
                  onClick={() => handleRowClick(product.id)}
                  sx={{
                    cursor: isMobile ? "pointer" : "default",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      backgroundColor: mainGradients.hover,
                    },
                    "& > .MuiTableCell-root": {
                      borderBottom: "1px solid rgba(0,0,0,0.05)",
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      width: isMobile ? "20%" : "5%",
                      maxWidth: isMobile ? "20%" : "5%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Tooltip title={product.attributes.artNumber} arrow>
                      <span>{product.attributes.artNumber}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: isMobile ? "40%" : "18%",
                      maxWidth: isMobile ? "40%" : "18%",
                      fontWeight: 500,
                      color: "#1e293b",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {product.attributes.highlight && (
                        <Tooltip title="Uitgelicht product">
                          <StarIcon
                            sx={{ color: "#fbbf24", fontSize: "1rem" }}
                          />
                        </Tooltip>
                      )}
                      <Tooltip title={product.attributes.name} arrow>
                        <span>{product.attributes.name}</span>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: isMobile ? "20%" : "10%",
                      maxWidth: isMobile ? "20%" : "10%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: 0.5,
                      }}
                    >
                      {product.attributes.priceDiscount ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5,
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 500, color: "#1e293b" }}
                            >
                              €{product.attributes.priceDiscount.toFixed(2)}
                            </Typography>
                            <Typography
                              sx={{
                                textDecoration: "line-through",
                                color: "#9ca3af",
                                fontSize: "0.875rem",
                              }}
                            >
                              €{product.attributes.price.toFixed(2)}
                            </Typography>
                          </Box>
                          <Chip
                            size="small"
                            color="error"
                            label={`-${(
                              ((product.attributes.price -
                                product.attributes.priceDiscount) /
                                product.attributes.price) *
                              100
                            ).toFixed(0)}%`}
                          />
                        </>
                      ) : (
                        <Typography sx={{ fontWeight: 500, color: "#1e293b" }}>
                          €{product.attributes.price.toFixed(2)}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: isMobile ? "20%" : "5%",
                      maxWidth: isMobile ? "20%" : "5%",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Tooltip title={product.attributes.totalSold || 0} arrow>
                      <span>{product.attributes.totalSold || 0}</span>
                    </Tooltip>
                  </TableCell>

                  {/* Dynamic columns for selected dates - only on desktop */}
                  {!isMobile &&
                    selectedDates.map(({ date, label }) => (
                      <TableCell
                        key={label}
                        sx={{
                          width: "5%",
                          maxWidth: "5%",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Tooltip
                          title={renderProductNeedsCell(product, date)}
                          arrow
                        >
                          <span>{renderProductNeedsCell(product, date)}</span>
                        </Tooltip>
                      </TableCell>
                    ))}

                  {/* Stock and Category columns - only on desktop */}
                  {!isMobile && (
                    <TableCell
                      sx={{
                        width: "5%",
                        maxWidth: "5%",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {renderStockStatus(product.attributes.stockQuantity)}
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell
                      sx={{
                        width: "10%",
                        maxWidth: "10%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Tooltip title={product.attributes.category} arrow>
                        <span>{product.attributes.category}</span>
                      </Tooltip>
                    </TableCell>
                  )}

                  {/* Actions column - only on desktop */}
                  {!isMobile && (
                    <TableCell
                      align="right"
                      sx={{
                        width: "9%",
                        maxWidth: "9%",
                      }}
                    >
                      <Button
                        component={RouterLink}
                        to={`/producten/${product.id}`}
                        variant="contained"
                        size="small"
                        onClick={(e) => e.stopPropagation()}
                        sx={{
                          background: mainGradients.primary,
                          borderRadius: "6px",
                          boxShadow: "none",
                          transition: "all 0.2s ease",
                          textTransform: "none",
                          "&:hover": {
                            background: mainGradients.primary,
                            opacity: 0.9,
                            transform: "translateY(-1px)",
                            boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                          },
                        }}
                      >
                        Details
                      </Button>
                    </TableCell>
                  )}
                </TableRow>

                {/* Collapse view for mobile devices */}
                {isMobile && (
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={4}
                    >
                      <Collapse
                        in={expandedRow === product.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box
                          sx={{
                            py: 2,
                            px: 1,
                            background: "rgba(248, 250, 252, 0.5)",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{ color: "#1e293b", fontWeight: 600 }}
                          >
                            Details
                          </Typography>
                          <Box sx={{ pl: 2 }}>
                            {[
                              {
                                label: "Prijs",
                                value: product.attributes.priceDiscount ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      gap: 0.5,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: 500,
                                          color: "#1e293b",
                                        }}
                                      >
                                        €
                                        {product.attributes.priceDiscount.toFixed(
                                          2
                                        )}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          textDecoration: "line-through",
                                          color: "#9ca3af",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        €{product.attributes.price.toFixed(2)}
                                      </Typography>
                                    </Box>
                                    <Chip
                                      size="small"
                                      color="error"
                                      label={`-${(
                                        ((product.attributes.price -
                                          product.attributes.priceDiscount) /
                                          product.attributes.price) *
                                        100
                                      ).toFixed(0)}%`}
                                    />
                                  </Box>
                                ) : (
                                  `€${product.attributes.price.toFixed(2)}`
                                ),
                              },
                              {
                                label: "Voorraad",
                                value: renderStockStatus(
                                  product.attributes.stockQuantity
                                ),
                              },
                              {
                                label: "Verkocht",
                                value: product.attributes.totalSold || 0,
                              },
                              {
                                label: "Categorie",
                                value: product.attributes.category,
                              },
                            ].map(({ label, value }) => (
                              <Typography
                                key={label}
                                variant="body2"
                                sx={{ mb: 1, color: "#475569" }}
                              >
                                <strong style={{ color: "#1e293b" }}>
                                  {label}:
                                </strong>{" "}
                                <span style={{ verticalAlign: "middle" }}>
                                  {value}
                                </span>
                              </Typography>
                            ))}

                            {/* Required products per day (D1, D2, D3) in mobile view */}
                            {selectedDates.length > 0 && (
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                  Benodigd per dag
                                </Typography>
                                {selectedDates.map(({ date, label }) => {
                                  const displayCount = renderProductNeedsCell(
                                    product,
                                    date
                                  );
                                  return (
                                    <Typography
                                      key={label}
                                      variant="body2"
                                      sx={{ mb: 1, color: "#475569" }}
                                    >
                                      <strong style={{ color: "#1e293b" }}>
                                        {label}:
                                      </strong>{" "}
                                      {displayCount}
                                    </Typography>
                                  );
                                })}
                              </Box>
                            )}

                            <Box sx={{ mt: 2 }}>
                              <Button
                                component={RouterLink}
                                to={`/producten/${product.id}`}
                                variant="contained"
                                size="small"
                                fullWidth
                                sx={{
                                  background: mainGradients.primary,
                                  borderRadius: "6px",
                                  boxShadow: "none",
                                  transition: "all 0.2s ease",
                                  textTransform: "none",
                                  "&:hover": {
                                    background: mainGradients.primary,
                                    opacity: 0.9,
                                    transform: "translateY(-1px)",
                                    boxShadow:
                                      "0 4px 8px rgba(255, 75, 110, 0.2)",
                                  },
                                }}
                              >
                                Bekijk details
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {filteredAndSortedProducts.length === 0 && (
        <Box
          sx={{
            textAlign: "center",
            py: 8,
            px: 2,
            background: "rgba(248, 250, 252, 0.5)",
            borderRadius: 2,
            border: "1px solid rgba(0,0,0,0.05)",
            mt: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#64748b",
              fontWeight: 500,
              mb: 1,
            }}
          >
            Geen producten gevonden
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#94a3b8",
            }}
          >
            Pas je zoekcriteria aan om andere producten te vinden
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Producten;
