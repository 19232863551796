// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import api from "../services/api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        await api.getDashboardStats();
        setIsAuthenticated(true);
        setError(null);
      } catch (error) {
        console.error("Token validation error:", error);
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        setError("Je sessie is verlopen. Log opnieuw in om door te gaan.");
      }
    } catch (error) {
      console.error("Auth check error:", error);
      setIsAuthenticated(false);
      setError("Er is een fout opgetreden bij het controleren van je sessie.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        error,
        checkAuthStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth moet binnen een AuthProvider worden gebruikt");
  }
  return context;
};
