// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "./contexts/AuthContext";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    success: {
      main: "#4caf50",
    },
    background: {
      default: "#f5f5f5",
    },
    // Remove the global text color override
    // text: {
    //   primary: "#ffffff",
    // },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    // Remove global color overrides
    // h1: { color: "#ffffff" },
    // h2: { color: "#ffffff" },
    // h4: { color: "#ffffff" },
    // h6: { color: "#ffffff" },
    // body1: { color: "#ffffff" },
    // subtitle1: { color: "#ffffff" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "4px", // Reset to default or your preference
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <App />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
