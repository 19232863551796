import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";

const nlGeoUrl = "https://cartomap.github.io/nl/wgs84/provincie_2024.geojson";

const provincieMapping = {
  // Normaliseer naar officiële namen
  northholland: "Noord-Holland",
  noordholl: "Noord-Holland",
  nh: "Noord-Holland",
  southholland: "Zuid-Holland",
  zuidholl: "Zuid-Holland",
  zh: "Zuid-Holland",
  northbrabant: "Noord-Brabant",
  nbrabant: "Noord-Brabant",
  nb: "Noord-Brabant",
  fryslan: "Fryslân",
  friesland: "Fryslân",
  flevoland: "Flevoland",
  groningen: "Groningen",
  drenthe: "Drenthe",
  overijssel: "Overijssel",
  gelderland: "Gelderland",
  gld: "Gelderland",
  utrecht: "Utrecht",
  utr: "Utrecht",
  zeeland: "Zeeland",
  limburg: "Limburg",
  lim: "Limburg",
};

const normalizeProvincieName = (name) => {
  if (!name) return "";

  // Eerst kijk of de exacte naam matched (case-insensitive)
  const exactMatch = Object.values(provincieMapping).find(
    (p) => p.toLowerCase() === name.toLowerCase()
  );
  if (exactMatch) return exactMatch;

  // Anders proberen we te normaliseren
  const normalized = name
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z]/g, "")
    .trim();

  return provincieMapping[normalized] || name;
};

const GeographicSection = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getColorIntensity = (provincie, provincieData) => {
    const normalizedProvincie = normalizeProvincieName(provincie);
    const provinceStats = provincieData.find(
      (p) => normalizeProvincieName(p.provincie) === normalizedProvincie
    );

    if (!provinceStats) {
      console.debug(`Provincie matching info:`, {
        original: provincie,
        normalized: normalizedProvincie,
        available: provincieData.map((p) => p.provincie),
      });
      return "#F5F5F5";
    }

    const maxBezoekers = Math.max(...provincieData.map((p) => p.bezoekers));
    const intensity = provinceStats.bezoekers / maxBezoekers;
    return `rgba(136, 132, 216, ${intensity * 0.8 + 0.2})`;
  };

  const top5Cities = [...data]
    .sort((a, b) => b.bezoekers - a.bezoekers)
    .slice(0, 5)
    .map((city) => ({
      naam: city.stad,
      bezoekers: city.bezoekers,
      paginaWeergaven: city.paginaWeergaven,
    }));

  const provincieData = data.reduce((acc, curr) => {
    const provincie = normalizeProvincieName(curr.provincie);

    if (!acc[provincie]) {
      acc[provincie] = {
        provincie,
        bezoekers: 0,
        paginaWeergaven: 0,
        engagement: 0,
        steden: 0,
      };
    }

    acc[provincie].bezoekers += curr.bezoekers;
    acc[provincie].paginaWeergaven += curr.paginaWeergaven;
    acc[provincie].engagement =
      (acc[provincie].engagement + parseFloat(curr.engagement)) / 2;
    acc[provincie].steden += 1;

    return acc;
  }, {});

  const provincieRows = Object.values(provincieData).sort(
    (a, b) => b.bezoekers - a.bezoekers
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 1.5,
            border: 1,
            borderColor: "divider",
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Typography variant="subtitle2">{label}</Typography>
          {payload.map((item, index) => (
            <Typography key={index} variant="body2" color="text.secondary">
              {item.name}: {item.value.toLocaleString()}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Card sx={{ mb: 3, p: 2 }}>
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            Bezoekers per Provincie
          </Typography>
          <Box sx={{ height: 700 }}>
            <ComposableMap
              projection="geoMercator"
              projectionConfig={{
                scale: 6000,
                center: [5.5, 52.2],
              }}
            >
              <ZoomableGroup>
                <Geographies geography={nlGeoUrl}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const provinceData = provincieRows.find(
                        (p) =>
                          normalizeProvincieName(p.provincie) ===
                          normalizeProvincieName(geo.properties.statnaam)
                      );

                      return (
                        <Tooltip
                          key={geo.rsmKey}
                          title={
                            <Box p={1}>
                              <Typography variant="subtitle2">
                                {geo.properties.statnaam}
                              </Typography>
                              <Typography variant="body2">
                                Bezoekers:{" "}
                                {provinceData
                                  ? provinceData.bezoekers.toLocaleString()
                                  : 0}
                              </Typography>
                              <Typography variant="body2">
                                Steden: {provinceData ? provinceData.steden : 0}
                              </Typography>
                            </Box>
                          }
                        >
                          <Geography
                            geography={geo}
                            fill={getColorIntensity(
                              geo.properties.statnaam,
                              provincieRows
                            )}
                            stroke="#FFFFFF"
                            strokeWidth={0.5}
                            style={{
                              default: {
                                outline: "none",
                              },
                              hover: {
                                fill: "#FF4F00",
                                outline: "none",
                                cursor: "pointer",
                              },
                            }}
                          />
                        </Tooltip>
                      );
                    })
                  }
                </Geographies>
              </ZoomableGroup>
            </ComposableMap>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ mb: 3, p: 2 }}>
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            Top 5 Steden - Bezoekersstatistieken
          </Typography>
          <Box sx={{ height: 300, mt: 2 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={top5Cities}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="naam"
                  angle={isMobile ? -45 : 0}
                  textAnchor={isMobile ? "end" : "middle"}
                  height={60}
                />
                <YAxis />
                <RechartsTooltip content={<CustomTooltip />} />
                <Bar dataKey="bezoekers" name="Bezoekers" fill="#8884d8" />
                <Bar
                  dataKey="paginaWeergaven"
                  name="Paginaweergaven"
                  fill="#82ca9d"
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ p: 2 }}>
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            Overzicht per Provincie
          </Typography>
          <Box sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Provincie</TableCell>
                  <TableCell align="right">Bezoekers</TableCell>
                  <TableCell align="right">Paginaweergaven</TableCell>
                  <TableCell align="right">Engagement Rate</TableCell>
                  <TableCell align="right">Aantal Steden</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {provincieRows.map((row) => (
                  <TableRow key={row.provincie} hover>
                    <TableCell component="th" scope="row">
                      {row.provincie}
                    </TableCell>
                    <TableCell align="right">
                      {row.bezoekers.toLocaleString()}
                    </TableCell>
                    <TableCell align="right">
                      {row.paginaWeergaven.toLocaleString()}
                    </TableCell>
                    <TableCell align="right">
                      {row.engagement.toFixed(2)}%
                    </TableCell>
                    <TableCell align="right">{row.steden}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GeographicSection;
