import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Button,
  Alert,
  FormHelperText,
  Grid,
  InputAdornment,
} from "@mui/material";
import { format, parse } from "date-fns";
import { createDiscountCode, updateDiscountCode } from "../services/api";

const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
};

const DiscountCodeForm = ({ initialData, onSuccess, onCancel }) => {
  const [formData, setFormData] = useState({
    code: "",
    discountType: "discountPercentage",
    value: "",
    endDate: format(new Date(), "yyyy-MM-dd"),
    minOrderAmount: "",
    maxUses: "",
    comboItems: "",
    disableFreeFireworks: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (initialData) {
      setFormData({
        code: initialData.attributes.code,
        discountType: initialData.attributes.discountType,
        value: initialData.attributes.value,
        endDate: format(new Date(initialData.attributes.endDate), "yyyy-MM-dd"),
        minOrderAmount: initialData.attributes.minOrderAmount,
        maxUses: initialData.attributes.maxUses || "",
        comboItems: initialData.attributes.comboItems
          ? initialData.attributes.comboItems.join(", ")
          : "",
        disableFreeFireworks: initialData.attributes.disableFreeFireworks,
      });
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // We hoeven geen publishedAt meer toe te voegen
      const submitData = {
        code: formData.code,
        discountType: formData.discountType,
        value: Number(formData.value),
        endDate: formData.endDate,
        minOrderAmount: Number(formData.minOrderAmount),
        maxUses: formData.maxUses === "" ? null : Number(formData.maxUses),
        comboItems: formData.comboItems
          ? formData.comboItems.split(",").map((item) => Number(item.trim()))
          : null,
        disableFreeFireworks: formData.disableFreeFireworks,
      };

      console.log("Submitting data:", submitData);

      let response;
      if (initialData) {
        response = await updateDiscountCode(initialData.id, submitData);
      } else {
        response = await createDiscountCode(submitData);
      }

      console.log("API Response:", response);
      onSuccess();
    } catch (err) {
      console.error("Form error:", err);
      setError(
        err.response?.data?.message ||
          err.message ||
          "Er is een fout opgetreden bij het opslaan van de kortingscode."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleSwitchChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      disableFreeFireworks: event.target.checked,
    }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Kortingscode"
            value={formData.code}
            onChange={handleChange("code")}
            required
            variant="outlined"
            placeholder="ZOMER2024"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel>Type korting</InputLabel>
            <Select
              value={formData.discountType}
              onChange={handleChange("discountType")}
              label="Type korting"
            >
              <MenuItem value="discountPercentage">Percentage korting</MenuItem>
              <MenuItem value="discountPrice">Bedrag korting</MenuItem>
              <MenuItem value="freeProduct">Gratis product</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={
              formData.discountType === "discountPercentage"
                ? "Percentage"
                : formData.discountType === "discountPrice"
                ? "Bedrag"
                : "Product ID"
            }
            value={formData.value}
            onChange={handleChange("value")}
            required
            type="number"
            InputProps={{
              startAdornment:
                formData.discountType === "discountPrice" ? (
                  <InputAdornment position="start">€</InputAdornment>
                ) : formData.discountType === "discountPercentage" ? (
                  <InputAdornment position="start">%</InputAdornment>
                ) : null,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Geldig tot"
            type="date"
            value={formData.endDate}
            onChange={handleChange("endDate")}
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Minimum orderbedrag"
            value={formData.minOrderAmount}
            onChange={handleChange("minOrderAmount")}
            required
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Maximum aantal keer te gebruiken"
            value={formData.maxUses}
            onChange={handleChange("maxUses")}
            type="number"
            helperText="Laat leeg voor onbeperkt gebruik"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Verplichte producten (product IDs)"
            value={formData.comboItems}
            onChange={handleChange("comboItems")}
            helperText="Komma-gescheiden lijst van product IDs, bijvoorbeeld: 1255, 1111"
            placeholder="1255, 1111"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.disableFreeFireworks}
                onChange={handleSwitchChange}
              />
            }
            label="Gratis vuurwerk uitschakelen"
          />
          <FormHelperText>
            Indien ingeschakeld, telt deze bestelling niet mee voor gratis
            vuurwerk
          </FormHelperText>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button onClick={onCancel} disabled={loading}>
          Annuleren
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            background: mainGradients.primary,
            "&:hover": {
              background: mainGradients.primary,
              opacity: 0.9,
            },
          }}
        >
          {loading
            ? "Bezig met opslaan..."
            : initialData
            ? "Bijwerken"
            : "Aanmaken"}
        </Button>
      </Box>
    </Box>
  );
};

export default DiscountCodeForm;
