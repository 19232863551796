// src/services/api.js

import axios from "axios";
import { jwtDecode } from "jwt-decode";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EXCLUSIONS_URL = `${API_BASE_URL}/exclusions`;
const AUTH_URL = `${API_BASE_URL}/auth`;
const DASHBOARD_URL = `${API_BASE_URL}/dashboard`;

// Functie om JWT token in headers te zetten
const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // Decodeer het token om de expiry time te krijgen
    const decodedToken = jwtDecode(token);
    const expiryTime = decodedToken.exp * 1000; // `exp` is in seconden, converteer naar milliseconden
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpiry", expiryTime);
  } else {
    // Als er geen token is, gebruik de API_KEY als fallback
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${process.env.REACT_APP_API_KEY}`;
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiry");
  }
};

// Controleer of het token bijna verlopen is
const isTokenExpired = () => {
  const expiryTime = localStorage.getItem("tokenExpiry");
  if (!expiryTime) return true;
  return new Date().getTime() > expiryTime - 60000;
};

// Ververs token functie
const refreshAccessToken = async () => {
  try {
    const response = await axios.post(`${AUTH_URL}/refresh-token`, {
      refreshToken: localStorage.getItem("refreshToken"),
    });
    const { token, expiresIn } = response.data;
    setAuthToken(token, expiresIn);
    return token;
  } catch (error) {
    console.error("Fout bij het verversen van token:", error);
    setAuthToken(null);
    return null;
  }
};

// Token refresh interceptor
axios.interceptors.request.use(
  async (config) => {
    if (
      config.url.endsWith("/login") ||
      config.url.endsWith("/refresh-token")
    ) {
      // Voor login en refresh-token endpoints, gebruik de API_KEY
      config.headers.Authorization = `Bearer ${process.env.REACT_APP_API_KEY}`;
      return config;
    }

    let token = localStorage.getItem("token");

    if (!token || isTokenExpired()) {
      token = await refreshAccessToken();
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      // Als er geen token is, gebruik de API_KEY
      config.headers.Authorization = `Bearer ${process.env.REACT_APP_API_KEY}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API-functies

export const getOrderDetails = async (orderId) => {
  console.log("[API] Ophalen orderdetails voor ID:", orderId);
  try {
    const response = await axios.get(`${API_BASE_URL}/orders/${orderId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log("[API] Order details succesvol opgehaald:", response.data);
    return response.data;
  } catch (error) {
    console.error("[API] Error fetching order details:", error);
    throw error.response
      ? error.response.data
      : { message: "Netwerkfout bij ophalen orderdetails" };
  }
};

export const fetchAllOrders = async (selectedYear) => {
  console.log(`[API] Ophalen alle orders voor jaar: ${selectedYear}`);
  try {
    const response = await axios.get(`${API_BASE_URL}/orders`, {
      params: {
        selectedYear,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("[API] Error fetching all orders:", error);
    throw error;
  }
};

// Login functie
export const login = async (data) => {
  console.log("[API] Attempting login...");
  try {
    const response = await axios.post(`${AUTH_URL}/login`, data);
    const { token, username } = response.data;
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    setAuthToken(token);
    console.log("[API] Login successful");
    return response.data;
  } catch (error) {
    console.error("[API] Login error:", error);
    throw error.response ? error.response.data : { message: "Netwerkfout" };
  }
};
export const getAvailableOrderIds = async () => {
  console.log("[API] Fetching available order IDs");
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/available-orders`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data.orderIds;
  } catch (error) {
    console.error("[API] Error fetching available order IDs:", error);
    throw error.response
      ? error.response.data
      : { message: "Netwerkfout bij ophalen beschikbare order IDs" };
  }
};

export const getDashboardStats = async (year = new Date().getFullYear()) => {
  console.log("[API] Ophalen dashboard statistieken voor jaar:", year);
  try {
    const statsResponse = await axios.get(`${DASHBOARD_URL}/stats`, {
      params: { year },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    console.log("[API] Dashboard stats response:", statsResponse.data);

    return statsResponse.data; // Return de volledige response
  } catch (error) {
    console.error("[API] Error bij ophalen dashboard stats:", error);
    throw error.response
      ? error.response.data
      : { message: "Netwerkfout bij ophalen dashboard statistieken" };
  }
};

export const getDashboardLocations = async (
  year = new Date().getFullYear()
) => {
  console.log("[API] Ophalen dashboard locaties voor jaar:", year);
  try {
    const response = await axios.get(`${DASHBOARD_URL}/locations`, {
      params: { year },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log("[API] Dashboard locaties succesvol opgehaald");
    return response.data;
  } catch (error) {
    console.error("[API] Error bij ophalen dashboard locaties:", error);
    throw error.response
      ? error.response.data
      : { message: "Netwerkfout bij ophalen dashboard locaties" };
  }
};

// Exclusion functies
export const addExclusions = async (data) => {
  try {
    const response = await axios.post(`${EXCLUSIONS_URL}/add`, data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { message: "Netwerkfout" };
  }
};

export const viewExclusions = async (data) => {
  try {
    const response = await axios.post(`${EXCLUSIONS_URL}/view`, data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { message: "Netwerkfout" };
  }
};

export const removeExclusions = async (data) => {
  try {
    const response = await axios.post(`${EXCLUSIONS_URL}/remove`, data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { message: "Netwerkfout" };
  }
};

// Pickup moment functies
export const getPickupMoments = async (date) => {
  try {
    const response = await axios.get(`${EXCLUSIONS_URL}/pickup-moments`, {
      params: { date },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { message: "Netwerkfout" };
  }
};

export const updatePickupMoment = async (id, data) => {
  try {
    const response = await axios.put(
      `${EXCLUSIONS_URL}/pickup-moments/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { message: "Netwerkfout" };
  }
};

// Items en datums ophalen
export const fetchItems = async () => {
  try {
    const response = await axios.get(`${EXCLUSIONS_URL}/items`);
    return response.data.items;
  } catch (error) {
    throw error.response ? error.response.data : { message: "Netwerkfout" };
  }
};

export const fetchDates = async () => {
  try {
    const response = await axios.get(`${EXCLUSIONS_URL}/dates`);
    return response.data.dates;
  } catch (error) {
    throw error.response ? error.response.data : { message: "Netwerkfout" };
  }
};

// Download functies
export const downloadProductsExcel = async (date) => {
  console.log(`[API] Start downloadProductsExcel voor datum: ${date}`);
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/products-excel/${date}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );
    console.log("[API] Excel download succesvol");
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return { data: blob };
  } catch (error) {
    console.error("[API] Excel download error:", error);
    throw (
      error.response?.data || { message: "Netwerkfout bij downloaden Excel" }
    );
  }
};

export const downloadProductsPDF = async (date) => {
  console.log(`[API] Start downloadProductsPDF voor datum: ${date}`);
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/products-pdf/${date}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/pdf",
        },
      }
    );
    console.log("[API] PDF download succesvol");
    const blob = new Blob([response.data], { type: "application/pdf" });
    return { data: blob };
  } catch (error) {
    console.error("[API] PDF download error:", error);
    throw error.response?.data || { message: "Netwerkfout bij downloaden PDF" };
  }
};

export const downloadChecklist = async (
  date,
  primarySort = "pickupDate",
  secondarySort = "pickupTime"
) => {
  console.log(
    `[API] Start downloadChecklist voor datum: ${date} met sortering: Primary - ${primarySort}, Secondary - ${secondarySort}`
  );
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/checklist/${date}?primarySort=${primarySort}&secondarySort=${secondarySort}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/pdf",
        },
      }
    );
    console.log("[API] Checklist download succesvol");
    const blob = new Blob([response.data], { type: "application/pdf" });
    return { data: blob };
  } catch (error) {
    console.error("[API] Checklist download error:", error);
    throw (
      error.response?.data || {
        message: "Netwerkfout bij downloaden checklist",
      }
    );
  }
};

export const downloadChecklistForYear = async (
  year,
  primarySort = "pickupDate",
  secondarySort = "pickupTime"
) => {
  console.log(
    `[API] Start downloadChecklistForYear voor jaar: ${year} met sortering: Primary - ${primarySort}, Secondary - ${secondarySort}`
  );
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/checklist/year/${year}?primarySort=${primarySort}&secondarySort=${secondarySort}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/pdf",
        },
      }
    );
    console.log("[API] Jaarlijkse checklist download succesvol");
    const blob = new Blob([response.data], { type: "application/pdf" });
    return { data: blob };
  } catch (error) {
    console.error("[API] Jaarlijkse checklist download error:", error);
    throw (
      error.response?.data || {
        message: "Netwerkfout bij downloaden checklist voor het jaar",
      }
    );
  }
};

export const downloadOrder = async (orderId) => {
  console.log(`[API] Start downloadOrder voor order: ${orderId}`);
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/order/${orderId}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/pdf",
        },
      }
    );
    console.log("[API] Order download succesvol");
    const blob = new Blob([response.data], { type: "application/pdf" });
    return { data: blob };
  } catch (error) {
    console.error("[API] Order download error:", error);

    // Bepaal type fout: Netwerkfout of Serverfout
    let errorMessage = "Netwerkfout bij downloaden order";
    if (error.response) {
      if (error.response.status === 404) {
        errorMessage = `Order met ID ${orderId} bestaat niet.`;
      } else {
        errorMessage =
          error.response.data?.message || "Serverfout bij downloaden order";
      }
    }

    // Gooi een nieuw Error-object met het bericht
    throw new Error(errorMessage);
  }
};

export const downloadProductsForYear = async (year, sortOption = "name") => {
  console.log(
    `[API] Start downloadProductsForYear voor jaar: ${year} met sorteeroptie: ${sortOption}`
  );
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/products-excel/year/${year}?sortOption=${sortOption}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );
    console.log("[API] Jaarlijkse productlijst download succesvol");
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return { data: blob };
  } catch (error) {
    console.error("[API] Jaarlijkse productlijst download error:", error);
    throw (
      error.response?.data || {
        message: "Netwerkfout bij downloaden productlijst voor het jaar",
      }
    );
  }
};

export const generateAllPDFs = async () => {
  console.log("[API] Start generateAllPDFs request");
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/generate-pdfs`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("[API] Generate PDFs response:", response.data);
    return response.data;
  } catch (error) {
    console.error("[API] Generate PDFs error:", error);
    throw (
      error.response?.data || {
        message: "Netwerkfout bij het genereren van PDFs",
      }
    );
  }
};

export const fetchAllProducts = async () => {
  console.log("[API] Ophalen van alle producten");
  try {
    const response = await axios.get(`${API_BASE_URL}/products`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data.data;
  } catch (error) {
    console.error("[API] Fout bij het ophalen van producten:", error);
    throw error;
  }
};

export const downloadAllOrders = async () => {
  console.log("[API] Start downloadAllOrders");
  try {
    const response = await axios.get(`${API_BASE_URL}/downloads/all-orders`, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/zip",
      },
    });
    console.log("[API] Alle orders download succesvol");
    const blob = new Blob([response.data], { type: "application/zip" });
    return { data: blob };
  } catch (error) {
    console.error("[API] Alle orders download error:", error);
    throw (
      error.response?.data || {
        message: "Netwerkfout bij downloaden alle orders",
      }
    );
  }
};

// Nieuwe API Functie: Benodigde Producten per Dag
export const getRequiredProductsByDay = async () => {
  console.log("[API] Start getRequiredProductsByDay");
  try {
    const response = await axios.get(
      `${API_BASE_URL}/products/required-by-day`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    console.log(
      "[API] getRequiredProductsByDay response:",
      JSON.stringify(response.data, null, 2)
    );
    return response.data.data;
  } catch (error) {
    console.error("[API] Error fetching required products by day:", error);
    throw error.response
      ? error.response.data
      : { message: "Netwerkfout bij ophalen benodigde producten per dag" };
  }
};

export const getProductById = async (productId) => {
  console.log(`[API] Ophalen productdetails voor ID: ${productId}`);
  try {
    const response = await axios.get(`${API_BASE_URL}/products/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log("[API] Product details succesvol opgehaald:", response.data);
    return response.data;
  } catch (error) {
    console.error("[API] Error fetching product details:", error);
    throw error.response
      ? error.response.data
      : { message: "Netwerkfout bij ophalen productdetails" };
  }
};

// Discount codes functies
export const fetchDiscountCodes = async () => {
  console.log("[API] Ophalen van alle kortingscodes");
  try {
    const response = await axios.get(`${API_BASE_URL}/discount-codes`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data.data;
  } catch (error) {
    console.error("[API] Error fetching discount codes:", error);
    throw error;
  }
};

export const createDiscountCode = async (discountData) => {
  console.log("[API] Aanmaken nieuwe kortingscode");
  try {
    const response = await axios.post(
      `${API_BASE_URL}/discount-codes`,
      discountData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("[API] Error creating discount code:", error);
    throw error;
  }
};

export const updateDiscountCode = async (id, discountData) => {
  console.log(`[API] Bijwerken kortingscode met ID: ${id}`);
  try {
    const response = await axios.put(
      `${API_BASE_URL}/discount-codes/${id}`,
      discountData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("[API] Error updating discount code:", error);
    throw error;
  }
};

export const getDiscountCodeById = async (id) => {
  console.log(`[API] Ophalen kortingscode met ID: ${id}`);
  try {
    const response = await axios.get(`${API_BASE_URL}/discount-codes/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data.data;
  } catch (error) {
    console.error(`[API] Error fetching discount code ${id}:`, error);
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export const deleteDiscountCode = async (id) => {
  console.log(`[API] Verwijderen kortingscode met ID: ${id}`);
  try {
    await axios.delete(`${API_BASE_URL}/discount-codes/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return true;
  } catch (error) {
    console.error("[API] Error deleting discount code:", error);
    throw error;
  }
};

// Nieuwe functies voor downloads

export const getDownloadFilesList = async (year) => {
  console.log(`[API] Ophalen van de bestandenlijst voor jaar ${year}`);
  try {
    const response = await axios.get(`${API_BASE_URL}/downloads/list-files`, {
      params: { year }, // Voeg jaar toe als query parameter
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data.files;
  } catch (error) {
    console.error(`Fout bij het ophalen van de bestandenlijst voor jaar ${year}:`, error);
    throw error;
  }
};

export const downloadFile = async (filename) => {
  console.log(`[API] Downloaden van bestand: ${filename}`);
  try {
    const response = await axios.get(
      `${API_BASE_URL}/downloads/files/${filename}`,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Fout bij het downloaden van bestand ${filename}:`, error);
    throw error;
  }
};

// Voeg deze nieuwe functie toe naast de bestaande downloadSelectedOrders
export const downloadSelectedOrdersAsPDF = async (orderIds) => {
  console.log(
    `[API] Start downloadSelectedOrdersAsPDF voor orders: ${orderIds.join(
      ", "
    )}`
  );
  try {
    const response = await axios.post(
      `${API_BASE_URL}/downloads/merge-orders`, // Nieuwe endpoint
      { orderIds },
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/pdf", // Verander naar PDF
        },
      }
    );
    console.log("[API] Selected orders PDF download succesvol");
    const blob = new Blob([response.data], { type: "application/pdf" });
    return { data: blob };
  } catch (error) {
    console.error("[API] Selected orders PDF download error:", error);
    throw (
      error.response?.data || {
        message: "Netwerkfout bij downloaden geselecteerde orders als PDF",
      }
    );
  }
};
export const downloadSelectedOrders = async (orderIds) => {
  console.log(`[API] Start downloadSelectedOrders voor orders: ${orderIds}`);
  try {
    const response = await axios.post(
      `${API_BASE_URL}/downloads/orders`,
      { orderIds },
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/zip",
        },
      }
    );
    console.log("[API] Selected orders download succesvol");
    const blob = new Blob([response.data], { type: "application/zip" });
    return { data: blob };
  } catch (error) {
    console.error("[API] Selected orders download error:", error);
    throw (
      error.response?.data || {
        message: "Netwerkfout bij downloaden geselecteerde orders",
      }
    );
  }
};
// Exporteer alle functies
const api = {
  login,
  addExclusions,
  viewExclusions,
  removeExclusions,
  getPickupMoments,
  updatePickupMoment,
  fetchItems,
  fetchDates,
  downloadProductsExcel,
  fetchAllOrders,
  generateAllPDFs,
  downloadProductsPDF,
  downloadChecklist,
  downloadChecklistForYear,
  downloadOrder,
  downloadSelectedOrders,
  downloadSelectedOrdersAsPDF,
  downloadAllOrders,
  downloadProductsForYear,
  getDashboardStats,
  getDashboardLocations,
  fetchAllProducts,
  getRequiredProductsByDay,
  getOrderDetails,
  getAvailableOrderIds,
  getProductById,
  fetchDiscountCodes,
  getDiscountCodeById,
  createDiscountCode,
  updateDiscountCode,
  deleteDiscountCode,
  // Nieuwe functies toevoegen
  getDownloadFilesList,
  downloadFile,
};

export default api;
