// src/pages/AddExclusions.js

import React, { useEffect, useState } from "react";
import { addExclusions, fetchItems, fetchDates } from "../services/api";
import {
  Container,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Alert,
  Grid,
  CircularProgress,
  Autocomplete,
  TextField,
  Paper,
  IconButton,
  InputAdornment, // Zorg ervoor dat InputAdornment is geïmporteerd
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme, useMediaQuery } from "@mui/material";
import FaqReader from "../components/FaqReader";
const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

const AddExclusions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlot, setTimeSlot] = useState("Hele Dag");
  const [message, setMessage] = useState({ type: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openDateAutocomplete, setOpenDateAutocomplete] = useState(false);
  const [openItemsAutocomplete, setOpenItemsAutocomplete] = useState(false); // Nieuwe state voor items Autocomplete

  // Functie om berichten weer te geven
  const showMessage = (type, text) => {
    setMessage({ type, text });
    setTimeout(() => {
      setMessage({ type: "", text: "" });
    }, 5000);
  };

  // Fetch beschikbare data bij component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const itemsData = await fetchItems();
        const datesData = await fetchDates();
        setItems(itemsData);
        setDates(datesData);
      } catch (error) {
        setMessage({
          type: "error",
          text: error.message || "Fout bij het ophalen van data.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Verzend uitsluitingen
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setMessage({ type: "", text: "" });
    try {
      const response = await addExclusions({
        selectedItems: selectedItems.map((item) => item.name),
        selectedDate,
        timeSlot,
      });
      setMessage({ type: "success", text: response.message });
      setSelectedItems([]);
    } catch (error) {
      setMessage({
        type: "error",
        text: error.message || "Fout bij toevoegen van uitsluitingen.",
      });
    } finally {
      setSaving(false);
    }
  };

  // Verander geselecteerde items
  const handleItemSelect = (event, newValue) => {
    setSelectedItems(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header met gradient */}
      <Box
        sx={{
          mb: 6,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-12px",
            left: isMobile ? "50%" : "0",
            transform: isMobile ? "translateX(-50%)" : "none",
            width: "100px",
            height: "4px",
            background: mainGradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              background: mainGradients.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Toevoegen Uitsluitingen
          </Typography>
          <FaqReader pageName="TijdSloten" />
        </Box>
      </Box>

      {/* Zoek- en filtersectie */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 2 : 0,
        }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: isMobile ? 2 : 0 }}
        >
          {selectedDate && timeSlot
            ? `${
                selectedItems.length
              } items geselecteerd voor ${timeSlot.toLowerCase()} op ${new Date(
                selectedDate
              ).toLocaleDateString("nl-NL")}`
            : "Selecteer datum en tijdslot om uitsluitingen toe te voegen"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "flex-start", // Linkse uitlijning
            width: isMobile ? "100%" : "auto",
          }}
        >
          {/* Date Autocomplete */}
          <Autocomplete
            sx={{
              minWidth: 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                transition: "all 0.2s ease",
                paddingRight: "48px", // Verhoog paddingRight om ruimte te maken voor de iconen (2 IconButtons x 24px)
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 75, 110, 0.5)",
                  },
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ff4b6e",
                    borderWidth: "2px",
                  },
                },
              },
            }}
            value={selectedDate}
            onChange={(event, newValue) => {
              setSelectedDate(newValue);
            }}
            options={dates}
            open={openDateAutocomplete}
            onOpen={() => setOpenDateAutocomplete(true)}
            onClose={() => setOpenDateAutocomplete(false)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecteer Datum"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  // Laat de startAdornment intact zodat geselecteerde chips zichtbaar blijven
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          flexShrink: 0,
                        }}
                      >
                        {/* Arrow Icon */}
                        <IconButton
                          onClick={() =>
                            openDateAutocomplete
                              ? setOpenDateAutocomplete(false)
                              : setOpenDateAutocomplete(true)
                          }
                          size="small"
                          sx={{
                            padding: "2px",
                            transition: "all 0.2s ease",
                            "&:hover": {
                              color: "#ff4b6e",
                            },
                          }}
                        >
                          {openDateAutocomplete ? (
                            <ArrowDropUpIcon
                              sx={{ color: "text.secondary", fontSize: 20 }}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              sx={{ color: "text.secondary", fontSize: 20 }}
                            />
                          )}
                        </IconButton>
                        {/* Clear Icon */}
                        {selectedDate && (
                          <IconButton
                            onClick={() => setSelectedDate(null)}
                            size="small"
                            sx={{
                              padding: "2px",
                              transition: "all 0.2s ease",
                              "&:hover": {
                                color: "#ff4b6e",
                              },
                            }}
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {/* Tijdslot Select */}
          <FormControl fullWidth variant="outlined">
            <InputLabel id="timeslot-label">Selecteer Tijdslot</InputLabel>
            <Select
              labelId="timeslot-label"
              value={timeSlot}
              onChange={(e) => setTimeSlot(e.target.value)}
              label="Selecteer Tijdslot"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  transition: "all 0.2s ease",
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 75, 110, 0.5)",
                    },
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ff4b6e",
                      borderWidth: "2px",
                    },
                  },
                },
              }}
            >
              <MenuItem value="Hele Dag">Hele Dag</MenuItem>
              <MenuItem value="Ochtend">Ochtend</MenuItem>
              <MenuItem value="Middag">Middag</MenuItem>
              <MenuItem value="Avond">Avond</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Berichten */}
      {message.text && (
        <Alert severity={message.type} sx={{ mb: 3 }}>
          {message.text}
        </Alert>
      )}

      {/* Laadindicator */}
      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ my: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            border: "1px solid rgba(0,0,0,0.05)",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={items}
                  getOptionLabel={(option) => option.name}
                  value={selectedItems}
                  onChange={handleItemSelect}
                  open={openItemsAutocomplete}
                  onOpen={() => setOpenItemsAutocomplete(true)}
                  onClose={() => setOpenItemsAutocomplete(false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Selecteer Items"
                      placeholder="Kies items"
                      InputProps={{
                        ...params.InputProps,
                        // Laat de startAdornment intact zodat geselecteerde chips zichtbaar blijven
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5,
                                flexShrink: 0,
                              }}
                            >
                              {/* Arrow Icon */}
                              <IconButton
                                onClick={() =>
                                  openItemsAutocomplete
                                    ? setOpenItemsAutocomplete(false)
                                    : setOpenItemsAutocomplete(true)
                                }
                                size="small"
                                sx={{
                                  padding: "2px",
                                  transition: "all 0.2s ease",
                                  "&:hover": {
                                    color: "#ff4b6e",
                                  },
                                }}
                              >
                                {openItemsAutocomplete ? (
                                  <ArrowDropUpIcon
                                    sx={{
                                      color: "text.secondary",
                                      fontSize: 20,
                                    }}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    sx={{
                                      color: "text.secondary",
                                      fontSize: 20,
                                    }}
                                  />
                                )}
                              </IconButton>
                              {/* Clear Icon */}
                              {selectedItems.length > 0 && (
                                <IconButton
                                  onClick={() => setSelectedItems([])}
                                  size="small"
                                  sx={{
                                    padding: "2px",
                                    transition: "all 0.2s ease",
                                    "&:hover": {
                                      color: "#ff4b6e",
                                    },
                                  }}
                                >
                                  <ClearIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                              )}
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={
                    saving || !selectedDate || selectedItems.length === 0
                  }
                  sx={{
                    background: mainGradients.primary,
                    borderRadius: 2,
                    transition: "all 0.2s ease",
                    textTransform: "none",
                    "&:hover": {
                      background: mainGradients.primary,
                      opacity: 0.9,
                      transform: "translateY(-1px)",
                      boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                    },
                  }}
                >
                  {saving ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Toepassen Uitsluitingen"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>

          {/* Berichten */}
          {message.text && (
            <Alert severity={message.type} sx={{ mt: 3 }}>
              {message.text}
            </Alert>
          )}
        </Paper>
      )}

      {/* Geen resultaten */}
      {!loading && selectedDate && selectedItems.length === 0 && (
        <Box
          sx={{
            textAlign: "center",
            py: 8,
            px: 2,
            background: "rgba(248, 250, 252, 0.5)",
            borderRadius: 2,
            border: "1px solid rgba(0,0,0,0.05)",
            mt: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#64748b",
              fontWeight: 500,
              mb: 1,
            }}
          >
            Geen uitsluitingen geselecteerd
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#94a3b8",
            }}
          >
            Selecteer items, datum en tijdslot om uitsluitingen toe te voegen.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default AddExclusions;
