// src/pages/ViewExclusions.js

import React, { useEffect, useState } from "react";
import { viewExclusions, removeExclusions, fetchDates } from "../services/api";
import {
  Container,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Alert,
  List,
  ListItem,
  ListItemText,
  Grid,
  CircularProgress,
  Autocomplete,
  TextField,
  Paper,
  IconButton,
  Divider,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme, useMediaQuery } from "@mui/material";
import FaqReader from "../components/FaqReader";

const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

const ViewExclusions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlot, setTimeSlot] = useState("Hele Dag");
  const [excludedItems, setExcludedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [openDateAutocomplete, setOpenDateAutocomplete] = useState(false);
  const [openItemsAutocomplete, setOpenItemsAutocomplete] = useState(false);

  // Functie om berichten weer te geven
  const showMessage = (type, text) => {
    setMessage({ type, text });
    setTimeout(() => {
      setMessage({ type: "", text: "" });
    }, 5000);
  };

  // Fetch beschikbare data bij component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const datesData = await fetchDates();
        setDates(datesData);
      } catch (error) {
        showMessage("error", error.message || "Fout bij het ophalen van data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch uitsluitingen bij selectie van datum en tijdslot
  useEffect(() => {
    if (selectedDate && timeSlot) {
      const fetchExclusionsData = async () => {
        setLoading(true);
        try {
          const exclusions = await viewExclusions({ selectedDate, timeSlot });
          setExcludedItems(exclusions.excludedItems);
        } catch (error) {
          showMessage(
            "error",
            error.message || "Fout bij het ophalen van uitsluitingen."
          );
        } finally {
          setLoading(false);
        }
      };
      fetchExclusionsData();
    } else {
      setExcludedItems([]);
    }
  }, [selectedDate, timeSlot]);

  // Verwijder uitsluitingen
  const handleRemove = async (e) => {
    e.preventDefault();
    setRemoving(true);
    setMessage({ type: "", text: "" });
    try {
      const itemsToRemove = selectedItems.map((item) => item.name || item);

      const response = await removeExclusions({
        selectedDate,
        timeSlot,
        selectedItems: itemsToRemove,
      });
      showMessage("success", response.message);

      // Refresh uitgesloten items
      const refreshedExclusions = await viewExclusions({
        selectedDate,
        timeSlot,
      });
      setExcludedItems(refreshedExclusions.excludedItems);
      setSelectedItems([]);
    } catch (error) {
      showMessage(
        "error",
        error.message || "Fout bij verwijderen van uitsluitingen."
      );
    } finally {
      setRemoving(false);
    }
  };

  // Verander geselecteerde items
  const handleItemSelect = (event, newValue) => {
    setSelectedItems(newValue);
  };

  // Functie om dynamische titels te genereren
  const getTitle = () => {
    switch (timeSlot) {
      case "Ochtend":
      case "Middag":
      case "Avond":
        return `Uitgesloten Items voor de ${timeSlot.toLowerCase()}`;
      case "Hele Dag":
      default:
        return "Uitgesloten Items voor de hele dag";
    }
  };

  // Functie om dynamische berichtentekst te genereren
  const getMessageText = () => {
    switch (timeSlot) {
      case "Ochtend":
      case "Middag":
      case "Avond":
        return `Geen items uitgesloten op deze dag in het ${timeSlot.toLowerCase()}.`;
      case "Hele Dag":
      default:
        return `Geen items uitgesloten op deze dag.`;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header met gradient */}
      <Box
        sx={{
          mb: 6,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-12px",
            left: isMobile ? "50%" : "0",
            transform: isMobile ? "translateX(-50%)" : "none",
            width: "100px",
            height: "4px",
            background: mainGradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              background: mainGradients.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Inzien Uitsluitingen
          </Typography>
          <FaqReader pageName="TijdSloten" />
        </Box>
      </Box>

      {/* Zoek- en filtersectie */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 2 : 0,
        }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: isMobile ? 2 : 0 }}
        >
          {excludedItems.length} uitsluitingen gevonden
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "flex-start",
            width: isMobile ? "100%" : "auto",
          }}
        >
          {/* Date Autocomplete */}
          <Autocomplete
            sx={{
              minWidth: 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                transition: "all 0.2s ease",
                paddingRight: "48px",
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 75, 110, 0.5)",
                  },
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ff4b6e",
                    borderWidth: "2px",
                  },
                },
              },
            }}
            value={selectedDate}
            onChange={(event, newValue) => {
              setSelectedDate(newValue);
            }}
            options={dates}
            open={openDateAutocomplete}
            onOpen={() => setOpenDateAutocomplete(true)}
            onClose={() => setOpenDateAutocomplete(false)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecteer Datum"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          flexShrink: 0,
                        }}
                      >
                        {/* Arrow Icon */}
                        <IconButton
                          onClick={() =>
                            openDateAutocomplete
                              ? setOpenDateAutocomplete(false)
                              : setOpenDateAutocomplete(true)
                          }
                          size="small"
                          sx={{
                            padding: "2px",
                            transition: "all 0.2s ease",
                            "&:hover": {
                              color: "#ff4b6e",
                            },
                          }}
                        >
                          {openDateAutocomplete ? (
                            <ArrowDropUpIcon
                              sx={{ color: "text.secondary", fontSize: 20 }}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              sx={{ color: "text.secondary", fontSize: 20 }}
                            />
                          )}
                        </IconButton>
                        {/* Clear Icon */}
                        {selectedDate && (
                          <IconButton
                            onClick={() => setSelectedDate(null)}
                            size="small"
                            sx={{
                              padding: "2px",
                              transition: "all 0.2s ease",
                              "&:hover": {
                                color: "#ff4b6e",
                              },
                            }}
                          >
                            <ClearIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {/* Tijdslot Select */}
          <FormControl fullWidth variant="outlined">
            <InputLabel id="timeslot-label">Selecteer Tijdslot</InputLabel>
            <Select
              labelId="timeslot-label"
              value={timeSlot}
              onChange={(e) => setTimeSlot(e.target.value)}
              label="Selecteer Tijdslot"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  transition: "all 0.2s ease",
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 75, 110, 0.5)",
                    },
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ff4b6e",
                      borderWidth: "2px",
                    },
                  },
                },
              }}
            >
              <MenuItem value="Hele Dag">Hele Dag</MenuItem>
              <MenuItem value="Ochtend">Ochtend</MenuItem>
              <MenuItem value="Middag">Middag</MenuItem>
              <MenuItem value="Avond">Avond</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Berichten */}
      {message.text && (
        <Alert severity={message.type} sx={{ mb: 3 }}>
          {message.text}
        </Alert>
      )}

      {/* Laadindicator */}
      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ my: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            border: "1px solid rgba(0,0,0,0.05)",
          }}
        >
          <form onSubmit={handleRemove}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={excludedItems}
                  getOptionLabel={(option) => option.name || option}
                  value={selectedItems}
                  onChange={handleItemSelect}
                  open={openItemsAutocomplete}
                  onOpen={() => setOpenItemsAutocomplete(true)}
                  onClose={() => setOpenItemsAutocomplete(false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Uitgesloten Items"
                      placeholder="Selecteer items om te verwijderen"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5,
                                flexShrink: 0,
                              }}
                            >
                              {/* Arrow Icon */}
                              <IconButton
                                onClick={() =>
                                  openItemsAutocomplete
                                    ? setOpenItemsAutocomplete(false)
                                    : setOpenItemsAutocomplete(true)
                                }
                                size="small"
                                sx={{
                                  padding: "2px",
                                  transition: "all 0.2s ease",
                                  "&:hover": {
                                    color: "#ff4b6e",
                                  },
                                }}
                              >
                                {openItemsAutocomplete ? (
                                  <ArrowDropUpIcon
                                    sx={{
                                      color: "text.secondary",
                                      fontSize: 20,
                                    }}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    sx={{
                                      color: "text.secondary",
                                      fontSize: 20,
                                    }}
                                  />
                                )}
                              </IconButton>
                              {/* Clear Icon */}
                              {selectedItems.length > 0 && (
                                <IconButton
                                  onClick={() => setSelectedItems([])}
                                  size="small"
                                  sx={{
                                    padding: "2px",
                                    transition: "all 0.2s ease",
                                    "&:hover": {
                                      color: "#ff4b6e",
                                    },
                                  }}
                                >
                                  <ClearIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                              )}
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  disabled={selectedItems.length === 0 || removing}
                  sx={{
                    background: mainGradients.primary,
                    borderRadius: 2,
                    transition: "all 0.2s ease",
                    textTransform: "none",
                    "&:hover": {
                      background: mainGradients.primary,
                      opacity: 0.9,
                      transform: "translateY(-1px)",
                      boxShadow: "0 4px 8px rgba(255, 75, 110, 0.2)",
                    },
                  }}
                >
                  {removing ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Items Verwijderen"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>

          {/* Berichten */}
          {message.text && (
            <Alert severity={message.type} sx={{ mt: 3 }}>
              {message.text}
            </Alert>
          )}

          {/* Lijst van uitgesloten items */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              {getTitle()}
            </Typography>

            {excludedItems.length === 0 ? (
              <Typography variant="body1">{getMessageText()}</Typography>
            ) : (
              <List>
                {excludedItems.map((item, index) => {
                  const sortedDayParts = item.dayParts
                    ? item.dayParts.sort((a, b) => {
                        const order = ["ochtend", "middag", "avond"];
                        return (
                          order.indexOf(a.toLowerCase()) -
                          order.indexOf(b.toLowerCase())
                        );
                      })
                    : [];

                  return (
                    <ListItem
                      key={index}
                      sx={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                    >
                      <ListItemText
                        primary={item.name || item}
                        secondary={
                          timeSlot === "Hele Dag"
                            ? sortedDayParts.length > 0
                              ? `Item uitgesloten voor de dagdelen: ${sortedDayParts.join(
                                  ", "
                                )}`
                              : `Item uitgesloten voor de hele dag`
                            : sortedDayParts.length > 0
                            ? `Dagdelen: ${sortedDayParts.join(", ")}`
                            : ""
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Box>
        </Paper>
      )}

      {/* Geen resultaten */}
      {!loading && excludedItems.length === 0 && selectedDate && (
        <Box
          sx={{
            textAlign: "center",
            py: 8,
            px: 2,
            background: "rgba(248, 250, 252, 0.5)",
            borderRadius: 2,
            border: "1px solid rgba(0,0,0,0.05)",
            mt: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#64748b",
              fontWeight: 500,
              mb: 1,
            }}
          >
            Geen uitsluitingen gevonden
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#94a3b8",
            }}
          >
            Pas je zoekcriteria aan om andere uitsluitingen te vinden
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default ViewExclusions;
