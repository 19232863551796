// src/pages/ManagePickups.js

import React, { useEffect, useState } from "react";
import {
  getPickupMoments,
  updatePickupMoment,
  fetchDates,
} from "../services/api";
import {
  Container,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TextField,
  Button,
  IconButton,
  Alert,
  CircularProgress,
  Tooltip,
  Autocomplete,
  useTheme,
  useMediaQuery,
  Divider,
  Collapse,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import FaqReader from "../components/FaqReader";

// Styling constanten
const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

const ManagePickups = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openTooltip, setOpenTooltip] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [pickupMoments, setPickupMoments] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editValues, setEditValues] = useState({
    maxPickups: "",
  });
  const [message, setMessage] = useState({ type: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("time");
  const [order, setOrder] = useState("asc");

  // Functie om berichten weer te geven
  const showMessage = (type, text) => {
    setMessage({ type, text });
    setTimeout(() => {
      setMessage({ type: "", text: "" });
    }, 5000);
  };

  // Fetch beschikbare data bij component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const datesData = await fetchDates();
        // Filter dates voor alleen het huidige jaar
        const currentYear = new Date().getFullYear();
        const filteredDates = datesData.filter((dateStr) => {
          // We verwachten datums in het formaat "woensdag 31-12-2024" of "31-12-2024"
          const match = dateStr.match(/\d{2}-\d{2}-(\d{4})/);
          if (match) {
            const year = parseInt(match[1]);
            return year === currentYear;
          }
          return false;
        });
        setDates(filteredDates);
      } catch (error) {
        console.error(error);
        showMessage("error", error.message || "Fout bij het ophalen van data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch pickup moments bij selectie van een datum
  useEffect(() => {
    if (selectedDate) {
      const fetchPickupMomentsData = async () => {
        setLoading(true);
        try {
          const response = await getPickupMoments(selectedDate);
          setPickupMoments(response.pickupMoments);
        } catch (error) {
          console.error(error);
          showMessage(
            "error",
            error.message || "Fout bij het ophalen van pickup moments."
          );
        } finally {
          setLoading(false);
        }
      };

      fetchPickupMomentsData();
    } else {
      setPickupMoments([]);
    }
  }, [selectedDate]);

  // Sorteerfunctie
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Start bewerken
  const handleEditClick = (id, currentMax) => {
    setEditingId(id);
    setEditValues({ maxPickups: currentMax });
  };

  // Annuleer bewerken
  const handleCancelEdit = () => {
    setEditingId(null);
    setEditValues({ maxPickups: "" });
  };

  // Sla bewerkingen op
  const handleSaveEdit = async (id) => {
    try {
      const updatedData = {
        maxPickups: parseInt(editValues.maxPickups, 10),
      };

      await updatePickupMoment(id, updatedData);
      showMessage("success", "Pickup moment succesvol bijgewerkt.");

      const response = await getPickupMoments(selectedDate);
      setPickupMoments(response.pickupMoments);

      setEditingId(null);
      setEditValues({ maxPickups: "" });
    } catch (error) {
      console.error(error);
      showMessage(
        "error",
        error.message || "Fout bij het bijwerken van pickup moment."
      );
    }
  };

  // Vernieuw data
  const handleRefresh = async () => {
    if (selectedDate) {
      setLoading(true);
      try {
        const response = await getPickupMoments(selectedDate);
        setPickupMoments(response.pickupMoments);
        showMessage("success", "Data succesvol vernieuwd.");
      } catch (error) {
        showMessage("error", "Fout bij het vernieuwen van de data.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Verander inputwaarden
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditValues((prev) => ({ ...prev, [name]: value }));
  };

  // Formatteer tijd
  const formatTime = (timeString) => {
    return timeString.substring(0, 5);
  };

  // Klik op rij (voor mobiele weergave)
  const handleRowClick = (id) => {
    if (isMobile) {
      setExpandedRow(expandedRow === id ? null : id);
    }
  };

  // Render exclusieve artikelen
  const renderExcludedArticles = (articles) => {
    if (!articles || articles.length === 0) return "-";

    return articles.map((article, index) => (
      <Tooltip
        key={article}
        title={`Artikelnummer: ${article}`}
        placement="top"
        open={openTooltip === article}
        onClose={() => setOpenTooltip(null)}
        onClick={(e) => {
          e.stopPropagation();
          setOpenTooltip(openTooltip === article ? null : article);
        }}
        PopperProps={{
          onClick: (e) => e.stopPropagation(),
        }}
      >
        <span
          style={{
            cursor: "pointer",
            marginRight: index < articles.length - 1 ? "4px" : "0",
            textDecoration: "underline dotted",
            userSelect: "none",
          }}
        >
          {article}
          {index < articles.length - 1 ? ", " : ""}
        </span>
      </Tooltip>
    ));
  };

  // Sorteer pickup moments
  const sortedPickupMoments = React.useMemo(() => {
    if (!pickupMoments.length) return [];

    const comparator = (a, b) => {
      let comparison = 0;
      switch (orderBy) {
        case "time":
          comparison = a.time.localeCompare(b.time);
          break;
        case "maxPickups":
          comparison = a.maxPickups - b.maxPickups;
          break;
        case "currentPickups":
          comparison = a.currentPickups - b.currentPickups;
          break;
        case "availablePickups":
          comparison =
            a.maxPickups - a.currentPickups - (b.maxPickups - b.currentPickups);
          break;
        default:
          comparison = 0;
      }
      return order === "asc" ? comparison : -comparison;
    };

    return [...pickupMoments].sort(comparator);
  }, [pickupMoments, order, orderBy]);

  // Render sorteerde headers
  const renderSortableHeader = (key, label, width) => (
    <TableCell
      sx={{
        width,
        fontWeight: 600,
        color: "#1e293b",
        background: mainGradients.tableHeader,
        transition: "all 0.2s ease",
        "& .MuiTableSortLabel-root": {
          transition: "all 0.2s ease",
          "&:hover": {
            color: "#ff4b6e",
          },
          "&.Mui-active": {
            color: "#ff4b6e",
          },
        },
        "& .MuiTableSortLabel-icon": {
          transition: "all 0.2s ease",
        },
      }}
    >
      <TableSortLabel
        active={orderBy === key}
        direction={orderBy === key ? order : "asc"}
        onClick={() => handleRequestSort(key)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 200px)",
        }}
      >
        <CircularProgress
          sx={{
            color: "#ff4b6e",
            "& .MuiCircularProgress-circle": {
              strokeLinecap: "round",
            },
          }}
        />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header met gradient */}
      <Box
        sx={{
          mb: 6,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-12px",
            left: isMobile ? "50%" : "0",
            transform: isMobile ? "translateX(-50%)" : "none",
            width: "100px",
            height: "4px",
            background: mainGradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              background: mainGradients.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Beheer Ophaaltijden
          </Typography>
          <FaqReader pageName="TijdSloten" />
        </Box>
      </Box>

      {/* Zoek- en filtersectie */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 2 : 0,
        }}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: isMobile ? 2 : 0 }}
        >
          {pickupMoments.length} ophaalmomenten gevonden
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: isMobile ? "column" : "row",
            width: isMobile ? "100%" : "auto",
          }}
        >
          <Autocomplete
            sx={{
              minWidth: 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                transition: "all 0.2s ease",
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 75, 110, 0.5)",
                  },
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ff4b6e",
                    borderWidth: "2px",
                  },
                },
              },
            }}
            options={dates}
            value={selectedDate}
            onChange={(event, newValue) => {
              setSelectedDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecteer Datum"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <SearchIcon sx={{ color: "text.secondary", mr: 1 }} />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <IconButton
            onClick={handleRefresh}
            disabled={!selectedDate || loading}
            color="primary"
            size="large"
            sx={{
              transition: "all 0.2s ease",
              "&:hover": {
                color: "#ff4b6e",
              },
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Berichten */}
      {message.text && (
        <Alert severity={message.type} sx={{ mb: 3 }}>
          {message.text}
        </Alert>
      )}

      {/* Laadindicator */}
      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ my: 3 }}>
          <CircularProgress />
        </Box>
      ) : sortedPickupMoments.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            border: "1px solid rgba(0,0,0,0.05)",
          }}
        >
          <Table sx={{ tableLayout: "fixed", width: "100%" }}>
            <TableHead>
              <TableRow>
                {isMobile && <TableCell sx={{ width: "10%" }} />}{" "}
                {/* Kolom voor expand icon */}
                {renderSortableHeader("time", "Tijd", isMobile ? "45%" : "20%")}
                {!isMobile && (
                  <>
                    {renderSortableHeader("maxPickups", "Max. Ophalen", "20%")}
                    {renderSortableHeader(
                      "currentPickups",
                      "Bestellingen",
                      "20%"
                    )}
                    <TableCell
                      sx={{
                        width: "20%",
                        fontWeight: 600,
                        color: "#1e293b",
                        background: mainGradients.tableHeader,
                      }}
                    >
                      Beschikbaar
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "20%",
                        fontWeight: 600,
                        color: "#1e293b",
                        background: mainGradients.tableHeader,
                      }}
                    >
                      Uitgezonderd
                    </TableCell>
                  </>
                )}
                <TableCell
                  sx={{
                    width: isMobile ? "45%" : "20%",
                    fontWeight: 600,
                    color: "#1e293b",
                    background: mainGradients.tableHeader,
                  }}
                >
                  Acties
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedPickupMoments.map((pm) => {
                const availablePickups = pm.maxPickups - pm.currentPickups;
                return (
                  <React.Fragment key={pm.id}>
                    <TableRow
                      hover
                      onClick={() => handleRowClick(pm.id)}
                      sx={{
                        cursor: isMobile ? "pointer" : "default",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          backgroundColor: mainGradients.hover,
                        },
                        "& > .MuiTableCell-root": {
                          borderBottom: "1px solid rgba(0,0,0,0.05)",
                        },
                      }}
                    >
                      {isMobile && (
                        <TableCell>
                          <IconButton size="small">
                            {expandedRow === pm.id ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                      <TableCell>{formatTime(pm.time)}</TableCell>
                      {!isMobile && (
                        <>
                          <TableCell>
                            {editingId === pm.id ? (
                              <TextField
                                type="number"
                                name="maxPickups"
                                value={editValues.maxPickups}
                                onChange={handleInputChange}
                                inputProps={{ min: 0 }}
                                size="small"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: 2,
                                    transition: "all 0.2s ease",
                                    "&:hover": {
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(255, 75, 110, 0.5)",
                                      },
                                    },
                                    "&.Mui-focused": {
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#ff4b6e",
                                        borderWidth: "2px",
                                      },
                                    },
                                  },
                                }}
                              />
                            ) : (
                              pm.maxPickups
                            )}
                          </TableCell>
                          <TableCell>{pm.currentPickups}</TableCell>
                          <TableCell>{availablePickups}</TableCell>
                          <TableCell>
                            {renderExcludedArticles(pm.excludedArticles)}
                          </TableCell>
                        </>
                      )}
                      <TableCell>
                        {editingId === pm.id ? (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <IconButton
                              color="success"
                              onClick={() => handleSaveEdit(pm.id)}
                              size="small"
                              sx={{
                                transition: "all 0.2s ease",
                                "&:hover": {
                                  color: "#ff4b6e",
                                },
                              }}
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={handleCancelEdit}
                              size="small"
                              sx={{
                                transition: "all 0.2s ease",
                                "&:hover": {
                                  color: "#ff4b6e",
                                },
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        ) : (
                          <Button
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(pm.id, pm.maxPickups);
                            }}
                            size="small"
                            sx={{
                              borderColor: "#ff4b6e",
                              color: "#ff4b6e",
                              textTransform: "none",
                              transition: "all 0.2s ease",
                              "&:hover": {
                                background: "rgba(255, 75, 110, 0.08)",
                                borderColor: "#ff4b6e",
                              },
                            }}
                          >
                            Aanpassen
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    {/* Mobiele uitklapbare rij */}
                    {isMobile && (
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={3}
                        >
                          <Collapse
                            in={expandedRow === pm.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              sx={{
                                py: 2,
                                px: 1,
                                background: "rgba(248, 250, 252, 0.5)",
                                borderRadius: 2,
                              }}
                            >
                              {editingId === pm.id ? (
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{ mb: 1, fontWeight: "medium" }}
                                  >
                                    Max. Ophalen:
                                  </Typography>
                                  <TextField
                                    type="number"
                                    name="maxPickups"
                                    value={editValues.maxPickups}
                                    onChange={handleInputChange}
                                    inputProps={{ min: 0 }}
                                    size="small"
                                    fullWidth
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: 2,
                                        transition: "all 0.2s ease",
                                        "&:hover": {
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor:
                                                "rgba(255, 75, 110, 0.5)",
                                            },
                                        },
                                        "&.Mui-focused": {
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "#ff4b6e",
                                              borderWidth: "2px",
                                            },
                                        },
                                      },
                                    }}
                                  />
                                  <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                                    <IconButton
                                      color="success"
                                      onClick={() => handleSaveEdit(pm.id)}
                                      size="small"
                                      sx={{
                                        transition: "all 0.2s ease",
                                        "&:hover": {
                                          color: "#ff4b6e",
                                        },
                                      }}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                    <IconButton
                                      color="error"
                                      onClick={handleCancelEdit}
                                      size="small"
                                      sx={{
                                        transition: "all 0.2s ease",
                                        "&:hover": {
                                          color: "#ff4b6e",
                                        },
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      gridTemplateColumns: "1fr 80px",
                                      gap: 1,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      Beschikbaar gesteld:
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        textAlign: "right",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {pm.maxPickups}
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      Aantal gebruikt:
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        textAlign: "right",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {pm.currentPickups}
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      Nog beschikbaar:
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        textAlign: "right",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {availablePickups}
                                    </Typography>
                                  </Box>

                                  <Divider sx={{ my: 1 }} />

                                  <Box>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      Uitgezonderde artikelen:
                                    </Typography>
                                    <Box sx={{ pl: 2 }}>
                                      {renderExcludedArticles(
                                        pm.excludedArticles
                                      )}
                                    </Box>
                                  </Box>

                                  <Box sx={{ mt: 2 }}>
                                    {editingId === pm.id ? null : (
                                      <Button
                                        variant="outlined"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleEditClick(pm.id, pm.maxPickups);
                                        }}
                                        size="small"
                                        sx={{
                                          borderColor: "#ff4b6e",
                                          color: "#ff4b6e",
                                          textTransform: "none",
                                          transition: "all 0.2s ease",
                                          "&:hover": {
                                            background:
                                              "rgba(255, 75, 110, 0.08)",
                                            borderColor: "#ff4b6e",
                                          },
                                        }}
                                      >
                                        Aanpassen
                                      </Button>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        selectedDate && (
          <Typography variant="body1" sx={{ textAlign: "center", mt: 3 }}>
            Geen pickup moments gevonden voor de geselecteerde datum.
          </Typography>
        )
      )}

      {/* Geen resultaten */}
      {!loading && sortedPickupMoments.length === 0 && selectedDate && (
        <Box
          sx={{
            textAlign: "center",
            py: 8,
            px: 2,
            background: "rgba(248, 250, 252, 0.5)",
            borderRadius: 2,
            border: "1px solid rgba(0,0,0,0.05)",
            mt: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#64748b",
              fontWeight: 500,
              mb: 1,
            }}
          >
            Geen pickup moments gevonden
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#94a3b8",
            }}
          >
            Pas je zoekcriteria aan om andere pickup moments te vinden
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default ManagePickups;
