// src/pages/Downloads.js

import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Alert,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  MenuItem,
  Collapse,
  IconButton,
  Button,
  CardActions,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import RefreshIcon from "@mui/icons-material/Refresh";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useLocation } from "react-router-dom";
import { useScrollToHash } from "../utils/useScrollToHash";
import {
  downloadProductsExcel,
  downloadProductsPDF,
  downloadChecklist,
  downloadChecklistForYear,
  downloadAllOrders,
  downloadProductsForYear,
  generateAllPDFs,
  getDownloadFilesList,
  downloadFile,
  downloadSelectedOrdersAsPDF,
} from "../services/api";
import api from "../services/api";
import FaqReader from "../components/FaqReader";

// Styling constants
const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

// Helper functie voor consistente orderfiltering
const excludedStatuses = ["open", "cancelled", "refunded"];
const filterValidOrders = (orders, type = "webshop") => {
  return orders.filter((order) => {
    if (type === "regular") {
      return !excludedStatuses.includes(order.attributes.stripePaymentStatus);
    }
    return !excludedStatuses.includes(order.attributes.paymentStatus);
  });
};

const Downloads = () => {
  const location = useLocation();
  useScrollToHash();

  // STATE DECLARATIONS
  const [loading, setLoading] = useState({
    productsExcel: false,
    productsPDF: false,
    checklist: false,
    yearChecklist: false,
    order: false,
    allOrders: false,
    yearProducts: false,
    generate: false,
    selectedOrders: false,
    downloadFiles: false, // Added for extra downloads
  });

  const [messages, setMessages] = useState({
    productsExcel: { type: "", text: "" },
    productsPDF: { type: "", text: "" },
    checklist: { type: "", text: "" },
    yearChecklist: { type: "", text: "" },
    allOrders: { type: "", text: "" },
    yearProducts: { type: "", text: "" },
    generate: { type: "", text: "" },
    selectedOrders: { type: "", text: "" },
    downloadFiles: { type: "", text: "" },
  });

  const [expandedSections, setExpandedSections] = useState({
    "productlist-day": true,
    "productlist-year": true,
    "checklist-day": true,
    "checklist-year": true,
    "order-downloads": true,
    "additional-downloads": true,
  });

  const [globalYear, setGlobalYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(`${globalYear}-12-28`);
  const [selectedYear, setSelectedYear] = useState(globalYear);
  const [primarySort, setPrimarySort] = useState("pickupDate");
  const [secondarySort, setSecondarySort] = useState("pickupTime");
  const [sortOption, setSortOption] = useState("name");
  const [downloadFiles, setDownloadFiles] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const tableScrollRef = useRef(null);
  const tableScrollPosition = useRef(0);
  // Sorter State
  const [sortDirection, setSortDirection] = useState("desc"); // 'asc' or 'desc'
  const [sortColumn, setSortColumn] = useState("createdAt"); // default sort on createdAt

  // New state to track if packslips have been generated
  const [generated, setGenerated] = useState(false);

  const handleOrderSelection = (orderId) => {
    // Store current scroll position before state update
    if (tableScrollRef.current) {
      tableScrollPosition.current = tableScrollRef.current.scrollTop;
    }

    setSelectedOrders((prev) => {
      if (prev.includes(orderId)) {
        return prev.filter((id) => id !== orderId);
      } else {
        return [...prev, orderId];
      }
    });
  };

  // EFFECTS
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.fetchAllOrders(globalYear);

        // Filter en verwerk reguliere bestellingen
        const processedOrders = filterValidOrders(
          response.ordersResponse.data,
          "regular"
        ).map((order) => ({
          id: order.id,
          userName: order.attributes.userName,
          createdAt: order.attributes.createdAt
            ? new Date(order.attributes.createdAt)
            : null,
          pickupDate: order.attributes.pickupDate
            ? `${order.attributes.pickupDate}-12-${globalYear}`
            : "Onbekend",
          pickupTimeSlot: order.attributes.pickupTimeSlot || "Onbekend",
        }));

        // Filter en verwerk webshop-bestellingen
        const processedWebshopOrders = filterValidOrders(
          response.webshopOrdersResponse.data
        ).map((order) => ({
          id: order.id,
          userName: order.attributes.userName,
          createdAt: order.attributes.createdAt
            ? new Date(order.attributes.createdAt)
            : null,
          pickupDate: order.attributes.pickupDate || "Onbekend",
          pickupTimeSlot: order.attributes.pickupTimeSlot || "Onbekend",
        }));

        // Combineer alle gefilterde bestellingen
        let allOrders = [...processedOrders, ...processedWebshopOrders];

        // Sorteer bestellingen op createdAt in aflopende volgorde
        allOrders.sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return b.createdAt - a.createdAt; // Nieuw naar oud
          } else if (a.createdAt) {
            return -1;
          } else if (b.createdAt) {
            return 1;
          } else {
            return 0;
          }
        });

        setOrderDetails(allOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setMessages((prev) => ({
          ...prev,
          generate: {
            type: "error",
            text: "Fout bij ophalen beschikbare order IDs",
          },
        }));
      }
    };
    fetchOrders();
  }, [globalYear]); 

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash && expandedSections.hasOwnProperty(hash)) {
      setExpandedSections((prevSections) => {
        const newSections = Object.keys(prevSections).reduce((acc, key) => {
          acc[key] = key === hash;
          return acc;
        }, {});

        // Controleer of er een verandering is
        const hasChange = Object.keys(newSections).some(
          (key) => prevSections[key] !== newSections[key]
        );

        return hasChange ? newSections : prevSections;
      });

      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      setExpandedSections((prevSections) => {
        const newSections = Object.keys(prevSections).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});

        const hasChange = Object.keys(newSections).some(
          (key) => prevSections[key] !== newSections[key]
        );

        return hasChange ? newSections : prevSections;
      });
    }
  }, [location.hash, expandedSections]);

  useEffect(() => {
    const fetchDownloadFiles = async () => {
      try {
        setLoading((prev) => ({ ...prev, downloadFiles: true }));
        const files = await getDownloadFilesList(globalYear);  // globalYear meegeven
        setDownloadFiles(files);
      } catch (error) {
        console.error("Fout bij het ophalen van de bestandenlijst:", error);
        setMessages((prev) => ({
          ...prev,
          downloadFiles: {
            type: "error",
            text: "Fout bij het ophalen van de bestandenlijst",
          },
        }));
      } finally {
        setLoading((prev) => ({ ...prev, downloadFiles: false }));
      }
    };
  
    fetchDownloadFiles();
  }, [globalYear]);

  useEffect(() => {
    if (tableScrollRef.current) {
      tableScrollRef.current.scrollTop = tableScrollPosition.current;
    }
  });

  // HANDLERS
  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const year = selectedDate.split('-')[0];
    
    setSelectedDate(selectedDate);
    setGlobalYear(parseInt(year));
    setSelectedYear(parseInt(year));
    
    setMessages((prev) => ({
      ...prev,
      productsExcel: { type: "", text: "" },
      productsPDF: { type: "", text: "" },
      checklist: { type: "", text: "" },
    }));
    setGenerated(false);
  };

  const handleSortOptionChange = (event) => {
    setSortOption(event.target.value);
    setMessages((prev) => ({
      ...prev,
      yearProducts: { type: "", text: "" },
    }));
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);
  };

  const handleDownloadFile = async (filename) => {
    setLoading((prev) => ({ ...prev, [filename]: true }));
    setMessages((prev) => ({
      ...prev,
      downloadFiles: { type: "", text: "" },
    }));

    try {
      const data = await downloadFile(filename);
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setMessages((prev) => ({
        ...prev,
        downloadFiles: {
          type: "success",
          text: `Bestand ${filename} gedownload`,
        },
      }));
    } catch (error) {
      setMessages((prev) => ({
        ...prev,
        downloadFiles: {
          type: "error",
          text: `Fout bij het downloaden van bestand ${filename}`,
        },
      }));
    } finally {
      setLoading((prev) => ({ ...prev, [filename]: false }));
    }
  };

  // UTILITY FUNCTIONS
  const saveFile = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // CORE FUNCTIONALITY HANDLERS
  const handleGenerate = async () => {
    setLoading((prev) => ({ ...prev, generate: true }));
    setMessages((prev) => ({
      ...prev,
      generate: { type: "", text: "" },
    }));
    setGenerated(false); // Reset the generated status

    try {
      const response = await generateAllPDFs();
      setMessages((prev) => ({
        ...prev,
        generate: {
          type: "success",
          text: `${response.generatedFiles.length} PDFs gegenereerd.${
            response.errors ? ` Met ${response.errors.length} fouten.` : ""
          }`,
        },
      }));
      setGenerated(true); // Set generated status to true

      // Refresh the orders to reflect any changes after generation
      const refreshedOrders = await api.fetchAllOrders(globalYear);

      // Filter en verwerk reguliere bestellingen
      const processedOrders = filterValidOrders(
        refreshedOrders.ordersResponse.data,
        "regular"
      ).map((order) => ({
        id: order.id,
        userName: order.attributes.userName,
        createdAt: order.attributes.createdAt
          ? new Date(order.attributes.createdAt)
          : null,
        pickupDate: order.attributes.pickupDate
          ? `${order.attributes.pickupDate}-12-${globalYear}`
          : "Onbekend",
        pickupTimeSlot: order.attributes.pickupTimeSlot || "Onbekend",
      }));

      // Filter en verwerk webshop-bestellingen
      const processedWebshopOrders = filterValidOrders(
        refreshedOrders.webshopOrdersResponse.data
      ).map((order) => ({
        id: order.id,
        userName: order.attributes.userName,
        createdAt: order.attributes.createdAt
          ? new Date(order.attributes.createdAt)
          : null,
        pickupDate: order.attributes.pickupDate || "Onbekend",
        pickupTimeSlot: order.attributes.pickupTimeSlot || "Onbekend",
      }));

      // Combine all orders
      let allOrders = [...processedOrders, ...processedWebshopOrders];

      // Sort orders by createdAt descending
      allOrders.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return b.createdAt - a.createdAt; // Descending: new to old
        } else if (a.createdAt) {
          return -1;
        } else if (b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      });

      setOrderDetails(allOrders);
    } catch (error) {
      setMessages((prev) => ({
        ...prev,
        generate: {
          type: "error",
          text: error.message || "Fout bij genereren van PDFs",
        },
      }));
    } finally {
      setLoading((prev) => ({ ...prev, generate: false }));
    }
  };

  const handleToggleSection = (sectionId) => {
    setExpandedSections((prev) => {
      const newState = {
        ...prev,
        [sectionId]: !prev[sectionId],
      };

      if (newState[sectionId]) {
        window.history.pushState(null, "", `#${sectionId}`);
      } else if (window.location.hash === `#${sectionId}`) {
        window.history.pushState(null, "", window.location.pathname);
      }

      return newState;
    });
  };

  const handleDownload = async (type) => {
    setLoading((prev) => ({ ...prev, [type]: true }));
    setMessages((prev) => ({
      ...prev,
      [type]: { type: "", text: "" },
    }));

    try {
      let response;
      switch (type) {
        case "productsExcel":
          if (!selectedDate) throw new Error("Selecteer eerst een datum");
          response = await downloadProductsExcel(selectedDate);
          saveFile(response.data, `Productlijst_${selectedDate}.xlsx`);
          setMessages((prev) => ({
            ...prev,
            productsExcel: {
              type: "success",
              text: "Productlijst Excel gedownload!",
            },
          }));
          break;
        case "productsPDF":
          if (!selectedDate) throw new Error("Selecteer eerst een datum");
          response = await downloadProductsPDF(selectedDate);
          saveFile(response.data, `Productlijst_${selectedDate}.pdf`);
          setMessages((prev) => ({
            ...prev,
            productsPDF: {
              type: "success",
              text: "Productlijst PDF gedownload!",
            },
          }));
          break;
        case "checklist":
          if (!selectedDate) throw new Error("Selecteer eerst een datum");
          response = await downloadChecklist(
            selectedDate,
            primarySort,
            secondarySort
          );
          saveFile(response.data, `Afvinklijst_${selectedDate}.pdf`);
          setMessages((prev) => ({
            ...prev,
            checklist: { type: "success", text: "Afvinklijst gedownload!" },
          }));
          break;
        case "selectedOrders":
          if (selectedOrders.length === 0)
            throw new Error("Selecteer eerst orders");
          response = await downloadSelectedOrdersAsPDF(selectedOrders);
          // Example of filename based on current date
          const currentDate = new Date()
            .toLocaleDateString("nl-NL")
            .replace(/\//g, "-");
          saveFile(response.data, `Geselecteerde_Orders_${currentDate}.pdf`);
          setMessages((prev) => ({
            ...prev,
            selectedOrders: {
              type: "success",
              text: "Geselecteerde orders succesvol gedownload als PDF!",
            },
          }));
          break;
        case "yearChecklist":
          response = await downloadChecklistForYear(
            selectedYear,
            primarySort,
            secondarySort
          );
          saveFile(response.data, `Afvinklijst_${selectedYear}.pdf`);
          setMessages((prev) => ({
            ...prev,
            yearChecklist: {
              type: "success",
              text: "Afvinklijst jaar gedownload!",
            },
          }));
          break;
        case "allOrders":
          response = await downloadAllOrders();
          saveFile(response.data, "alle_orders.zip");
          setMessages((prev) => ({
            ...prev,
            allOrders: {
              type: "success",
              text: "Alle orders succesvol gedownload als ZIP!",
            },
          }));
          break;
        case "yearProducts":
          response = await downloadProductsForYear(selectedYear, sortOption);
          saveFile(response.data, `Productlijst_${selectedYear}.xlsx`);
          setMessages((prev) => ({
            ...prev,
            yearProducts: {
              type: "success",
              text: `Productlijst voor ${selectedYear} succesvol gedownload!`,
            },
          }));
          break;
        default:
          throw new Error("Ongeldig download type");
      }

      // Clear the message after a short delay (optional)
      setTimeout(() => {
        setMessages((prev) => ({
          ...prev,
          [type]: { type: "", text: "" },
        }));
      }, 5000);
    } catch (error) {
      setMessages((prev) => ({
        ...prev,
        [type]: {
          type: "error",
          text:
            error.message ||
            "Er is een fout opgetreden tijdens het downloaden.",
        },
      }));
    } finally {
      setLoading((prev) => ({ ...prev, [type]: false }));
    }
  };

  // CARD COMPONENT
  const CollapsibleCard = ({ id, title, subheader, children }) => (
    <Card
      raised
      sx={{
        mb: 3,
        borderRadius: 2,
        border: "1px solid rgba(0,0,0,0.05)",
        boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
        transition: "all 0.2s ease",
        "&:hover": {
          boxShadow: "0 6px 16px rgba(0,0,0,0.1)",
        },
      }}
    >
      <CardHeader
        title={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{title}</Typography>
            <IconButton onClick={() => handleToggleSection(id)} size="small">
              {expandedSections[id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        }
        subheader={subheader}
        sx={{
          background: mainGradients.tableHeader,
          "& .MuiCardHeader-content": { color: "#1e293b" },
        }}
      />
      <Collapse in={expandedSections[id]}>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              background: mainGradients.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Downloads
          </Typography>
          <FaqReader pageName="Downloads" />
        </Box>

        {/* Aangepaste jaarselector in een Card met dezelfde stijl als in Bestellingen */}
        <Card 
          sx={{ 
            mb: 4, 
            p: 2,
            background: mainGradients.tableHeader,
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: 2
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography variant="subtitle1" sx={{ fontWeight: "medium", color: "text.secondary" }}>
                Selecteer het jaar voor alle downloads:
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth sx={{ minWidth: 120 }}>
                <InputLabel id="downloads-year-select-label">Jaar</InputLabel>
                <Select
                  labelId="downloads-year-select-label"
                  value={selectedYear}
                  label="Jaar"
                  onChange={(e) => {
                    const newYear = parseInt(e.target.value);
                    setGlobalYear(newYear);
                    setSelectedYear(newYear);
                    setSelectedDate(`${newYear}-12-28`);
                    
                    // Reset generatie status, selecties en berichten
                    setGenerated(false);
                    setSelectedOrders([]);
                    setMessages((prev) => ({
                      ...prev,
                      generate: { type: "", text: "" },
                      yearProducts: { type: "", text: "" },
                      yearChecklist: { type: "", text: "" }
                    }));
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      transition: "all 0.2s ease",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(255, 75, 110, 0.5)",
                        },
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ff4b6e",
                          borderWidth: "2px",
                        },
                      },
                    },
                  }}
                >
                  {Array.from(
                    { length: new Date().getFullYear() - 2019 + 1 },
                    (_, i) => 2019 + i
                  ).map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Card>

        <Grid container spacing={3}>
          {/* Productlijst Dag */}
          <Grid
            item
            xs={12}
            id="productlist-day"
            sx={{ scrollMarginTop: "40px" }}
          >
            <CollapsibleCard
              id="productlist-day"
              title="Productlijst per dag"
              subheader="Download de productlijst voor een specifieke dag"
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="date"
                    fullWidth
                    value={selectedDate}
                    onChange={handleDateChange}
                    label="Selecteer Datum"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload("productsExcel")}
                    disabled={loading.productsExcel}
                    startIcon={<FileDownloadIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: "6px",
                      "&:hover": { opacity: 0.9 },
                    }}
                  >
                    {loading.productsExcel
                      ? "Downloaden..."
                      : "Download Productlijst (Excel)"}
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload("productsPDF")}
                    disabled={loading.productsPDF}
                    startIcon={<FileDownloadIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: "6px",
                      mt: 2,
                      "&:hover": { opacity: 0.9 },
                    }}
                  >
                    {loading.productsPDF
                      ? "Downloaden..."
                      : "Download Productlijst (PDF)"}
                  </Button>
                </Grid>
                {(loading.productsExcel || loading.productsPDF) && (
                  <Grid item xs={12}>
                    <Box sx={{ width: "100%", mt: 2 }}>
                      <LinearProgress />
                    </Box>
                  </Grid>
                )}
              </Grid>
              {/* Alerts voor Productlijst Excel en PDF */}
              {messages.productsExcel.text && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity={messages.productsExcel.type}>
                    {messages.productsExcel.text}
                  </Alert>
                </Box>
              )}
              {messages.productsPDF.text && (
                <Box sx={{ mt: 1 }}>
                  <Alert severity={messages.productsPDF.type}>
                    {messages.productsPDF.text}
                  </Alert>
                </Box>
              )}
            </CollapsibleCard>
          </Grid>

          {/* Productlijst Jaar */}
          <Grid
            item
            xs={12}
            id="productlist-year"
            sx={{ scrollMarginTop: "40px" }}
          >
            <CollapsibleCard
              id="productlist-year"
              title="Productlijst voor jaar"
              subheader="Download de productlijst voor het geselecteerde jaar"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="number"
                    fullWidth
                    value={globalYear}
                    disabled
                    label="Geselecteerd Jaar"
                    InputLabelProps={{ shrink: true }}
                    sx={{ backgroundColor: 'rgba(0,0,0,0.03)' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    label="Sorteeroptie"
                    value={sortOption}
                    onChange={handleSortOptionChange}
                  >
                    <MenuItem value="name">Naam</MenuItem>
                    <MenuItem value="artNumber">Artikelnummer</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload("yearProducts")}
                    disabled={loading.yearProducts}
                    startIcon={<FileDownloadIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: "6px",
                      "&:hover": { opacity: 0.9 },
                    }}
                  >
                    {loading.yearProducts
                      ? "Downloaden..."
                      : `Download Productlijst ${selectedYear}`}
                  </Button>
                </Grid>
                {loading.yearProducts && (
                  <Grid item xs={12}>
                    <Box sx={{ width: "100%", mt: 2 }}>
                      <LinearProgress />
                    </Box>
                  </Grid>
                )}
              </Grid>
              {/* Alert voor Productlijst Jaar */}
              {messages.yearProducts.text && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity={messages.yearProducts.type}>
                    {messages.yearProducts.text}
                  </Alert>
                </Box>
              )}
            </CollapsibleCard>
          </Grid>

          {/* Afvinklijst Dag */}
          <Grid
            item
            xs={12}
            id="checklist-day"
            sx={{ scrollMarginTop: "40px" }}
          >
            <CollapsibleCard
              id="checklist-day"
              title="Afvinklijst per dag"
              subheader="Download de afvinklijst voor een specifieke dag"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    type="date"
                    fullWidth
                    value={selectedDate}
                    onChange={handleDateChange}
                    label="Selecteer Datum"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    fullWidth
                    label="Primaire Sorteeroptie"
                    value={primarySort}
                    onChange={(e) => setPrimarySort(e.target.value)}
                  >
                    <MenuItem value="pickupDate">Ophaaldatum</MenuItem>
                    <MenuItem value="pickupTime">Ophaaltijd</MenuItem>
                    <MenuItem value="orderId">Ordernummer</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    fullWidth
                    label="Secundaire Sorteeroptie"
                    value={secondarySort}
                    onChange={(e) => setSecondarySort(e.target.value)}
                  >
                    <MenuItem value="pickupDate">Ophaaldatum</MenuItem>
                    <MenuItem value="pickupTime">Ophaaltijd</MenuItem>
                    <MenuItem value="orderId">Ordernummer</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="warning"
                    onClick={() => handleDownload("checklist")}
                    disabled={loading.checklist || !generated} // Disabled if not generated
                    startIcon={<FormatListBulletedIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: "6px",
                      "&:hover": { opacity: 0.9 },
                    }}
                  >
                    {loading.checklist ? "Downloaden..." : "Afvinklijst"}
                  </Button>
                </Grid>
                {loading.checklist && (
                  <Grid item xs={12}>
                    <Box sx={{ width: "100%", mt: 2 }}>
                      <LinearProgress />
                    </Box>
                  </Grid>
                )}
              </Grid>
              {/* Alert voor Afvinklijst */}
              {messages.checklist.text && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity={messages.checklist.type}>
                    {messages.checklist.text}
                  </Alert>
                </Box>
              )}
            </CollapsibleCard>
          </Grid>

          {/* Afvinklijst Jaar */}
          <Grid
            item
            xs={12}
            id="checklist-year"
            sx={{ scrollMarginTop: "40px" }}
          >
            <CollapsibleCard
              id="checklist-year"
              title="Afvinklijst downloads voor Jaar"
              subheader="Download afvinklijst voor het geselecteerde jaar"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    type="number"
                    fullWidth
                    value={globalYear}
                    disabled
                    label="Geselecteerd Jaar"
                    InputLabelProps={{ shrink: true }}
                    sx={{ backgroundColor: 'rgba(0,0,0,0.03)' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    fullWidth
                    label="Primaire Sorteeroptie"
                    value={primarySort}
                    onChange={(e) => setPrimarySort(e.target.value)}
                  >
                    <MenuItem value="pickupDate">Ophaaldatum</MenuItem>
                    <MenuItem value="pickupTime">Ophaaltijd</MenuItem>
                    <MenuItem value="orderId">Ordernummer</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    fullWidth
                    label="Secundaire Sorteeroptie"
                    value={secondarySort}
                    onChange={(e) => setSecondarySort(e.target.value)}
                  >
                    <MenuItem value="pickupDate">Ophaaldatum</MenuItem>
                    <MenuItem value="pickupTime">Ophaaltijd</MenuItem>
                    <MenuItem value="orderId">Ordernummer</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDownload("yearChecklist")}
                    disabled={loading.yearChecklist || !generated} // Disabled if not generated
                    startIcon={<PictureAsPdfIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: "6px",
                      "&:hover": { opacity: 0.9 },
                    }}
                  >
                    {loading.yearChecklist
                      ? "Downloaden..."
                      : `Download Afvinklijst ${selectedYear}`}
                  </Button>
                </Grid>
                {loading.yearChecklist && (
                  <Grid item xs={12}>
                    <Box sx={{ width: "100%", mt: 2 }}>
                      <LinearProgress />
                    </Box>
                  </Grid>
                )}
              </Grid>
              {/* Alert voor Afvinklijst Jaar */}
              {messages.yearChecklist.text && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity={messages.yearChecklist.type}>
                    {messages.yearChecklist.text}
                  </Alert>
                </Box>
              )}
            </CollapsibleCard>
          </Grid>

          {/* Order Downloads */}
          <Grid
            item
            xs={12}
            id="order-downloads"
            sx={{ scrollMarginTop: "40px" }}
          >
            <CollapsibleCard
              id="order-downloads"
              title="Pakbonnen downloaden"
              subheader="Genereer en download pakbonnen individueel of allemaal"
            >
              <Box sx={{ mb: 2 }}>
                {!generated && (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Klik op "Genereer Alle Pakbonnen" voordat je de pakbonnen
                    kunt downloaden.
                  </Alert>
                )}
              </Box>
              <Grid container spacing={2} alignItems="center">
                {/* Genereer alle pakbonnen */}
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="warning"
                    onClick={handleGenerate}
                    disabled={loading.generate}
                    startIcon={<RefreshIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: "6px",
                      "&:hover": { opacity: 0.9 },
                    }}
                  >
                    {loading.generate
                      ? "PDFs Genereren..."
                      : "Genereer Alle Pakbonnen"}
                  </Button>
                </Grid>

                {/* Download geselecteerde orders als PDF */}
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleDownload("selectedOrders")}
                    disabled={
                      !generated ||
                      selectedOrders.length === 0 ||
                      loading.selectedOrders
                    }
                    startIcon={<PictureAsPdfIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: "6px",
                      "&:hover": { opacity: 0.9 },
                    }}
                  >
                    {loading.selectedOrders
                      ? "Downloaden..."
                      : selectedOrders.length > 0
                      ? `Download ${selectedOrders.length} ${
                          selectedOrders.length === 1 ? "order" : "orders"
                        } als PDF`
                      : "Selecteer orders om te downloaden"}
                  </Button>
                </Grid>

                {/* Download alle orders als ZIP */}
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleDownload("allOrders")}
                    disabled={!generated || loading.allOrders}
                    startIcon={<DownloadIcon />}
                    sx={{
                      background: mainGradients.primary,
                      borderRadius: "6px",
                      "&:hover": { opacity: 0.9 },
                    }}
                  >
                    {loading.allOrders
                      ? "Downloaden..."
                      : "Download Alle Orders (ZIP)"}
                  </Button>
                </Grid>
                {loading.generate && (
                  <Grid item xs={12}>
                    <Box sx={{ width: "100%", mt: 1 }}>
                      <LinearProgress />
                    </Box>
                  </Grid>
                )}
              </Grid>

              {/* Alerts voor Genereer, Geselecteerde Orders en Alle Orders */}
              {messages.generate.text && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity={messages.generate.type}>
                    {messages.generate.text}
                  </Alert>
                </Box>
              )}
              {messages.selectedOrders.text && (
                <Box sx={{ mt: 1 }}>
                  <Alert severity={messages.selectedOrders.type}>
                    {messages.selectedOrders.text}
                  </Alert>
                </Box>
              )}
              {messages.allOrders.text && (
                <Box sx={{ mt: 1 }}>
                  <Alert severity={messages.allOrders.type}>
                    {messages.allOrders.text}
                  </Alert>
                </Box>
              )}

              {/* Download tabel */}
              <Box
                sx={{
                  opacity: generated ? 1 : 0.5,
                  pointerEvents: generated ? "auto" : "none",
                  transition: "opacity 0.3s ease",
                }}
              >
                <TableContainer
                  component={Paper}
                  ref={tableScrollRef}
                  sx={{
                    maxHeight: "400px",
                    border: "1px solid #ddd",
                    borderRadius: 1,
                    mb: 2,
                  }}
                >
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          padding="checkbox"
                          sx={{ background: mainGradients.tableHeader }}
                        >
                          <Checkbox
                            indeterminate={
                              selectedOrders.length > 0 &&
                              selectedOrders.length < orderDetails.length
                            }
                            checked={
                              orderDetails.length > 0 &&
                              selectedOrders.length === orderDetails.length
                            }
                            onChange={(e) => {
                              if (tableScrollRef.current) {
                                tableScrollPosition.current =
                                  tableScrollRef.current.scrollTop;
                              }

                              if (e.target.checked) {
                                setSelectedOrders(
                                  orderDetails.map((order) => order.id)
                                );
                              } else {
                                setSelectedOrders([]);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            background: mainGradients.tableHeader,
                            fontWeight: "bold",
                          }}
                        >
                          Order #
                        </TableCell>
                        <TableCell
                          sx={{
                            background: mainGradients.tableHeader,
                            fontWeight: "bold",
                          }}
                        >
                          Naam
                        </TableCell>
                        <TableCell
                          sx={{
                            background: mainGradients.tableHeader,
                            fontWeight: "bold",
                          }}
                        >
                          <TableSortLabel
                            active={sortColumn === "createdAt"}
                            direction={
                              sortColumn === "createdAt" ? sortDirection : "asc"
                            }
                            onClick={() => handleSort("createdAt")}
                          >
                            Besteld op
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails.length > 0 ? (
                        orderDetails
                          .slice()
                          .sort((a, b) => {
                            if (!a[sortColumn] || !b[sortColumn]) return 0;
                            if (sortColumn === "createdAt") {
                              return sortDirection === "asc"
                                ? a[sortColumn] - b[sortColumn]
                                : b[sortColumn] - a[sortColumn];
                            }
                            return 0;
                          })
                          .map((order) => (
                            <TableRow
                              key={order.id}
                              hover
                              onClick={() => handleOrderSelection(order.id)}
                              selected={selectedOrders.includes(order.id)}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: mainGradients.hover,
                                },
                              }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedOrders.includes(order.id)}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleOrderSelection(order.id);
                                  }}
                                />
                              </TableCell>
                              <TableCell>#{order.id}</TableCell>
                              <TableCell>{order.userName}</TableCell>
                              <TableCell>
                                {order.createdAt
                                  ? order.createdAt.toLocaleDateString("nl-NL")
                                  : "Onbekend"}
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <Typography>Geen beschikbare pakbonnen</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CollapsibleCard>
          </Grid>

          {/* Extra Downloads */}
          <Grid
            item
            xs={12}
            id="additional-downloads"
            sx={{ scrollMarginTop: "40px" }}
          >
            <CollapsibleCard
              id="additional-downloads"
              title="Extra Downloads"
              subheader="Download extra bestanden"
            >
              <Grid container spacing={2}>
                {downloadFiles.map((file) => (
                  <Grid item xs={12} md={6} key={file.filename}>
                    <Card
                      raised
                      sx={{
                        borderRadius: 2,
                        border: "1px solid rgba(0,0,0,0.05)",
                        boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          boxShadow: "0 6px 16px rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      <CardHeader
                        title={file.title}
                        subheader={file.description}
                      />
                      <CardActions>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDownloadFile(file.filename)}
                          startIcon={<DownloadIcon />}
                          disabled={loading[file.filename]}
                          sx={{
                            background: mainGradients.primary,
                            borderRadius: "6px",
                            "&:hover": { opacity: 0.9 },
                          }}
                        >
                          {loading[file.filename]
                            ? "Downloaden..."
                            : "Download"}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {/* Alert voor Extra Downloads */}
              {messages.downloadFiles.text && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity={messages.downloadFiles.type}>
                    {messages.downloadFiles.text}
                  </Alert>
                </Box>
              )}
            </CollapsibleCard>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Downloads;
