import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Tooltip,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faChartLine,
  faList,
  faBoxes,
  faTruck,
  faDownload,
  faUserShield,
  faCashRegister,
  faUpRightFromSquare,
  faStore,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import FaqReader from "../components/FaqReader";

// Nieuwe gradiënten voor de kaarten
const gradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  info: "linear-gradient(135deg, #0ea5e9 0%, #0284c7 100%)",
  warning: "linear-gradient(135deg, #f59e0b 0%, #d97706 100%)",
  success: "linear-gradient(135deg, #10b981 0%, #059669 100%)",
  secondary: "linear-gradient(135deg, #8b5cf6 0%, #7c3aed 100%)",
  error: "linear-gradient(135deg, #ef4444 0%, #dc2626 100%)",
};

const Home = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    if (path.startsWith("http")) {
      window.open(path, "_blank", "noopener,noreferrer");
    } else {
      navigate(path);
    }
  };

  const menuItems = [
    {
      title: "Dashboard",
      description: "Bekijk statistieken en overzichten van de verkopen.",
      path: "/dashboard",
      color: "primary",
      tooltip: "Bekijk verkoop statistieken en overzichten",
      icon: faChartLine,
    },
    {
      title: "Bestellingen",
      description: "Bekijk en beheer alle bestellingen.",
      path: "/bestellingen",
      color: "info",
      tooltip: "Beheer alle bestellingen",
      icon: faList,
    },
    {
      title: "Producten",
      description: "Bekijk en beheer het productassortiment.",
      path: "/producten",
      color: "warning",
      tooltip: "Beheer producten en voorraad",
      icon: faBoxes,
    },
    {
      title: "Kortingscodes",
      description: "Bekijk en beheer alle kortingscodes.",
      path: "/discount-codes",
      color: "secondary",
      tooltip: "Beheer kortingscodes",
      icon: faTicket,
    },
    {
      title: "Beheer tijdsloten",
      description: "Beheer de beschikbare afhaalsloten en hun capaciteit.",
      path: "/manage-pickups",
      color: "success",
      tooltip: "Beheer dagdelen en ophaaltijdsloten",
      icon: faTruck,
    },
    {
      title: "Uitsluiten producten",
      description:
        "Door een product uit te sluiten van specifieke dagen of dagdelen kan het niet op die tijden besteld of opgehaald worden. Klanten zien alleen beschikbare opties.",
      path: "/add",
      color: "secondary",
      tooltip:
        "Voeg specifieke items toe aan uitsluitingen voor voorraadbeheer",
      icon: faBoxes,
    },
    {
      title: "Bekijk uitsluitingen",
      description:
        "Bekijk welke items uitgesloten zijn op specifieke dagen of dagdelen.",
      path: "/view",
      color: "error",
      tooltip: "Bekijk de huidige uitsluitingen van items per dag of dagdeel",
      icon: faList,
    },
    {
      title: "Downloads",
      description:
        "Download productlijsten, afvinklijsten en pakbonnen voor orders.",
      path: "/downloads",
      color: "info",
      tooltip: "Download bestellijsten, afvinklijsten en pakbonnen",
      icon: faDownload,
    },
    {
      title: "Webshop",
      description: "Open de webshop in een nieuw tabblad.",
      path: "https://www.vuurwerkbruchem.com",
      color: "primary",
      tooltip: "Open de webshop in een nieuw tabblad",
      icon: faStore,
      external: true,
    },
    {
      title: "Admin Paneel",
      description: "Open het beheerders paneel in een nieuw tabblad.",
      path: "https://api.vuurwerkbruchem.com/admin",
      color: "warning",
      tooltip: "Open het admin paneel in een nieuw tabblad",
      icon: faUserShield,
      external: true,
    },
    {
      title: "Kassasysteem",
      description: "Open het kassasysteem in een nieuw tabblad.",
      path: "https://kiosk.fireworkflow.com/",
      color: "success",
      tooltip: "Open het kassasysteem in een nieuw tabblad",
      icon: faCashRegister,
      external: true,
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Box
        sx={{
          mb: 8,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "-20px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100px",
            height: "4px",
            background: gradients.primary,
            borderRadius: "2px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: "bold",
              background: gradients.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Vuurwerk Bruchem
          </Typography>
          <FaqReader pageName="Home" />
        </Box>
        <Typography
          variant="h6"
          align="center"
          sx={{
            color: "text.secondary",
            maxWidth: "600px",
            margin: "0 auto",
            opacity: 0.8,
          }}
        >
          Welkom! Maak een keus uit onderstaande opties.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {menuItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.title}>
            <Tooltip title={item.tooltip} arrow>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: 4,
                  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                  border: "1px solid rgba(255,255,255,0.1)",
                  background: "#fff",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: "0 12px 24px rgba(0,0,0,0.1)",
                    "& .icon-wrapper": {
                      transform: "scale(1.1) translateY(-2px)",
                    },
                    "& .card-gradient": {
                      opacity: 1,
                    },
                    "& .card-content": {
                      transform: "translateY(-4px)",
                    },
                  },
                }}
              >
                <Box
                  className="card-gradient"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: "6px",
                    background: gradients[item.color],
                    opacity: 0.8,
                    transition: "opacity 0.3s ease",
                  }}
                />
                <CardContent
                  className="card-content"
                  sx={{
                    flexGrow: 1,
                    transition: "transform 0.3s ease",
                    pt: 4,
                  }}
                >
                  <Box
                    className="icon-wrapper"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 3,
                      transition: "transform 0.3s ease",
                    }}
                  >
                    <Box
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: gradients[item.color],
                        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={item.icon}
                        style={{
                          fontSize: "1.5rem",
                          color: "#fff",
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {item.title}
                    {item.external && (
                      <FontAwesomeIcon
                        icon={faUpRightFromSquare}
                        style={{
                          fontSize: "0.8rem",
                          opacity: 0.7,
                        }}
                      />
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    sx={{ mb: 2 }}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ p: 2, pt: 0 }}>
                  <Button
                    fullWidth
                    onClick={() => handleNavigate(item.path)}
                    sx={{
                      background: gradients[item.color],
                      color: "white",
                      py: 1.5,
                      borderRadius: 2,
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: 500,
                      "&:hover": {
                        background: gradients[item.color],
                        opacity: 0.9,
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    {item.external ? "Openen" : "Bekijken"}
                  </Button>
                </CardActions>
              </Card>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
