import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  CircularProgress,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import ReactMarkdown from "react-markdown";

const FaqReader = ({ pageName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleOpen = async () => {
    setIsOpen(true);
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`/manual/${pageName}.md`);
      if (!response.ok) {
        throw new Error("FAQ kon niet worden geladen");
      }
      const text = await response.text();
      setContent(text);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          marginLeft: 1,
          color: "primary.main",
          "&:hover": {
            backgroundColor: "rgba(25, 118, 210, 0.04)",
          },
        }}
      >
        <HelpOutlineIcon />
      </IconButton>

      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          Help & FAQ
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box sx={{ color: "error.main", py: 2 }}>{error}</Box>
          ) : (
            <Box
              sx={{
                py: 2,
                "& h1": {
                  fontSize: "1.5rem",
                  fontWeight: 600,
                  mb: 2,
                },
                "& h2": {
                  fontSize: "1.25rem",
                  fontWeight: 600,
                  mb: 1.5,
                  mt: 3,
                },
                "& p": {
                  mb: 2,
                  lineHeight: 1.6,
                },
                "& ul, & ol": {
                  pl: 3,
                  mb: 2,
                },
                "& li": {
                  mb: 1,
                },
              }}
            >
              <ReactMarkdown>{content}</ReactMarkdown>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FaqReader;
