// src/pages/OrderDetail.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Alert,
  Chip,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faUser,
  faEnvelope,
  faPhone,
  faLocationDot,
  faClock,
  faCalendarDays,
  faCreditCard,
  faFileInvoice,
  faClockRotateLeft,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import api, { downloadOrder } from "../services/api";
import FaqReader from "../components/FaqReader";

// Styling constants
const mainGradients = {
  primary: "linear-gradient(135deg, #ff4b6e 0%, #ff3355 100%)",
  tableHeader: "linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)",
  hover: "rgba(255, 75, 110, 0.03)",
};

// Function to style and translate the status
const getStatusStyle = (status) => {
  const statusMap = {
    contant: {
      backgroundColor: "#fff3e0",
      color: "#ed6c02",
      label: "Nog te betalen in winkel",
    },
    completed: {
      backgroundColor: "#e8f5e9",
      color: "#2e7d32",
      label: "Betaald",
    },
    paid: { backgroundColor: "#e8f5e9", color: "#2e7d32", label: "Betaald" },
    cancelled: {
      backgroundColor: "#fbe9e7",
      color: "#d32f2f",
      label: "Geannuleerd, NIET MEEGEVEN",
    },
    refunded: {
      backgroundColor: "#fbe9e7",
      color: "#d32f2f",
      label: "Geannuleerd, NIET MEEGEVEN",
    },
    open: {
      backgroundColor: "#fbe9e7",
      color: "#d32f2f",
      label: "Niet betaald, NIET MEEGEVEN",
    },
  };
  const style = statusMap[status?.toLowerCase()];
  return (
    style || {
      backgroundColor: "#f5f5f5",
      color: "#616161",
      label: "Onbekend",
    }
  );
};

// Function to format the pickupDate
const formatPickupDate = (dateString, orderType) => {
  if (orderType === "webshopOrder") {
    // For webshopOrder, expect a string like "zaterdag 28 december 2024" or "zaterdag 28-12-2024"
    const regexDash = /(\w+)\s+(\d{1,2})-(\d{1,2})-(\d{4})/;
    const regexMonth = /(\w+)\s+(\d{1,2})\s+(\w+)\s+(\d{4})/;
    let date;
    if (regexDash.test(dateString)) {
      const match = dateString.match(regexDash);
      const day = match[2];
      const month = match[3];
      const year = match[4];
      date = new Date(`${year}-${month}-${day}T00:00:00`);
    } else if (regexMonth.test(dateString)) {
      const match = dateString.match(regexMonth);
      const day = match[2];
      const monthName = match[3];
      const year = match[4];
      date = new Date(`${day} ${monthName} ${year}`);
    }
    if (date && !isNaN(date)) {
      return date.toLocaleDateString("nl-NL", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    return "Onbekend"; // Fallback if regex doesn't match
  } else if (orderType === "order") {
    // For regular order, expect only the day, e.g., "28"
    const day = parseInt(dateString, 10);
    if (isNaN(day)) return "Onbekend";
    const date = new Date(2024, 11, day); // December is month 11 (0-indexed)
    return date.toLocaleDateString("nl-NL", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return "Onbekend";
};

const OrderDetail = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // New state variables for download
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        const response = await api.getOrderDetails(orderId);
        setOrder(response.data);
      } catch (err) {
        console.error("Error fetching order details:", err);
        setError(
          "Er is een fout opgetreden bij het ophalen van de orderdetails."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  // Handler function for downloading the order PDF
  const handleDownloadOrderPDF = async () => {
    setLoadingDownload(true);
    setDownloadMessage("");
    try {
      const response = await downloadOrder(orderId);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Order_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setDownloadMessage("Download succesvol");
    } catch (error) {
      console.error("Error downloading order PDF:", error);
      setDownloadMessage(
        "Er is een fout opgetreden bij het downloaden van de pakbon."
      );
    } finally {
      setLoadingDownload(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress sx={{ color: "#ff4b6e" }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => navigate("/bestellingen")}
          sx={{
            color: "text.primary",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          }}
        >
          Terug naar overzicht
        </Button>
      </Container>
    );
  }

  if (!order) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="info">Bestelling niet gevonden</Alert>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => navigate("/bestellingen")}
          sx={{
            mt: 2,
            color: "text.primary",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          }}
        >
          Terug naar overzicht
        </Button>
      </Container>
    );
  }

  const attributes = order.attributes || {};

  // Detect order type based on available fields
  const isWebshopOrder = attributes.paymentStatus !== undefined;
  const orderType = isWebshopOrder ? "webshopOrder" : "order";

  // Extract common fields
  const {
    products = [],
    userName = "",
    email = "",
    phoneNumber = "",
    createdAt = "",
    pickupDate = "",
    pickupTime = "", // webshopOrder: pickupTime, order: pickupTimeSlot
    pickupTimeSlot = "",
    notes = "",
  } = attributes;

  // Extract webshopOrder specific fields
  const {
    streetName,
    houseNumber,
    houseNumberSuffix,
    postalCode,
    city,
    paymentStatus,
    paymentMethod,
    totalPrice,
    webshopOrderNumber,
    onlinePaymentMethod,
    successUrl,
    priceBeforeDiscount,
    discountCode,
    // Other webshopOrder-specific fields can be added here
  } = attributes;

  // Extract order specific fields
  const {
    stripePaymentStatus,
    paymentProvider,
    // Other order-specific fields can be added here
  } = attributes;

  // Compute totalPrice if not available
  const computedTotalPrice = isWebshopOrder
    ? totalPrice ||
      products.reduce(
        (acc, product) => acc + (product.price || 0) * (product.count || 0),
        0
      )
    : products.reduce(
        (acc, product) => acc + (product.price || 0) * (product.count || 0),
        0
      );

  // Compute priceBeforeDiscount and priceDiscount
  const actualPriceBeforeDiscount =
    isWebshopOrder && priceBeforeDiscount
      ? priceBeforeDiscount
      : computedTotalPrice;
  const priceDiscount =
    isWebshopOrder && priceBeforeDiscount
      ? priceBeforeDiscount - computedTotalPrice
      : 0;

  // Format pickupDate based on order type
  const formattedPickupDate = formatPickupDate(pickupDate, orderType);

  // Determine payment status and method
  const rawPaymentStatus = isWebshopOrder ? paymentStatus : stripePaymentStatus;
  const statusInfo = getStatusStyle(rawPaymentStatus);
  const finalPaymentStatusLabel = statusInfo.label;
  const backgroundColor = statusInfo.backgroundColor;
  const color = statusInfo.color;

  const finalPaymentMethod = isWebshopOrder
    ? paymentMethod === "online"
      ? onlinePaymentMethod
        ? onlinePaymentMethod.charAt(0).toUpperCase() +
          onlinePaymentMethod.slice(1)
        : "Online"
      : "Contant"
    : paymentProvider
    ? paymentProvider.charAt(0).toUpperCase() + paymentProvider.slice(1)
    : "Stripe";

  // Define table columns based on order type and screen size
  const getTableColumns = () => {
    const baseColumns = [
      { id: "artNumber", label: "Artikelnr." },
      { id: "name", label: "Product" },
    ];

    if (!isMobile) {
      baseColumns.push({ id: "price", label: "Prijs per stuk" });
      baseColumns.push({ id: "count", label: "Aantal" });
      if (isWebshopOrder) {
        baseColumns.push({ id: "ean", label: "EAN" });
        baseColumns.push({ id: "weightGram", label: "Gewicht (g)" });
        baseColumns.push({ id: "shots", label: "Shots" });
        baseColumns.push({ id: "kaliber", label: "Kaliber" });
        baseColumns.push({
          id: "countFreeFireworks",
          label: "Gratis Vuurwerk",
        });
      }
    }

    baseColumns.push({ id: "subtotal", label: "Subtotaal" });

    return baseColumns;
  };

  const columns = getTableColumns();
  const columnCount = columns.length;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Back button and title */}
      <Box sx={{ mb: 4, display: "flex", alignItems: "center", gap: 2 }}>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => navigate("/bestellingen")}
          sx={{
            color: "text.primary",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          }}
        >
          Terug
        </Button>
        <Typography variant="h4" component="h1">
          Bestelling #{order.id}
        </Typography>
        <FaqReader pageName="OrderDetail" />
      </Box>

      {/* Status and date */}
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Chip
          label={finalPaymentStatusLabel.toUpperCase()}
          sx={{
            backgroundColor: backgroundColor,
            color: color,
            fontWeight: "medium",
          }}
        />
        <Typography variant="body2" color="text.secondary">
          <FontAwesomeIcon
            icon={faClockRotateLeft}
            style={{ marginRight: "8px" }}
          />
          Besteld op{" "}
          {new Date(createdAt).toLocaleString("nl-NL", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: isWebshopOrder ? "2-digit" : undefined,
            minute: isWebshopOrder ? "2-digit" : undefined,
          })}
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* Customer details */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <FontAwesomeIcon icon={faUser} />
                Klantgegevens
              </Typography>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box sx={{ width: 24, mt: "2px" }}>
                    <FontAwesomeIcon icon={faUser} />
                  </Box>
                  <Typography>{userName || "Onbekend"}</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box sx={{ width: 24, mt: "2px" }}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Box>
                  <Typography>{email || "Onbekend"}</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box sx={{ width: 24, mt: "2px" }}>
                    <FontAwesomeIcon icon={faPhone} />
                  </Box>
                  <Typography>{phoneNumber || "Onbekend"}</Typography>
                </Box>

                {/* Conditionally render address */}
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box sx={{ width: 24, mt: "2px" }}>
                    <FontAwesomeIcon icon={faLocationDot} />
                  </Box>
                  {isWebshopOrder ? (
                    <Typography>
                      {streetName} {houseNumber}
                      {houseNumberSuffix || ""}
                      <br />
                      {postalCode} {city}
                    </Typography>
                  ) : (
                    <Typography>
                      Niet bekend, bestelling uit oude systeem.
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Order details */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <FontAwesomeIcon icon={faFileInvoice} />
                Ordergegevens
              </Typography>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box sx={{ width: 24, mt: "2px" }}>
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </Box>
                  <Typography>Ophaaldag: {formattedPickupDate}</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box sx={{ width: 24, mt: "2px" }}>
                    <FontAwesomeIcon icon={faClock} />
                  </Box>
                  <Typography>
                    Ophaaltijd:{" "}
                    {isWebshopOrder
                      ? pickupTime || "Onbekend"
                      : pickupTimeSlot || "Onbekend"}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box sx={{ width: 24, mt: "2px" }}>
                    <FontAwesomeIcon icon={faCreditCard} />
                  </Box>
                  <Typography>Betaalmethode: {finalPaymentMethod}</Typography>
                </Box>

                {/* Success URL */}
                {successUrl && (
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
                  >
                    <Box sx={{ width: 24, mt: "2px" }}>
                      <FontAwesomeIcon icon={faFileInvoice} />
                    </Box>
                    <Typography>
                      <Link
                        href={successUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Bestelbevestiging
                      </Link>
                    </Typography>
                  </Box>
                )}

                {/* Add price before discount, discount amount, and discount code used */}
                {isWebshopOrder && priceBeforeDiscount && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                      }}
                    >
                      <Box sx={{ width: 24, mt: "2px" }}>
                        <FontAwesomeIcon icon={faCreditCard} />
                      </Box>
                      <Typography>
                        Prijs vóór korting: € {priceBeforeDiscount.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                      }}
                    >
                      <Box sx={{ width: 24, mt: "2px" }}>
                        <FontAwesomeIcon icon={faCreditCard} />
                      </Box>
                      <Typography>
                        Prijs korting: € {priceDiscount.toFixed(2)}
                      </Typography>
                    </Box>
                    {discountCode && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 2,
                        }}
                      >
                        <Box sx={{ width: 24, mt: "2px" }}>
                          <FontAwesomeIcon icon={faCreditCard} />
                        </Box>
                        <Typography>
                          Korting code gebruikt: {discountCode}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}

                {notes && (
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Opmerkingen:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        p: 2,
                        bgcolor: "grey.50",
                        borderRadius: 1,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {notes}
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Download Button */}
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadOrderPDF}
                  disabled={loadingDownload}
                  startIcon={<FontAwesomeIcon icon={faFilePdf} />}
                  sx={{
                    background: mainGradients.primary,
                    "&:hover": { opacity: 0.9 },
                    borderRadius: "6px",
                  }}
                >
                  {loadingDownload ? "Downloaden..." : "Download Pakbon"}
                </Button>
                {downloadMessage && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                  >
                    {downloadMessage}
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Message about free fireworks with link to order confirmation */}
        {isWebshopOrder && (
          <Grid item xs={12}>
            <Alert severity="info" sx={{ mb: 3 }}>
              Het eventuele gratis vuurwerk is niet weergegeven op deze pagina,
              maar wel bij de bevestiging richting klant, pakbon, bestelbon,
              orderbevestiging of op de bevestigingspagina. Bekijk de{" "}
              {successUrl ? (
                <Link
                  href={successUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  bestelbevestiging
                </Link>
              ) : (
                "bestelbevestiging"
              )}
              .
            </Alert>
          </Grid>
        )}

        {/* Products */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Bestelde Producten
              </Typography>

              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          sx={{
                            fontWeight: 600,
                            background: mainGradients.tableHeader,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product, index) => (
                      <TableRow key={product.id || index}>
                        <TableCell>{product.artNumber || "N/A"}</TableCell>
                        <TableCell>
                          {product.name || "Onbekend"}
                          {isMobile && (
                            <>
                              <Typography
                                variant="caption"
                                display="block"
                                color="text.secondary"
                              >
                                {product.count || 0}x €{" "}
                                {product.price
                                  ? product.price.toFixed(2)
                                  : "0.00"}
                              </Typography>
                              {/* Extra product details for mobile */}
                              {isWebshopOrder && (
                                <>
                                  {product.ean && (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      color="text.secondary"
                                    >
                                      EAN: {product.ean}
                                    </Typography>
                                  )}
                                  {product.weightGram && (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      color="text.secondary"
                                    >
                                      Gewicht: {product.weightGram}g
                                    </Typography>
                                  )}
                                  {product.shots && (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      color="text.secondary"
                                    >
                                      Shots: {product.shots}
                                    </Typography>
                                  )}
                                  {product.kaliber && (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      color="text.secondary"
                                    >
                                      Kaliber: {product.kaliber}
                                    </Typography>
                                  )}
                                  {product.countFreeFireworks && (
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      color="text.secondary"
                                    >
                                      Gratis vuurwerk inbegrepen
                                    </Typography>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </TableCell>
                        {!isMobile && (
                          <>
                            <TableCell>
                              €{" "}
                              {product.price
                                ? product.price.toFixed(2)
                                : "0.00"}
                            </TableCell>
                            <TableCell align="center">
                              {product.count || 0}
                            </TableCell>
                            {isWebshopOrder && (
                              <>
                                <TableCell>{product.ean || "N/A"}</TableCell>
                                <TableCell>
                                  {product.weightGram || "N/A"}
                                </TableCell>
                                <TableCell>{product.shots || "N/A"}</TableCell>
                                <TableCell>
                                  {product.kaliber || "N/A"}
                                </TableCell>
                                <TableCell>
                                  {product.countFreeFireworks ? "Ja" : "Nee"}
                                </TableCell>
                              </>
                            )}
                          </>
                        )}
                        <TableCell align="right">
                          €{" "}
                          {product.price && product.count
                            ? (product.price * product.count).toFixed(2)
                            : "0.00"}
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* Total price */}
                    <TableRow>
                      <TableCell
                        colSpan={columnCount - 1}
                        sx={{ border: 0 }}
                        align="right"
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600 }}
                        >
                          Totaal
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={{ border: 0 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600 }}
                        >
                          € {computedTotalPrice.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* Price before discount */}
                    {isWebshopOrder && priceBeforeDiscount && (
                      <TableRow>
                        <TableCell
                          colSpan={columnCount - 1}
                          sx={{ border: 0 }}
                          align="right"
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600 }}
                          >
                            Prijs vóór korting
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600 }}
                          >
                            € {priceBeforeDiscount.toFixed(2)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {/* Price discount */}
                    {isWebshopOrder &&
                      priceBeforeDiscount &&
                      priceDiscount > 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={columnCount - 1}
                            sx={{ border: 0 }}
                            align="right"
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600 }}
                            >
                              Prijs korting
                            </Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ border: 0 }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, color: "#d32f2f" }} // Red color for discount
                            >
                              -€ {priceDiscount.toFixed(2)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    {/* Discount code used */}
                    {isWebshopOrder && discountCode && (
                      <TableRow>
                        <TableCell
                          colSpan={columnCount - 1}
                          sx={{ border: 0 }}
                          align="right"
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600 }}
                          >
                            Korting code gebruikt
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600 }}
                          >
                            {discountCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OrderDetail;
